import {CONJUGATION_FORMS} from "../../Constants";
import {stripWhitespace} from "../util/Util";

const KANA_U_TO_A = {
    'う':'わ',
    'く':'か',
    'す':'さ',
    'つ':'た',
    'ぬ':'な',
    'ふ':'は',
    'む':'ま',
    'る':'ら',
    'ぐ':'が',
    'ず':'ざ',
    'ぶ':'ば',
    'ぷ':'ぱ'
};

const KANA_U_TO_I = {
    'う':'い',
    'く':'き',
    'す':'し',
    'つ':'ち',
    'ぬ':'に',
    'ふ':'ひ',
    'む':'み',
    'る':'り',
    'ぐ':'ぎ',
    'ず':'じ',
    'ぶ':'び',
    'ぷ':'ぴ'
};

const KANA_U_TO_E = {
    'う':'え',
    'く':'け',
    'す':'せ',
    'つ':'て',
    'ぬ':'ね',
    'ふ':'へ',
    'む':'め',
    'る':'れ',
    'ぐ':'げ',
    'ず':'ぜ',
    'ぶ':'べ',
    'ぷ':'ぺ'
};

const KANA_U_TO_O = {
    'う':'お',
    'く':'こ',
    'す':'そ',
    'つ':'と',
    'ぬ':'の',
    'ふ':'ほ',
    'む':'も',
    'る':'ろ',
    'ぐ':'ご',
    'ず':'ぞ',
    'ぶ':'ぼ',
    'ぷ':'ぽ'
};

export const VERB_TYPES = {
    U_VERB: 'う',
    RU_VERB: 'る',
    IRREGULAR: 'Irregular'
};

export function conjugateVerb(dictionaryForm, masuVerbType, teVerbType) {
    let verbPresentPositive = '';
    let verbPresentNegative = '';
    let verbPastPositive = '';
    let verbPastNegative = '';
    let verbTeForm = '';
    let verbTeFormNegative = '';
    let verbPresentNaiPositive = '';
    let verbPresentNaiNegative = '';
    let verbPastNaiPositive = '';
    let verbPastNaiNegative = '';

    let genericVerbStem = '';
    let verbStemMasu = '';
    let verbStemTe = '';
    let verbStemNai = '';
    let verbStemPotential = '';
    let verbStemVolitional = '';

    if (dictionaryForm && dictionaryForm.length > 1) {
        const verbLength = dictionaryForm.length;

        genericVerbStem = dictionaryForm.substring(0, verbLength-1);
        verbStemTe = genericVerbStem;
        verbStemMasu = verbStemTe;
        verbStemNai = genericVerbStem;
        verbStemPotential = genericVerbStem;
        verbStemVolitional = genericVerbStem;

        // Handle irregular "kuru" and "suru" verbs.
        if (masuVerbType === VERB_TYPES.IRREGULAR) {
            if (dictionaryForm.endsWith("くる") || dictionaryForm.endsWith("来る")) {
                verbStemTe = `${dictionaryForm.substring(0, verbLength-2)}き`;
                verbStemMasu = verbStemTe;
                verbStemNai = `${dictionaryForm.substring(0, verbLength-2)}こ`;
                verbStemPotential = `${dictionaryForm.substring(0, verbLength-2)}こられ`;
                verbStemVolitional = `${dictionaryForm.substring(0, verbLength-2)}こよ`;
            } else if (dictionaryForm.endsWith("する")) {
                verbStemTe = `${dictionaryForm.substring(0, verbLength-2)}し`;
                verbStemMasu = verbStemTe;
                verbStemNai = verbStemTe;
                verbStemPotential = `${dictionaryForm.substring(0, verbLength-2)}でき`;
                verbStemVolitional = `${dictionaryForm.substring(0, verbLength-2)}しよ`;
            } else {
                console.error("Unhandled irregular verb:", dictionaryForm);
            }
        }

        let lastKana = dictionaryForm.substring(verbLength-1, verbLength);

        let teSuffix = 'て';
        if (masuVerbType === VERB_TYPES.U_VERB) {

            if (dictionaryForm.endsWith('いらっしゃる') ||
                dictionaryForm.endsWith('おっしゃる') ||
                dictionaryForm.endsWith('なさる') ||
                dictionaryForm.endsWith('下さる') ||
                dictionaryForm.endsWith('くださる') ||

                dictionaryForm.endsWith('ござる') ||
                dictionaryForm.endsWith('でこざる')

            ) {
                // Irregular masu-form u-verbs
                verbStemMasu += 'い';
            } else {
                // Get regular u-verb masu form
                verbStemMasu += KANA_U_TO_I[lastKana];
            }

            // Get nai form
            if (dictionaryForm.endsWith("ある")) {
                // Special handling for ある u-verb.
                verbStemNai = dictionaryForm.substring(0, verbLength-2);
            } else {
                verbStemNai += KANA_U_TO_A[lastKana];
            }

            // Get potential form
            verbStemPotential += KANA_U_TO_E[lastKana];

            // Get volitional form
            verbStemVolitional += KANA_U_TO_O[lastKana];

            // Get te form
            switch(lastKana) {
                case 'う':
                case 'つ':
                case 'る': {
                    teSuffix = 'って'
                    break;
                }
                case 'む':
                case 'ぶ':
                case 'ぬ': {
                    teSuffix = 'んで'
                    break;
                }
                case 'す': {
                    teSuffix = 'して'
                    break;
                }
                case 'く': {
                    teSuffix = 'いて'
                    break;
                }
                case 'ぐ': {
                    teSuffix = 'いで'
                    break;
                }
                default: {
                    // TODO: shouldn't happen
                    teSuffix = 'TBD'
                }
            }
        }

        if (masuVerbType === VERB_TYPES.RU_VERB) {
            // Get potential form
            verbStemPotential += "(ら)れ";

            // Get volitional form
            verbStemVolitional += 'よ';
        }

        // Handle irregular "iku" te-form.
        if (teVerbType === VERB_TYPES.IRREGULAR) {
            if (dictionaryForm.endsWith("いく") || dictionaryForm.endsWith("行く")) {
                verbStemTe = dictionaryForm.substring(0, verbLength-1);
                teSuffix = "って";
            } else {
                // TODO:andrewReview - this catches suru and kuru right now.
                // console.error("Unhandled te-form irregular verb:", dictionaryForm);
            }
        }

        // Only set conjugations if the masu type is specified.
        if (masuVerbType) {
            verbPresentPositive = `${verbStemMasu}ます`;
            verbPresentNegative = `${verbStemMasu}ません`;
            verbPastPositive = `${verbStemMasu}ました`;
            verbPastNegative = `${verbStemMasu}ませんでした`;

            verbPresentNaiPositive = dictionaryForm;
            verbPresentNaiNegative = `${verbStemNai}ない`;
            verbPastNaiNegative = `${verbStemNai}なかった`;

            verbTeFormNegative = `${verbStemNai}なくて`;
        }

        // Only set conjugation if the te type is specified.
        if (teVerbType) {
            verbTeForm = `${verbStemTe}${teSuffix}`;

            // Past positive nai-form is constructed from te-form.
            const verbPastNaiPositiveStem = verbTeForm.substring(0, verbTeForm.length - 1);
            const pastNaiPositiveSuffix = teSuffix.endsWith('て') ? 'た': 'だ';
            verbPastNaiPositive = `${verbPastNaiPositiveStem}${pastNaiPositiveSuffix}`;
        }
    }

    const retval = {}
    retval[CONJUGATION_FORMS.DICTIONARY] = dictionaryForm;
    retval[CONJUGATION_FORMS.STEM] = verbStemMasu;
    retval[CONJUGATION_FORMS.POTENTIAL_STEM] = verbStemPotential;

    retval[CONJUGATION_FORMS.MASU_PRESENT_POSITIVE] = stripWhitespace(verbPresentPositive);
    retval[CONJUGATION_FORMS.MASU_PRESENT_NEGATIVE] = stripWhitespace(verbPresentNegative);
    retval[CONJUGATION_FORMS.MASU_PAST_POSITIVE] = stripWhitespace(verbPastPositive);
    retval[CONJUGATION_FORMS.MASU_PAST_NEGATIVE] = stripWhitespace(verbPastNegative);

    retval[CONJUGATION_FORMS.TE] = stripWhitespace(verbTeForm);
    retval[CONJUGATION_FORMS.TE_NEGATIVE] = stripWhitespace(verbTeFormNegative);

    retval[CONJUGATION_FORMS.PLAIN_PRESENT_POSITIVE] = stripWhitespace(verbPresentNaiPositive);
    retval[CONJUGATION_FORMS.PLAIN_PRESENT_NEGATIVE] = stripWhitespace(verbPresentNaiNegative);
    retval[CONJUGATION_FORMS.PLAIN_PAST_POSITIVE] = stripWhitespace(verbPastNaiPositive);
    retval[CONJUGATION_FORMS.PLAIN_PAST_NEGATIVE] = stripWhitespace(verbPastNaiNegative);

    retval[CONJUGATION_FORMS.VOLITIONAL] = stripWhitespace(`${verbStemVolitional}う`);
    return retval;
}