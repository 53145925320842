import React from "react";
import {fetchData} from '../japanese/deserializer/GenkiDeserializer';
import {BREAKPOINTS, KEYS, LABELS, LAYOUT_IDS, PARTS_OF_SPEECH_KEYS} from "../Constants";
import AppLayout from "../components/AppLayout";
import VocabularyDetail from '../japanese/components/VocabularyDetail';
import PropTypes from "prop-types";

const LESSONS = [
    {
        group: 'Class 1/2',
        tags: [
            "'会G'",
            "'会L1'",
            "'会L2'",
            "'会L3'",
            "'会L4'",
            "'会L5'",
        ]
    },
    {
        group: 'Class 3/4',
        tags: [
            "'会L6'",
            "'会L7'",
            "'会L8'",
            "'会L9'",
        ]
    },
    {
        group: 'Class 5/6',
        tags: [
            "'会L10'",
            "'会L11'",
            "'会L12'",
            "'会L13'",
            "'会L14'",
        ]
    },
    {
        group: 'Class 7/8',
        tags: [
            "'会L15'",
            "'会L16'",
            "'会L17'",
            "'会L18'",
            "'会L19'",
            "'会L20'",
        ]
    },
    {
        group: 'Class 9',
        tags: [
            "'会L21'",
            "'会L22'",
            "'会L23'",
        ]
    },
    {
        group: 'Extra',
        tags: [
            "'会L1(e)'",
            "'会L2(e)'",
            "'会L4(e)'",
            "'会L5(e)'",
            "'会L6(e)'",
            "'会L7(e)'",
            "'会L9(e)'",
            "'会L10(e)'",
            "'会L11(e)'",
            "'会L12(e)'",
            "'会L13(e)'",
            "'会L14(e)'",
            "'会L15(e)'",
            "'会L17(e)'",
        ]
    },
    {
        group: '読',
        tags: [
            "'読L4-III'",
            "'読L5-II'",
            "'読L6-I'",
            "'読L6-III'",
            "'読L7-II'",
            "'読L8-II'",
            "'読L9-II'",
            "'読L10-II'",
            "'読L11-II'",
            "'読L12-II'",
            "'読L13-II'",
            "'読L13-III'",
            "'読L14-II'",
            "'読L15-II'",
            "'読L16-II'",
            "'読L17-II'",
            "'読L18-II'",
            "'読L18-III'",
            "'読L19-II'",
            "'読L19-III'",
            "'読L20-II'",
            "'読L21-II'",
            "'読L22-II'",
            "'読L23-II'"
        ]
    }
];

const POS = [
    {
        group: 'Nouns',
        tags:[
            PARTS_OF_SPEECH_KEYS.NOUN,
        ]
    },
    {
        group: 'Verbs',
        tags:[
            PARTS_OF_SPEECH_KEYS.VERB_RU,
            PARTS_OF_SPEECH_KEYS.VERB_U,
            PARTS_OF_SPEECH_KEYS.VERB_IRR,
        ]
    },
    {
        group: 'Adjectives',
        tags:[
            PARTS_OF_SPEECH_KEYS.ADJ_NA,
            PARTS_OF_SPEECH_KEYS.ADJ_I
        ]
    },
    {
        group: 'Other',
        tags:[
            PARTS_OF_SPEECH_KEYS.ADVERB,
            PARTS_OF_SPEECH_KEYS.EXPRESSION,
            PARTS_OF_SPEECH_KEYS.PARTICLE,
            PARTS_OF_SPEECH_KEYS.PRE_NOMINAL,
            PARTS_OF_SPEECH_KEYS.SUFFIX
        ]
    }
];

function JapaneseQuestion({row, field}) {
    let questionLabel = row[field];

    if (field === KEYS.SENTENCE_KANJI) {
        questionLabel = questionLabel.replaceAll(" ", "");
    }

    return (
        <h2 style={{marginBottom:0}}>
            {questionLabel}
        </h2>
    );
}

export default class Genki extends React.Component {
    static propTypes = {
        breakpoint: PropTypes.string,
        keyPress: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            originalData: []
        };
        this.cancel = false;
    }

    componentDidMount() {
        // Fetch and parse verb CSV data.
        fetchData().then(data => {
            this.parseTsv(data);
        });
        this.cancel = false;
    }

    componentWillUnmount() {
        this.cancel = true;
    }

    parseTsv = (data) => {
        const {data : parsedData} = data;
        if (this.cancel) {
            console.log("Genki - component is unmounted - don't set state.");
            return;
        }

        this.setState({
            originalData: parsedData
        });
    }

    render() {
        const { breakpoint, keyPress } = this.props;
        const { originalData } = this.state;

        return (
            <AppLayout
                instanceId={LAYOUT_IDS.GENKI}
                data={originalData}
                columns={[
                    { dataKey: KEYS.PART_OF_SPEECH, label: LABELS[KEYS.PART_OF_SPEECH], breakpoint: BREAKPOINTS.XS_DOWN},
                    { dataKey: KEYS.VOCABULARY_KANJI, label: LABELS[KEYS.VOCABULARY_KANJI]},
                    { dataKey: KEYS.VOCABULARY_KANA, label: LABELS[KEYS.VOCABULARY_KANA], breakpoint: BREAKPOINTS.XS_DOWN},
                    { dataKey: KEYS.DEFINITION, label: LABELS[KEYS.DEFINITION]},
                ]}
                breakpoint={breakpoint}
                keyPress={keyPress}
                lessonRenderer={val => val ? val.split(',').map(val => val.replaceAll("'", "")).join(', ') : ''}
                questionRenderer={(row, questionType) => <JapaneseQuestion row={row} field={questionType}/>}
                answerRenderer={(row, questionType) => <VocabularyDetail data={row} questionType={questionType} hideBadge={true} />}
                detailRenderer={row => <VocabularyDetail data={row} hideBadge={false} />}
                detailDialogRenderer={row => <VocabularyDetail data={row} hideBadge={true} />}
                searchField={KEYS.SEARCH_STRING}
                flashcardOptions={[
                    {
                        field: KEYS.VOCABULARY_KANA,
                        label: LABELS[KEYS.VOCABULARY_KANA]
                    },
                    {
                        field: KEYS.VOCABULARY_KANJI,
                        label: LABELS[KEYS.VOCABULARY_KANJI]
                    },
                    {
                        field: KEYS.DEFINITION,
                        label: LABELS[KEYS.DEFINITION]
                    },
                    {
                        field: KEYS.SENTENCE_KANJI,
                        label: LABELS[KEYS.SENTENCE]
                    },
                    {
                        field: KEYS.SENTENCE_DEFINITION,
                        label: LABELS[KEYS.SENTENCE_DEFINITION]
                    }
                ]}
                filterOptions={[
                    {
                        modelId: 'genkiPartsOfSpeech',
                        tagCategories: POS,
                        defaultTags: [PARTS_OF_SPEECH_KEYS.VERB_IRR, PARTS_OF_SPEECH_KEYS.VERB_RU,
                            PARTS_OF_SPEECH_KEYS.VERB_U, PARTS_OF_SPEECH_KEYS.ADJ_I, PARTS_OF_SPEECH_KEYS.ADJ_NA],
                        filterFunc: selectedTags => row => selectedTags.includes(row[KEYS.PART_OF_SPEECH])
                    },
                    {
                        modelId: 'genkiLessons',
                        tagCategories: LESSONS,
                        defaultTags: ["'会L21'"],
                        filterFunc: (selectedTags) => row => {
                            let isMatch = false;
                            selectedTags.forEach(tag => {
                                if (isMatch) {
                                    return;
                                }
                                isMatch = row[KEYS.LESSON].includes(tag);
                            });
                            return isMatch;
                        },
                        checkboxLabelFunc: val => val.slice(1, val.length-1)
                    }
                ]}
            />
        );
    }
}