import React from "react";
import {PATHS, PRIMARY_TABS, SECONDARY_TABS} from "../material-ui-app/components/Navigation";
import PropTypes from 'prop-types';

export function getPathInfo() {
    let isRedirectRequired = false;
    let path1 = PATHS.ROOT;

    switch(window.location.pathname) {
        case PATHS.ROOT:
        case PATHS.DEV: {
            path1 = window.location.pathname;
            break;
        }
        default: {
            isRedirectRequired = true;
        }
    }

    const hashPathList = window.location.hash.slice(1).split("/");
    const [currHashPath1, currHashPath2] = hashPathList;

    let hashPath1 = '';
    let hashPath2 = '';
    let queryStr = '';
    if (path1 === PATHS.ROOT) {
        hashPath1 = PRIMARY_TABS.GENKI;
        switch(currHashPath1) {
            case PRIMARY_TABS.GENKI:
            case PRIMARY_TABS.MARUGOTO:
            case PRIMARY_TABS.SPEKTRUM:
            case PRIMARY_TABS.DEV: {
                hashPath1 = currHashPath1;
                break;
            }
            default: {
                isRedirectRequired = true;
            }
        }

        hashPath2 = SECONDARY_TABS.VOCABULARY;
        switch(currHashPath2) {
            case SECONDARY_TABS.VOCABULARY:
            case SECONDARY_TABS.GRAMMAR: {
                hashPath2 = currHashPath2;
                break;
            }
            default: {
                isRedirectRequired = true;
            }
        }

        let query = "";
        const lastValue = hashPathList[hashPathList.length -1];
        if (lastValue) {
            const queryArray = lastValue.split("?");
            if (queryArray && queryArray.length === 2) {
                query = queryArray[1];
            }
        }

        queryStr = query ? "?" + query : "";
    }


    /*
    let others = "";
    if (rest) {
        others = "/" + rest.join("/");
    }
    const composedPath = `${path1}#${hashPath1}/${hashPath2}${others}${queryStr}`;
    */



    // TODO:andrewReview - debug
    /*
    if (isRedirectRequired) {
        console.log("andrewReview - redirect required");
    }
    console.log("andrewReview - basePath:", `${path1}#${hashPath1}/${hashPath2}`);
    console.log("andrewReview - queryStr:", query);
    console.log("andrewReview - composedPath:", composedPath);
    console.log("andrewReview ===========");
    */


    return {
        isRedirectRequired,
        composedPath: `${path1}#${hashPath1}/${hashPath2}`,
        path1,
        hashPath1,
        hashPath2,
        queryStr
    }
}

function Show({showPath, currPath, children, isRemove}) {
    let isShow = false;

    if (Array.isArray(showPath) && Array.isArray(currPath)) {
        if (showPath.length === currPath.length && showPath.length > 0) {
            isShow = showPath.reduce((acc, val, idx) => {
                const isSamePath = val === currPath[idx];
                return (acc === null) ? isSamePath : acc && isSamePath;
            }, null);
        }
    } else {
        isShow = showPath === currPath;
    }

    if (isRemove) {
        return isShow ? children : null;
    }

    return (
        <div style={{display: isShow ? '' : 'none'}}>
            {children}
        </div>
    );
}

export default class Router extends React.Component {
    static propTypes = {
        render: PropTypes.func
    }

    static defaultProps = {
        render: () => (<div>Router.js render</div>)
    }

    constructor(props) {
        super(props);
        this.state = {
            path1: undefined,
            currHashPath1: undefined,
            currHashPath2: undefined
        }
    }

    componentDidMount() {
        window.addEventListener('hashchange', this.handleLocationChange);
        this.handleLocationChange();
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleLocationChange);
    }

    handleLocationChange = () => {
        const { currPath1, currHashPath1, currHashPath2 } = this.state;
        const { path1, hashPath1, hashPath2, isRedirectRequired, composedPath } = getPathInfo();

        // Redirect if path is not correct.
        if (isRedirectRequired) {
            window.location.replace(composedPath);
            return;
        }

        // Save path info
        const newState = {};
        if ( currPath1 !== path1) {
            newState.currPath1 = path1;
        }
        if ( currHashPath1 !== hashPath1) {
            newState.currHashPath1 = hashPath1;
        }
        if ( currHashPath2 !== hashPath2) {
            newState.currHashPath2 = hashPath2;
        }
        if (JSON.stringify(newState) !== "{}") {
            this.setState(newState);
        }
    }

    render() {
        const { currPath1, currHashPath1, currHashPath2 } = this.state;
        return this.props.render(Show, currPath1, currHashPath1, currHashPath2);
    }
}