import React from "react";
import PropTypes from "prop-types";
import {isBreakpoint} from "../util/Breakpoint";
import {BREAKPOINTS, KEYS, LAYOUT_IDS} from "../Constants";
import {Navigation} from "./Navigation";
import AppLayoutBase from "./AppLayoutBase";
import VocabularyTable from "./VocabularyTable";
import FlashcardDialog from "./FlashcardDialog";
import VocabularyDetailDialog from "./VocabularyDetailDialog";
import {handleKeyPress} from "../japanese/util/Util";
import ScrollablePane from "./ScrollablePane";
import {sortRows} from "../japanese/util/DeserializerUtil";
import CategoryFilter from "./CategoryFilter";

export default class AppLayout extends React.Component {
    static propTypes = {
        breakpoint: PropTypes.string,
        contentLeft: PropTypes.object,
        contentRight: PropTypes.object,
        footer: PropTypes.object,
        onDimensionsChange: PropTypes.func
    };

    static defaultProps = {
        detailRenderer: () => {},
        detailDialogRenderer: () => {},
        filterOptions: []
    }

    constructor(props) {
        super(props);
        this.state = {
            originalData: [],
            data: [],
            vocabularyTableHeight: '0',
            vocabularyTableWidth: '0',
            selectedRow: null,
            selectedIndex: -1,
            sortKey: null,
            sortDirection: null,
            showFilterModal: false,
            isFlashcardDialogOpen: false,
            questionType: null,
            isDetailDialogOpen: false,
            filterOptions: null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {data : propsData, keyPress, filterOptions, defaultSort} = this.props;
        const {isFlashcardDialogOpen, data, selectedIndex} = this.state;

        const newState = {};
        let isFilterRequired = false;

        if (propsData && JSON.stringify(propsData) !== JSON.stringify(this.state.originalData)) {
            newState.originalData = propsData;
            newState.data = propsData;
            isFilterRequired = true;
        }

        if (filterOptions && filterOptions.length > 0 && JSON.stringify(filterOptions) !== JSON.stringify(this.state.filterOptions)) {
            newState.filterOptions = filterOptions;
            filterOptions.forEach(val => {
                const {modelId, defaultTags} = val;
                newState[modelId] = defaultTags;
            })
            isFilterRequired = true;
        }

        if (keyPress !== prevProps.keyPress) {
            const {newIndex, newRow} = handleKeyPress(keyPress, data, selectedIndex);
            if (!isFlashcardDialogOpen) {
                // Ignore if flashcard dialog is open.
                newState.selectedIndex = newIndex;
                newState.selectedRow = newRow;
            }
        }

        let callback = undefined;
        if (isFilterRequired) {
            callback = this.filterData;
            if (defaultSort) {
                const {field, direction} = defaultSort;
                newState.sortKey = field;
                newState.sortDirection = direction;
            }
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState, callback);
        }
    }

    onDimensionsChange = val => {
        const {
            contentLeftHeight,
            contentLeftWidth
        } = val;
        this.setState({
            vocabularyTableHeight: `${contentLeftHeight}`,
            vocabularyTableWidth: `${contentLeftWidth}`
        });
    }

    filterData = () => {
        const { filterOptions } = this.props;
        const {originalData, sortKey, sortDirection} = this.state;
        if (!originalData) {
            return;
        }

        // Shallow copy original data.
        let revisedData = [...originalData];

        // Apply any custom filters.
        filterOptions.forEach(val => {
            const {modelId, filterFunc} = val;
            const selectedTags = this.state[modelId];
            revisedData = revisedData.filter(filterFunc(selectedTags));
        });

        // Sort
        revisedData = sortRows(revisedData, sortKey, sortDirection);

        this.setState({
            data: revisedData
        });
    }

    onRowSelection = (selectedRow, idx) => {
        const { data } = this.state;

        let selectedIndex = -1;
        if (idx) {
            selectedIndex = idx;
        } else if (selectedRow && data) {
            selectedIndex = data.findIndex(val => val === selectedRow);
        }

        this.setState({
            selectedRow,
            selectedIndex
        });
    }

    onSortChange = (sortKey, sortDirection) => {
        this.setState({
            sortKey,
            sortDirection
        }, this.filterData);
    }

    openDetailDialog = (row, idx) => {
        this.setState({
            isDetailDialogOpen: true,
            selectedIndex: idx,
            selectedRow: row
        });
    }

    onSearch = searchField => val => {
        if (!searchField || !val) {
            this.filterData();
            return;
        }

        // Filter by search string.
        const {originalData} = this.state;
        const data = originalData.filter(row => row[searchField].includes(val));
        this.setState({
            data
        });

    }

    openFlashcardDialog = (questionType) => () => {
        this.setState({
            questionType,
            isFlashcardDialogOpen: true
        });
    }

    render() {
        const {breakpoint, instanceId, columns, keyPress, lessonRenderer, flashcardOptions, detailDialogRenderer, footer, questionRenderer, answerRenderer, detailRenderer, filterOptions, searchField} = this.props;
        const {vocabularyTableHeight, vocabularyTableWidth, data, isFlashcardDialogOpen, selectedRow, selectedIndex, questionType, isDetailDialogOpen} = this.state;

        let bodyMargin;
        let elementMargin;
        if (isBreakpoint(BREAKPOINTS.SM_DOWN, breakpoint)) {
            bodyMargin = 'inherit';
            elementMargin = 'inherit';
        } else {
            bodyMargin = '1.5em';
            elementMargin = '-13px';
        }

        return (
            <AppLayoutBase
                bodyMargin={bodyMargin}
                header={<Navigation bodyMargin={bodyMargin} elementMargin={elementMargin}/>}
                contentLeft={
                    <React.Fragment>
                        <VocabularyTable
                            instanceId={instanceId}
                            columns={columns ? columns : []}
                            rows={data ? data : []}
                            height={vocabularyTableHeight}
                            width={vocabularyTableWidth}
                            onSelection={this.onRowSelection}
                            onSortChange={this.onSortChange}
                            onSearch={searchField ? this.onSearch(searchField) : undefined}
                            lessonRenderer={lessonRenderer}
                            breakpoint={breakpoint}
                            flashcardOptions={flashcardOptions ? flashcardOptions.map(val => {
                                return {
                                    label: val.label,
                                    clickFunc: this.openFlashcardDialog(val.field)
                                }
                            }) : undefined}
                            openDetailDialog={this.openDetailDialog}
                            keyPress={keyPress}
                            filterContent={
                                filterOptions.map(val => {
                                    return (
                                        <React.Fragment key={val.modelId}>
                                            <CategoryFilter
                                                modelId={val.modelId}
                                                categories={val.tagCategories}
                                                tags={this.state[val.modelId] ? this.state[val.modelId] : val.defaultTags}
                                                onTagChange={(selectedTags) => {
                                                    const newState = {};
                                                    newState[val.modelId] = selectedTags;
                                                    this.setState(newState, this.filterData);
                                                }}
                                                checkboxLabelFunc={val.checkboxLabelFunc}
                                            />
                                            <hr />
                                        </React.Fragment>
                                    )
                                })
                            }
                        />
                        <FlashcardDialog
                            isOpen={isFlashcardDialogOpen}
                            rows={data}
                            selectedRow={selectedRow}
                            selectedIndex={selectedIndex}
                            onSelection={this.onRowSelection}
                            lessonRenderer={lessonRenderer}
                            breakpoint={breakpoint}
                            onClose={() => {
                                this.setState({
                                    isFlashcardDialogOpen: false
                                });
                            }}
                            questionType={questionType}
                            questionRenderer={questionRenderer}
                            answerRenderer={answerRenderer}
                            keyPress={keyPress}
                        />
                        <VocabularyDetailDialog
                            isOpen={isDetailDialogOpen}
                            rows={data}
                            detailView={detailDialogRenderer(selectedRow)}
                            selectedRow={selectedRow}
                            selectedIndex={selectedIndex}
                            onSelection={this.onRowSelection}
                            lessonRenderer={lessonRenderer}
                            breakpoint={breakpoint}
                            onClose={() => {
                                this.setState({
                                    isDetailDialogOpen: false
                                });
                            }}
                        />
                    </React.Fragment>
                }
                contentRight={
                    <ScrollablePane
                        fixedFooter={
                            <div style={{margin: 'auto', fontWeight: 'bold', paddingTop: '5px'}}>
                                {/* TODO:andrewReview - make this into a component */}
                                {(selectedRow && selectedRow[KEYS.LESSON]) ? lessonRenderer(selectedRow[KEYS.LESSON]) : ''}
                            </div>
                        }
                    >
                        {/* Vertical spacer */}
                        <div style={{height: '8px'}}>&nbsp;</div>
                        {/*
                        <VocabularyDetail
                            data={selectedRow}
                            lessonRenderer={lessonRenderer}
                        />
                        */}
                        {detailRenderer(selectedRow)}
                    </ScrollablePane>
                }
                footer={footer}
                onDimensionsChange={this.onDimensionsChange}
            />
        );
    }
}