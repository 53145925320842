import React from "react";
import {AppBar, IconButton, makeStyles, Toolbar} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import {getPathInfo} from "../../components/Router";
import Collapse from '@material-ui/core/Collapse';
import GroupIcon from '@material-ui/icons/Group';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import FaceIcon from '@material-ui/icons/Face';
import LandscapeIcon from '@material-ui/icons/Landscape';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import BuildIcon from '@material-ui/icons/Build';

export const PATHS = Object.freeze({
    ROOT: '/',
    DEV: '/dev/'
});

export const PRIMARY_TABS = Object.freeze({
    GENKI: 'genki',
    MARUGOTO: 'marugoto',
    SPEKTRUM: 'spektrum',
    DEV: 'dev'
})

export const SECONDARY_TABS = Object.freeze({
    VOCABULARY: 'vocabulary',
    GRAMMAR: 'grammar'
});

const PRIMARY_TAB_LABELS = {};
PRIMARY_TAB_LABELS[PRIMARY_TABS.GENKI] = "Genki「げんき」";
PRIMARY_TAB_LABELS[PRIMARY_TABS.MARUGOTO] = "まるごと A2.2.K";
PRIMARY_TAB_LABELS[PRIMARY_TABS.SPEKTRUM] = "Spektrum A1+";
PRIMARY_TAB_LABELS[PRIMARY_TABS.DEV] = "Topics";

const PRIMARY_TAB_ICONS = {};
PRIMARY_TAB_ICONS[PRIMARY_TABS.GENKI] = <FaceIcon />;
PRIMARY_TAB_ICONS[PRIMARY_TABS.MARUGOTO] = <GroupIcon />;
PRIMARY_TAB_ICONS[PRIMARY_TABS.SPEKTRUM] = <LandscapeIcon />;
PRIMARY_TAB_ICONS[PRIMARY_TABS.DEV] = <MailIcon />;

const SECONDARY_TAB_LABELS = {};
SECONDARY_TAB_LABELS[SECONDARY_TABS.VOCABULARY] = "Vocabulary";
SECONDARY_TAB_LABELS[SECONDARY_TABS.GRAMMAR] = "Grammar";

const NAV_TREE_DATA = [
    {
        tab: PRIMARY_TABS.GENKI,
        icon: PRIMARY_TAB_ICONS[PRIMARY_TABS.GENKI],
        children: [
            {
                tab: SECONDARY_TABS.VOCABULARY,
                icon: <SpellcheckIcon />
            },
            {
                tab: SECONDARY_TABS.GRAMMAR,
                icon: <BuildIcon />
            }
        ]
    },

    /*
    {
        tab: PRIMARY_TABS.MARUGOTO,
        icon: PRIMARY_TAB_ICONS[PRIMARY_TABS.MARUGOTO],
        children: [
            {
                tab: SECONDARY_TABS.VOCABULARY,
                icon: <MailIcon />
            },
            {
                tab: SECONDARY_TABS.GRAMMAR,
                icon: <InboxIcon />,
            }
        ]
    },
    */


    {
        tab: PRIMARY_TABS.SPEKTRUM,
        icon: PRIMARY_TAB_ICONS[PRIMARY_TABS.SPEKTRUM]
    },

    /*
    {
        tab: PRIMARY_TABS.DEV
    }
    */

];

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function ListItemLink(props) {
    return <ListItem button component={'a'} {...props} />;
}

function NavTree({selectedPrimaryTab, onClose}) {
    return NAV_TREE_DATA.map(branch => (
        <NavBranch
            key={`navTree_${branch.tab}`}
            branch={branch}
            selectedPrimaryTab={selectedPrimaryTab}
            onClose={onClose}
        />
    ));
}

function NavBranch({branch, selectedPrimaryTab, onClose}) {
    const {hashPath1, hashPath2} = getPathInfo();

    let primaryTab = branch.tab;
    let primaryIcon = branch.icon;

    let secondaryTabs = branch.children ? branch.children.map(val => val.tab) : [];
    let secondaryIcons = branch.children ? branch.children.map(val => val.icon) : [];

    const classes = useStyles();
    const [open, setOpen] = React.useState(selectedPrimaryTab === primaryTab);
    const handleClick = () => {
        setOpen(!open);
    };

    // Only render primary link.
    if (secondaryTabs.length === 0) {
        return (
            <ListItemLink
                href={`#${primaryTab}`} selected={hashPath1 === primaryTab}
                onClick={onClose}
            >
                <ListItemIcon>{primaryIcon}</ListItemIcon>
                <ListItemText primary={PRIMARY_TAB_LABELS[primaryTab]} />
            </ListItemLink>
        );
    }

    // Render collapsible primary link with secondary links.
    return (
        <React.Fragment>
            {/* Primary tab */}
            <ListItem button onClick={handleClick}>
                <ListItemIcon>{primaryIcon}</ListItemIcon>
                <ListItemText primary={PRIMARY_TAB_LABELS[primaryTab]} />
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </ListItem>

            {/* Secondary tab */}
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        secondaryTabs.map((secondaryTab, i) => (
                            <ListItemLink
                                key={`secondaryTab_${primaryTab}_${secondaryTab}`}
                                href={`#${primaryTab}/${secondaryTab}`}
                                selected={hashPath1 === primaryTab && hashPath2 === secondaryTab}
                                className={classes.nested}
                                onClick={onClose}
                            >
                                <ListItemIcon>{secondaryIcons[i]}</ListItemIcon>
                                <ListItemText primary={SECONDARY_TAB_LABELS[secondaryTab]} />
                            </ListItemLink>
                        ))
                    }
                </List>
            </Collapse>
        </React.Fragment>
    );
}

export function Navigation({rightHeader, bodyMargin, elementMargin}) {
    const classes = useStyles();

    // Drawer state
    const [isDrawerOpen, setState] = React.useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState(open);
    };

    // Get path info.
    const {hashPath1: primaryTab} = getPathInfo();

    // Get selected subtab index.
    /*
    let selectedTabIndex;
    switch(secondaryTab) {
        case SECONDARY_TABS.GRAMMAR: {
            selectedTabIndex = 1;
            break;
        }
        case SECONDARY_TABS.VOCABULARY:
        default: {
            selectedTabIndex = 0;
        }
    }
    */

    return (
        <React.Fragment>

            <Drawer anchor={'left'} open={isDrawerOpen} onClose={toggleDrawer(false)}>
                <div style={{width: '250px'}} >
                    <NavTree selectedPrimaryTab={primaryTab} onClose={toggleDrawer(false)}/>
                    <Divider />
                </div>
            </Drawer>

            <AppBar position="static" color="primary">
                <Toolbar variant="dense" disableGutters={true} style={{marginLeft: bodyMargin, marginRight: bodyMargin}}>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)} style={{marginLeft: elementMargin}}>
                        <MenuIcon/>
                    </IconButton>

                    <div className={classes.title} style={{paddingRight: '60px'}}>
                        <Typography variant="h6">
                            <IconButton disabled={true} style={{color: '#fff'}}>
                                {PRIMARY_TAB_ICONS[primaryTab]}
                            </IconButton>
                            {PRIMARY_TAB_LABELS[primaryTab]}
                        </Typography>
                    </div>

                    {rightHeader}
                </Toolbar>
            </AppBar>

        </React.Fragment>
    );
}

/*
function SecondaryTabs({value, variant}) {
    const {hashPath1: primaryTab} = getPathInfo();
    return (
        <Tabs value={value} variant={variant}>
            <Tab href={`${PATHS.ROOT}#${primaryTab}/${SECONDARY_TABS.VOCABULARY}`} component={'a'} label={'Vocabulary'} />
            <Tab href={`${PATHS.ROOT}#${primaryTab}/${SECONDARY_TABS.GRAMMAR}`} component={'a'} label={'Grammar'} />
        </Tabs>
    );
}
*/