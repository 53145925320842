const PUNCTUATION = {
	'.': '。',
	'?': '？',
    ',': '、',
    ':': '：',
    '[': '「',
    ']': '」',
    '{': '【',
    '}': '】',
    '~': '～',
    '-': '・',
    '(': '（',
    ')': '）',
    ' ': '　',
    
    // TODO:andrew - this doesn't work
    '...':'…',
    
    // Passthrough
    '/':'/',
};

const ROMAJI_TO_HIRAGANA = {
    ...PUNCTUATION,
	'a': 'あ',
	'ba': 'ば',
	'be': 'べ',
	'bi': 'び',
	'bo': 'ぼ',
	'bu': 'ぶ',
	'bya': 'びゃ',
	'byo': 'びょ',
	'byu': 'びゅ',
	'cha': 'ちゃ',
	'chi': 'ち',
	'cho': 'ちょ',
	'chu': 'ちゅ',
	'da': 'だ',
	'de': 'で',
	'dji': 'ぢ',
	'do': 'ど',
	'dzu': 'づ',
	'dja': 'ぢゃ',
	'djo': 'ぢょ',
	'dju': 'ぢゅ',
	'e': 'え',
	'fu': 'ふ',
	'ga': 'が',
	'ge': 'げ',
	'gi': 'ぎ',
	'go': 'ご',
	'gu': 'ぐ',
	'gya': 'ぎゃ',
	'gyo': 'ぎょ',
	'gyu': 'ぎゅ',
	'ha': 'は',
	'he': 'へ',
	'hi': 'ひ',
	'ho': 'ほ',
	'hya': 'ひゃ',
	'hyo': 'ひょ',
	'hyu': 'ひゅ',
	'i': 'い',
	'ja': 'じゃ',
	'ji': 'じ',
	'jo': 'じょ',
	'ju': 'じゅ',
	'ka': 'か',
	'ke': 'け',
	'ki': 'き',
	'ko': 'こ',
	'ku': 'く',
	'kya': 'きゃ',
	'kyo': 'きょ',
	'kyu': 'きゅ',
	'ma': 'ま',
	'me': 'め',
	'mi': 'み',
	'mo': 'も',
	'mu': 'む',
	'mya': 'みゃ',
	'myo': 'みょ',
	'myu': 'みゅ',
	'n': 'ん',
	'na': 'な',
	'ne': 'ね',
	'ni': 'に',
	'no': 'の',
	'nu': 'ぬ',
	'nya': 'にゃ',
	'nyo': 'にょ',
	'nyu': 'にゅ',
	'o': 'お',
	'pa': 'ぱ',
	'pe': 'ぺ',
	'pi': 'ぴ',
	'po': 'ぽ',
	'pu': 'ぷ',
	'pya': 'ぴゃ',
	'pyo': 'ぴょ',
	'pyu': 'ぴゅ',
	'ra': 'ら',
	're': 'れ',
	'ri': 'り',
	'ro': 'ろ',
	'ru': 'る',
	'rya': 'りゃ',
	'ryo': 'りょ',
	'ryu': 'りゅ',
	'sa': 'さ',
	'se': 'せ',
	'sha': 'しゃ',
	'shi': 'し',
	'sho': 'しょ',
	'shu': 'しゅ',
	'so': 'そ',
	'su': 'す',
	'ta': 'た',
	'te': 'て',
	'to': 'と',
	'tsu': 'つ',
	'u': 'う',
	'wa': 'わ',
	'wo': 'を',
	'ya': 'や',
	'yo': 'よ',
	'yu': 'ゆ',
	'za': 'ざ',
	'ze': 'ぜ',
	'zo': 'ぞ',
	'zu': 'ず',
	'DOUBLE_CONSONANT': 'っ'
}

const ROMAJI_TO_KATAKANA = {
    ...PUNCTUATION,
	'a': 'ア',
	'ba': 'バ',
	'be': 'ベ',
	'bi': 'ビ',
	'bo': 'ボ',
	'bu': 'ブ',
	'bya': 'ビャ',
	'byo': 'ビョ',
	'byu': 'ビュ',
	'cha': 'チャ',
	'chi': 'チ',
	'cho': 'チョ',
	'chu': 'チュ',
	'da': 'ダ',
	'de': 'デ',
	'di': 'ディ',
	'dja': 'ヂャ',
	'dji': 'ヂ',
	'djo': 'ヂョ',
	'dju': 'ヂュ',
	'do': 'ド',
	'dzu': 'ヅ',
	'e': 'エ',
	'fi': 'フィ',
	'fu': 'フ',
	'ga': 'ガ',
	'ge': 'ゲ',
	'gi': 'ギ',
	'go': 'ゴ',
	'gu': 'グ',
	'gya': 'ギャ',
	'gyo': 'ギョ',
	'gyu': 'ギュ',
	'ha': 'ハ',
	'he': 'ヘ',
	'hi': 'ヒ',
	'ho': 'ホ',
	'hya': 'ヒャ',
	'hyo': 'ヒョ',
	'hyu': 'ヒュ',
	'i': 'イ',
	'ja': 'ジャ',
	'ji': 'ジ',
	'jo': 'ジョ',
	'ju': 'ジュ',
	'ka': 'カ',
	'ke': 'ケ',
	'ki': 'キ',
	'ko': 'コ',
	'ku': 'ク',
	'kya': 'キャ',
	'kyo': 'キョ',
	'kyu': 'キュ',
	'ma': 'マ',
	'me': 'メ',
	'mi': 'ミ',
	'mo': 'モ',
	'mu': 'ム',
	'mya': 'ミャ',
	'myo': 'ミョ',
	'myu': 'ミュ',
	'na': 'ナ',
	'ne': 'ネ',
	'ni': 'ニ',
	'no': 'ノ',
	'nu': 'ヌ',
	'nya': 'ニャ',
	'nyo': 'ニョ',
	'nyu': 'ニュ',
	'o': 'オ',
	'pa': 'パ',
	'pe': 'ペ',
	'pi': 'ピ',
	'po': 'ポ',
	'pu': 'プ',
	'pya': 'ピャ',
	'pyo': 'ピョ',
	'pyu': 'ピュ',
	'ra': 'ラ',
	're': 'レ',
	'ri': 'リ',
	'ro': 'ロ',
	'ru': 'ル',
	'rya': 'リャ',
	'ryo': 'リョ',
	'ryu': 'リュ',
	'sa': 'サ',
	'se': 'セ',
	'sha': 'シャ',
	'shi': 'シ',
	'sho': 'ショ',
	'shu': 'シュ',
	'so': 'ソ',
	'su': 'ス',
	'ta': 'タ',
	'te': 'テ',
	'ti':'ティ',
	'to': 'ト',
	'tsu': 'ツ',
	'u': 'ウ',
	'wa': 'ワ',
	'wi': 'ウィ',
	'we': 'ウェ',
	'wo': 'ヲ',
	'ya': 'ヤ',
	'yo': 'ヨ',
	'yu': 'ユ',
	'za': 'ザ',
	'ze': 'ゼ',
	'zo': 'ゾ',
	'zu': 'ズ',
	'n': 'ン',
	'DOUBLE_CONSONANT': 'ッ',
	'LONG_VOWEL': 'ー'
}

// Generate hiragana to katakana dictionary
const HIRAGANA_TO_KATAKANA = Object.keys(ROMAJI_TO_HIRAGANA).reduce((acc, romaji) => {
	const hiragana = ROMAJI_TO_HIRAGANA[romaji];
	const katakana = ROMAJI_TO_KATAKANA[romaji];
	acc[hiragana] = katakana;
	return acc;
}, {});
HIRAGANA_TO_KATAKANA['ゃ'] = 'ャ';
HIRAGANA_TO_KATAKANA['ょ'] = 'ョ';
HIRAGANA_TO_KATAKANA['ゅ'] = 'ュ';

export function toHiragana(str) {
   	let retval = '';
   	if (!str || typeof str !== "string") {
   		return retval;
   	}
   	
	let index = 0;
	const length = str.length;   
	let tmp = '';
	while(index < length) {
		const char = str.charAt(index);
		const prevChar = index > 0 ? str.charAt(index - 1) : null;
		const nextChar = index < length ? str.charAt(++index) : null;
		tmp += char;

		const isUpperCase = tmp.charAt(0) === tmp.charAt(0).toUpperCase();
		const dict = isUpperCase ? ROMAJI_TO_KATAKANA : ROMAJI_TO_HIRAGANA;

		const kana = getKana(dict, prevChar, tmp, nextChar);      
		if (!kana) {
			// TODO - handle this ??
			// console.log("not parsed:", tmp);
			continue;
		}
 
		retval += kana;
		tmp = '';
	}

   
   return retval;
}

function getKana(dict, prevCharVal, strVal, nextCharVal) {
    if (strVal.length < 1 || strVal.length > 4 || strVal === '\n') {
        return strVal;
    }
    
    const str = strVal.toLowerCase();
    const prevChar = prevCharVal ? prevCharVal.toLowerCase() : null;
    const nextChar = nextCharVal ? nextCharVal.toLowerCase() : null;
	const retval = dict[str];
	
	// Handle all known lookup values
	if (retval && (retval !== dict['n'] || !dict[`n${nextChar}`])) {
	
	    // Special case katakana long vowel
	    if (dict === ROMAJI_TO_KATAKANA &&
	        prevChar === str && /[a | i | u | e | o]/.test(str)) {
	       	return ROMAJI_TO_KATAKANA.LONG_VOWEL;
	    }

		// TODO:andrewReview - this doesn't handle 'nyu' correctly
		// 入 社[にゅう しゃ]
		console.log("nyu issue - retval:", retval);

		return retval;
	}
	
	// Handle double consonants
	if (str.length >= 3 && str.charAt(0) === str.charAt(1)) {
	    const endingKana = dict[`${str.slice(1)}`];
	    if (endingKana) {
	        const doubleConsonant = dict.DOUBLE_CONSONANT;
	        return `${doubleConsonant}${endingKana}`;
	    }
	}
	
	return null;
}

export function hiraganaToKatakana(hiraganaStr) {
	if (!hiraganaStr) {
		return hiraganaStr;
	}
	const retval = hiraganaStr.split("").map(val => {
		const katakana = HIRAGANA_TO_KATAKANA[val];
		return katakana ? katakana : val;
	}).join('');
	return retval;
}


function getKeyValue(key, value, ignoreWhitespace) {
	if (!ignoreWhitespace) {
		const keyValue = {};
		keyValue[key] = value;
		return keyValue;
	}

	if (key.trim() && value.trim()) {
		const trimmedKeyValue = {};
		trimmedKeyValue[key.trim()] = value.trim();
		return trimmedKeyValue;
	}

	return null;
}

export function getRubyTextInfo(kanjiStr, kanaStr, ignoreWhitespace) {
	let retval = [];
	if (!kanjiStr || !kanaStr) {
		return retval;
	}

	let searchStr = kanaStr;
	let key = "";
	let isFound = null;
	let isRepeatedKana = false;

	kanjiStr.split("").forEach(kanjiChar => {
		let matchedKanaIndex = searchStr.indexOf(kanjiChar);
		if (matchedKanaIndex > -1) {
			if (isFound === false) {
				// State change. Save previous kanji results.
				let furigana = searchStr.substring(0, matchedKanaIndex);

				// Handle matchedKanaIndex === 0:
				// matchedKanaIndex: 0, key: '痛', searchStr: 'いたい', kanjiChar: 'い', kanjiStr: '痛い' kanaStr: 'いたい'
				if (!furigana) {
					matchedKanaIndex = searchStr.indexOf(kanjiChar, 1);
					furigana = searchStr.substring(0, matchedKanaIndex);
					console.log(`RomajiUtil.js - Revise from {'${key}': ''} => {'${key}': '${furigana}'}`);
				}

				// Warn for these cases where the furigana boundary is ambiguous:
				// [{"山下先生":"やましたせんせい"},{"は":"は"},{"母":"はは"},{"を":"を"},{"知":"し"},{"っています。":"っています。"}]
				// [{"背":"せ"},{"が":"が"},{"低":"ひ"},{"くなかったです":"くくなかったです"}]
				// [{"五":"い"},{"つ":"つつ"}]
				if (searchStr[matchedKanaIndex] === searchStr[matchedKanaIndex + 1] && searchStr[matchedKanaIndex] === kanjiChar) {
					isRepeatedKana = true;
				}

				const keyValue = getKeyValue(key, furigana, ignoreWhitespace);
				if (keyValue) {
					retval.push(keyValue);
				}

				// Update search string.
				searchStr = searchStr.substring(matchedKanaIndex);

				// Clear key.
				key = '';
			}

			key += kanjiChar;
			isFound = true;

		} else {
			if (isFound === true) {
				// State change. Save previous kana results.
				// Key and value are the same string value.
				const keyValue = getKeyValue(key, key, ignoreWhitespace);
				if (keyValue) {
					retval.push(keyValue);
				}

				// Update search string.
				searchStr = searchStr.substring(key.length);

				// Clear key.
				key = '';
			}

			key += kanjiChar;
			isFound = false;
		}
	});

	// Add any leftover values
	if (key) {
		const keyValue = getKeyValue(key, searchStr, ignoreWhitespace);
		if (keyValue) {
			retval.push(keyValue);
		}
	}

	// Need to handle this - logging for now.
	if (isRepeatedKana) {
		console.warn("RomajiUtil.js - repeated kanji detected:", JSON.stringify(retval));
	}

	return retval;
}

/*
export function getRubyTextInfo2(kanjiStr, kanaStr, ignoreWhitespace) {
	let retval = [];
	if (!kanjiStr || ! kanaStr) {
		return retval;
	}

	function findNextMatch(str, furigana) {
		let strIndex = str.length-1;
		let furiganaIndex = furigana.length-1;
		for (let i = strIndex; i > -1; i--) {
			for (let j = furiganaIndex; j > -1; j--) {
				if (furigana[j] === str[i]) {
					return {
						str: i,
						furigana: j
					};
				}
			}
			furiganaIndex--;
		}
		return {
			str: -1,
			furigana: -1
		};
	}

	let kanjiIndex = kanjiStr.length;
	let kanaIndex = kanaStr.length;
	let key = '';
	let value = '';
	while (kanjiIndex > -1 && kanaIndex > -1) {
		const {str, furigana} = findNextMatch(kanjiStr.substring(0,kanjiIndex),kanaStr.substring(0,kanaIndex));
		if (str === kanjiIndex-1) {
			key = (kanjiStr[str] ? kanjiStr[str] : '') + key;
			value = (kanaStr[furigana] ? kanaStr[furigana] : '') + value;
		} else {
			if (key && value) {
				let item = {}
				item[key] = value;

				// TODO:andrewReview - repeated code
				if (!ignoreWhitespace || (ignoreWhitespace && key.trim() && value.trim())) {
					retval.unshift(item);
				}
			}

			// Current value:
			key = kanjiStr.substring(str + 1, kanjiIndex);
			value =  kanaStr.substring(furigana + 1, kanaIndex);
			let curr = {};
			curr[key] = value;

			// TODO:andrewReview - repeated code
			if (!ignoreWhitespace || (ignoreWhitespace && key.trim() && value.trim())) {
				retval.unshift(curr);
			}

			key = kanjiStr[str];
			value = kanaStr[furigana];
		}

		kanjiIndex = str;
		kanaIndex = furigana;
	}

	if (key && value) {
		let remainder = {};
		remainder[key] = value;

		// TODO:andrewReview - repeated code
		if (!ignoreWhitespace || (ignoreWhitespace && key.trim() && value.trim())) {
			retval.unshift(remainder);
		}
	}
	return retval;
}
*/

/*
export function getRubyTextInfo(kanjiStr, kanaStr) {
	// Handle case where there are no kanji.
	if (kanjiStr === kanaStr) {
		const results = {};
		results[kanjiStr] = kanaStr;
		return [results];
	}

	function findNextMatch(str, furigana) {
		let strIndex = str.length-1;
		let furiganaIndex = furigana.length-1;
		for (let i = strIndex; i > -1; i--) {
			for (let j = furiganaIndex; j > -1; j--) {
				if (furigana[j] === str[i]) {
					return {
						str: i,
						furigana: j
					};
				}
			}
			furiganaIndex--;
		}
		return {
			str: -1,
			furigana: -1
		};
	}

	let kanjiIndex = kanjiStr.length;
	let kanaIndex = kanaStr.length;
	let key = '';
	let value = '';
	let retval = [];
	while (kanjiIndex > -1 && kanaIndex > -1) {
		const {str, furigana} = findNextMatch(kanjiStr.substring(0,kanjiIndex),kanaStr.substring(0,kanaIndex));
		// console.log("match:", str, kanjiIndex-1, furigana, kanaIndex-1);

		if (str === kanjiIndex-1) {
			key = kanjiStr[str] + key;
			value = kanaStr[furigana] + value;
		} else {
			// Previous value
			console.log("acc key:", key);
			console.log("acc value:", value);
			if (key && value) {
				let item = {}
				item[key] = value;
				retval.unshift(item);
			}


			// Current value:
			key = kanjiStr.substring(str + 1, kanjiIndex);
			value =  kanaStr.substring(furigana + 1, kanaIndex);
			console.log("curr key:", key);
			console.log("curr value:", value);
			let curr = {};
			curr[key] = value;
			retval.unshift(curr);

			key = kanjiStr[str];
			value = kanaStr[furigana];
		}

		kanjiIndex = str;
		kanaIndex = furigana;
	}

	// console.log("key, value:", key, value);
	// console.log("retval:", retval)

	return retval;
}
*/

/*
export function getRubyTextInfo(str, furigana) {
	// Handle case where there are no kanji.
	if (str === furigana) {
		const results = {};
		results[str] = furigana;
		return [results];
	}

	let furiganaIndex = furigana.length - 1;
	let strIndex = str.length - 1;
	let key = '';
	let value = '';
	let nextKey = '';
	const retval = [];

	function findNextMatch(str, furigana) {
		let strIndex = str.length-1;
		let furiganaIndex = furigana.length-1;
		for (let i = strIndex; i > -1; i--) {
			for (let j = furiganaIndex; j > -1; j--) {
				if (furigana[j] === str[i]) {
					return i;
				}
			}
		}
		return -1;
	}

	// Iterate through every furigana char starting from the last char.
	// If the furigana char matches the str char, keep concatenating the key and value.
    // If they don't match, store the previous results. Save the new key and accumulate furigana chars until it
	// matches the next str char.
	while (furiganaIndex > -1) {
		if (furigana[furiganaIndex] === str[strIndex]) {
			// Keep accumulating key/value characters while they match.
			key = str[strIndex--] + key;
			value = furigana[furiganaIndex--] + value;
		} else {
			// Store previous key/value pair, if any.
			if (key && value) {
				const results = {};
				results[key] = value;
				retval.unshift(results);
			}

			// Start new key/value pair at the current index.
			// const nextMatch = findNextMatch(str.substring(0, strIndex), value.substring(0, furiganaIndex));
			key = str[strIndex--];
			value = furigana[furiganaIndex--];
			if (strIndex > -1) {
			// if (nextMatch > -1) {
				// Keep accumulating value chars until the next key is matched.
				nextKey = str[strIndex];
				// nextKey = str[nextMatch];
				// key = str.substring(nextMatch, strIndex + 1);
				// strIndex = nextMatch;
				while (furiganaIndex > -1 && furigana[furiganaIndex] !== nextKey) {
					value = furigana[furiganaIndex--] + value;
				}
			} else {
				// No more str chars. Accumulate remaining furigana chars.
				while (furiganaIndex > -1) {
					value = furigana[furiganaIndex--] + value;
				}
			}

			// No more furigana chars. Accumulate remaining key chars.
			if (furiganaIndex === -1 && strIndex > -1) {
				key = str.substring(0,strIndex+1) + key;
			}

			// Store key/value pair.
			const results = {};
			results[key] = value;
			retval.unshift(results);

			// Clear key and value cache.
			key = '';
			value = '';
		}
	}

	return retval;
}
 */

/*
export function getRubyTextInfo(kanjiStr, kanaStr) {
	if (!kanjiStr || !kanaStr) {
		return [];
	}

	function findFirstCharInSubstr(startIndex, searchStr, searchChar) {
		for (let i = startIndex; i < searchStr.length; i++) {
			if (searchStr[i] === searchChar) {
				return i;
			}
		}
		return -1;
	}

	let kanaValueStart = 0;
	let kanaSearchIndexStart = 0;
	let kanjiValue = "";

	let retval = [];
	kanjiStr.split('').forEach((char) => {
		let foundKanaIndex = findFirstCharInSubstr(kanaSearchIndexStart, kanaStr, char);

		console.log("char:", char, ",foundKanaIndex:", foundKanaIndex, ",kanaStr:", kanaStr);

		if (foundKanaIndex >= 0) {
			// Populate previous kanji if applicable.
			if (kanjiValue) {
				const mapping = {};
				mapping[kanjiValue] = kanaStr.substring(kanaValueStart, foundKanaIndex);
				retval.push(mapping);
			}

			// Populate matching kana.
			const mapping = {};
			mapping[char] = kanaStr[foundKanaIndex];
			retval.push(mapping);

			// Move starting kana substring index up and reset kanji kanjiValue.
			kanaValueStart = foundKanaIndex + 1;
			kanaSearchIndexStart = kanaValueStart;
			kanjiValue = "";
		} else {
			// Unmatched character is a kanji character. Append it.
			kanjiValue += char;
			// Start search at the next kana character.
			kanaSearchIndexStart++;
		}
	});

	// Populate unmatched kanji if applicable.
	if (kanjiValue) {
		const mapping = {};
		mapping[kanjiValue] = kanaStr.substring(kanaValueStart, kanaStr.length);
		retval.push(mapping);
	}

	return retval;
}
 */

// TODO:andrewReview - buggy code:
/*
kanjiStr: 聞く
kanaStr: きく

// First character is mapped to empty string - no ruby text
kanjiStr: 聞きます
kanaStr: ききます
 */
/*
export function getRubyTextInfo(kanjiStr, kanaStr) {
	if (!kanjiStr || !kanaStr) {
		return [];
	}

	function findFirstCharInSubstr(startIndex, searchStr, searchChar) {
		for (let i = startIndex; i < searchStr.length; i++) {
			if (searchStr[i] === searchChar) {
				return i;
			}
		}
		return -1;
	}

	let kanaSubstrStart = 0;
	let kanjiKey = "";

	let retval = [];
	kanjiStr.split('').forEach((char) => {
		let foundKanaIndex = findFirstCharInSubstr(kanaSubstrStart, kanaStr, char);

		if (foundKanaIndex >= 0) {
			// Populate previous kanji if applicable.
			if (kanjiKey) {
				const kanji = {};
				let kanaValue = kanaStr.substring(kanaSubstrStart, foundKanaIndex);
				if (!kanaValue && (foundKanaIndex +1) < kanaStr.length && kanaStr[foundKanaIndex +1] === kanaStr[foundKanaIndex]) {
					// TODO:andrewReview - figure out a better fix for this!
					// Workaround for matching 聞きます / ききます. foundKanaIndex stays at 0, but should be 1.
					console.log("andrewReview - weird workaround for ", kanjiStr, "/", kanaStr);
					kanaValue = kanaStr.substring(kanaSubstrStart, ++foundKanaIndex);
				}
				kanji[kanjiKey] = kanaValue;
				retval.push(kanji);
			}

			// Populate matching kana.
			const kana = {};
			kana[char] = kanaStr[foundKanaIndex];
			retval.push(kana);

			// Move starting kana substring index up and reset kanji kanjiKey.
			kanaSubstrStart = foundKanaIndex + 1;
			kanjiKey = "";
		} else {
			kanjiKey += char;
		}
	});

	return retval;
}
*/

/*
export function getDerivedKanjiStr(nonDictionaryStr, rubyTextInfo) {
	const strLength = nonDictionaryStr.length;
	const rubyTextInfoLength = rubyTextInfo.length;
	const minLength = (rubyTextInfoLength <= strLength) ? rubyTextInfoLength : strLength;

	let i = 0;
	let retval = "";
	while (i < minLength) {
		const key = Object.keys(rubyTextInfo[i])[0];
		if (key !== nonDictionaryStr[i]) {
			break;
		}
		retval += key;
		i++
	}

	if (i + 1 < strLength) {
		const remainingSubStr = nonDictionaryStr.substring(i + 1, strLength);
		retval += remainingSubStr;
	}
	return retval;
}
*/

/*
export function getNonDictionaryRubyTextInfo(nonDictionaryFormStr, dictionaryFormRubyTextInfo) {
	if (!nonDictionaryFormStr || !dictionaryFormRubyTextInfo) {
		return null;
	}

	const strLength = nonDictionaryFormStr.length;
	const rubyTextInfoLength = dictionaryFormRubyTextInfo.length;
	const minLength = (rubyTextInfoLength <= strLength) ? rubyTextInfoLength : strLength;

	let i=0;
	const retval = [];
	while (i < minLength) {
		const key = Object.keys(dictionaryFormRubyTextInfo[i])[0];
		if (key !== nonDictionaryFormStr[i]) {
			break;
		}

		retval.push(dictionaryFormRubyTextInfo[i]);
		i++
	}

	if (i+1 < strLength) {
		const remainingSubStr = nonDictionaryFormStr.substring(i+1, strLength);
		if (remainingSubStr) {
			const remaining = {};
			remaining[remainingSubStr] = remainingSubStr;
			retval.push(remaining);
		}
	}

	// TODO:andrewReview
	console.log("i:", i, "retval:", retval);

	return retval;
}
*/

/*
   if (str.length === 1) {
       if (/[a | i | u | e | o]/.test(str)) {
           return `[v: ${str}]`;
       }
       
       if (/n/.test(str) && (!/[a | i | u | e | o]/.test(nextChar))) {
           return `[n: ${str}]`;
       }
   }
   
   // TODO: shi, chi, tsu, fu (prevent si ti tu hu yi ye wi wu we)
   
   if (str.length === 2) {
       if (/[k | s | t | n | h | m | y | r | w | g | z | d | b | p | j]/.test(str.charAt(0))) {
		   if (/[a | i | u | e | o]/.test(str.charAt(1))) {
			   return `[2: - ${str}]`;
		   }
       }
   }
 */