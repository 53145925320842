import {BREAKPOINTS} from "../Constants";

const VALID_BREAKPOINTS = {};
VALID_BREAKPOINTS[BREAKPOINTS.XS_DOWN] = [BREAKPOINTS.XS_DOWN];
VALID_BREAKPOINTS[BREAKPOINTS.SM_DOWN] = [BREAKPOINTS.SM_DOWN, BREAKPOINTS.XS_DOWN];
VALID_BREAKPOINTS[BREAKPOINTS.MD_DOWN] = [BREAKPOINTS.MD_DOWN, BREAKPOINTS.SM_DOWN, BREAKPOINTS.XS_DOWN];
VALID_BREAKPOINTS[BREAKPOINTS.LG_DOWN] = [BREAKPOINTS.LG_DOWN, BREAKPOINTS.MD_DOWN, BREAKPOINTS.SM_DOWN, BREAKPOINTS.XS_DOWN];

export function getBreakpoint(isXsDown, isSmDown, isMdDown, isLgDown) {
    let retval = '';
    if (isXsDown) {
        retval = BREAKPOINTS.XS_DOWN;
    } else if (isSmDown) {
        retval = BREAKPOINTS.SM_DOWN;
    } else if (isMdDown) {
        retval = BREAKPOINTS.MD_DOWN;
    } else if (isLgDown) {
        retval = BREAKPOINTS.LG_DOWN;
    }
    return retval;
}

export function isBreakpoint(condition, breakpoint) {
    const validBreakpoints = VALID_BREAKPOINTS[condition];
    return validBreakpoints ? validBreakpoints.includes(breakpoint) : false;
}