import './style/App.css';
import Genki from './views/Genki';
import React from 'react';
import MarugotoA22K from "./views/MarugotoA22K";
import theme from './theme'
import {Navigation, PRIMARY_TABS, SECONDARY_TABS} from './components/Navigation';
import Router from '../components/Router';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import {KEYCODE} from "./Constants";
import SpektrumA1 from "./views/SpektrumA1";
import {GenkiGrammar} from "./views/GenkiGrammar";
import {getBreakpoint} from "./util/Breakpoint";
import DevApp from "./DevApp";

let lastKeyPress;

class KeyPressWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lastKeyPress
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.logKey);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.logKey)
    }

    logKey = (e) => {
        let isArrowKey = false;
        switch (e.code) {
            case KEYCODE.UP:
            case KEYCODE.DOWN:
            case KEYCODE.LEFT:
            case KEYCODE.RIGHT: {
                isArrowKey = true;
                break;
            }
            default:
            // NOOP
        }

        if (isArrowKey) {
            const propValue = `${e.code}_${Date.now()}`;
            lastKeyPress = propValue;
            this.setState({
                lastKeyPress
            });
        }
    }

    render () {
        const { render } = this.props;
        const { lastKeyPress } = this.state;
        return (
            <div>
                {render(lastKeyPress)}
            </div>
        );
    }
}

export default function App() {

    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const breakpoint = getBreakpoint(isXsDown, isSmDown, isMdDown, isLgDown);

    return (
        <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Router render={(Show, currPath1, currHashPath1, currHashPath2) => {
                return(
                    <KeyPressWrapper render={(keypress) => {
                        return (
                            <React.Fragment>
                                {/* Genki */}
                                <Show currPath={currHashPath1} showPath={PRIMARY_TABS.GENKI}>
                                    <Show currPath={currHashPath2} showPath={SECONDARY_TABS.VOCABULARY}>
                                        <Genki breakpoint={breakpoint} keyPress={keypress} />
                                    </Show>
                                    <Show currPath={currHashPath2} showPath={SECONDARY_TABS.GRAMMAR}>
                                        <GenkiGrammar breakpoint={breakpoint} />
                                    </Show>
                                </Show>

                                {/* Marugoto */}
                                <Show currPath={currHashPath1} showPath={PRIMARY_TABS.MARUGOTO}>
                                    <Show currPath={currHashPath2} showPath={SECONDARY_TABS.VOCABULARY}>
                                        <MarugotoA22K breakpoint={breakpoint} keyPress={keypress} />
                                    </Show>
                                    <Show currPath={currHashPath2} showPath={SECONDARY_TABS.GRAMMAR}>
                                        <Navigation />
                                        <div>Marugoto grammar TBD</div>
                                    </Show>
                                </Show>

                                {/* Spektrum A1 */}
                                <Show currPath={currHashPath1} showPath={PRIMARY_TABS.SPEKTRUM}>
                                    <SpektrumA1 breakpoint={breakpoint} keyPress={keypress} />
                                </Show>

                                {/* Dev */}
                                <Show currPath={currHashPath1} showPath={PRIMARY_TABS.DEV}>
                                    <Navigation />
                                    <DevApp />
                                </Show>
                            </React.Fragment>
                        );
                    }} />
                );
            }}/>
        </ThemeProvider>
    );
}
