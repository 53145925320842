import React from "react";
import {Furigana} from "./Ruby";

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import {ADJECTIVES, CONJUGATION_FORMS, KANA_TYPES, KEYS, LABELS, VERBS} from '../../Constants';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
    header: {
        textAlign: 'left',
        verticalAlign: 'top',
        fontWeight: 'bold'
    },
    conjugation: {
        textAlign: 'left'
    }
}));

export default function VocabularyDetail({data, hideBadge, questionType}) {

    const classes = useStyles(theme);

    if (!data) {
        return null;
    }

    let vocabulary = '';
    let vocabularyDefinition  = '';
    let vocabularyGrammarNotes = '';
    let pitchAccent = null;

    let sentence = null;
    let sentenceDefinition = null;

    let conjugationForms = null;

    if (data) {
        // Vocabulary
        const vocabularyKanji = data[KEYS.VOCABULARY_KANJI];
        const vocabularyKana = data[KEYS.VOCABULARY_KANA];
        vocabulary = <Furigana kanjiStr={vocabularyKanji} kanaStr={vocabularyKana} ignoreWhitespace={true}/>
        vocabularyDefinition = data[KEYS.DEFINITION];
        if (data[KEYS.GRAMMAR_NOTES]) {
            vocabularyGrammarNotes = `[${data[KEYS.GRAMMAR_NOTES]}]`;
        }


        // Pitch
        pitchAccent = data[KEYS.SECONDARY_DEFINITION];

        // Sentence
        const sentenceKanji = data[KEYS.SENTENCE_KANJI];
        const sentenceKana = data[KEYS.SENTENCE_KANA];
        if (sentenceKanji && sentenceKana) {
            sentence = <Furigana kanjiStr={sentenceKanji} kanaStr={sentenceKana} ignoreWhitespace={true}/>
            sentenceDefinition = data[KEYS.SENTENCE_DEFINITION];
        }

        if (questionType === KEYS.SENTENCE_KANJI || questionType === KEYS.SENTENCE_DEFINITION) {
            // NOOP - don't show the conjugations
        } else {
            // Conjugations
            if (data.conjugationMap &&
                data.conjugationMap[KANA_TYPES.KANJI]) {
                conjugationForms = data.conjugationMap[KANA_TYPES.KANJI];
            }
        }

    }

    let boxStyle = {textAlign:'center'};

    return (
        <Box style={boxStyle}>
            <div>
                {hideBadge ? null : getBadge(data)}

                <h2 style={{marginBottom:0}}>{vocabulary}</h2>
                <h3 style={{marginTop:0}}>{vocabularyDefinition} {vocabularyGrammarNotes}</h3>

                {Boolean(pitchAccent) &&
                    <h4 style={{marginTop:'-16px'}}>{pitchAccent}</h4>
                }

                {Boolean(sentence) && Boolean(sentenceDefinition) &&
                    <React.Fragment>
                        <h2 style={{marginBottom:0}}>{sentence}</h2>
                        <h3 style={{marginTop:0}}>{sentenceDefinition}</h3>
                    </React.Fragment>
                }

                {Boolean(conjugationForms) &&
                    <React.Fragment>
                        <Container maxWidth="sm">
                        <Grid container
                              direction="row"
                              spacing={2}
                        >
                            {/* masu-form / desu-form */}
                            <Grid item xs={12} sm={3} className={classes.header}>
                                {VERBS.includes(data[KEYS.PART_OF_SPEECH]) ? '〜ます' : '〜です'}
                            </Grid>
                            <Grid item xs={6} sm={4} className={classes.conjugation}>
                                {conjugationForms[CONJUGATION_FORMS.MASU_PRESENT_POSITIVE]}<br/>
                                {conjugationForms[CONJUGATION_FORMS.MASU_PAST_POSITIVE]}
                            </Grid>
                            <Grid item xs={6} sm={5} className={classes.conjugation}>
                                {conjugationForms[CONJUGATION_FORMS.MASU_PRESENT_NEGATIVE]}<br/>
                                {conjugationForms[CONJUGATION_FORMS.MASU_PAST_NEGATIVE]}
                            </Grid>

                            {/* te-form */}
                            <Grid item xs={12} sm={3} className={classes.header}>
                                〜て
                            </Grid>
                            <Grid item xs={6} sm={4} className={classes.conjugation}>
                                {conjugationForms[CONJUGATION_FORMS.TE]}
                            </Grid>
                            <Grid item xs={6} sm={5} className={classes.conjugation}>
                                {conjugationForms[CONJUGATION_FORMS.TE_NEGATIVE]}
                            </Grid>

                            {/* plain form */}
                            <Grid item xs={12} sm={3} className={classes.header}>
                                〜た
                            </Grid>
                            <Grid item xs={6} sm={4} className={classes.conjugation}>
                                {conjugationForms[CONJUGATION_FORMS.PLAIN_PRESENT_POSITIVE]}<br/>
                                {conjugationForms[CONJUGATION_FORMS.PLAIN_PAST_POSITIVE]}
                            </Grid>
                            <Grid item xs={6} sm={5} className={classes.conjugation}>
                                {conjugationForms[CONJUGATION_FORMS.PLAIN_PRESENT_NEGATIVE]}<br/>
                                {conjugationForms[CONJUGATION_FORMS.PLAIN_PAST_NEGATIVE]}
                            </Grid>

                            {VERBS.includes(data[KEYS.PART_OF_SPEECH]) &&
                                <React.Fragment>
                                    {/* Potential form */}
                                    <Grid item xs={12} sm={3} className={classes.header}>
                                        Potential
                                    </Grid>
                                    <Grid item xs={12} sm={9} className={classes.conjugation}>
                                        {conjugationForms[CONJUGATION_FORMS.POTENTIAL_STEM]}る
                                    </Grid>

                                    {/* Volitional form */}
                                    <Grid item xs={12} sm={3} className={classes.header}>
                                        Volitional
                                    </Grid>
                                    <Grid item xs={12} sm={9} className={classes.conjugation}>
                                        {conjugationForms[CONJUGATION_FORMS.VOLITIONAL]}
                                    </Grid>


                                </React.Fragment>
                            }
                        </Grid>
                        </Container>

                        {/*
                    <table style={{textAlign: 'left', margin: 'auto', borderSpacing: '0 1em'}}>
                        <tbody>
                        <tr>
                            <td colSpan={3} style={{textAlign: 'center'}}>
                                {VERBS.includes(data[KEYS.PART_OF_SPEECH]) ? <b>-ます</b> : <b>-です</b>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {conjugationForms[CONJUGATION_FORMS.MASU_PRESENT_POSITIVE]}<br/>
                                {conjugationForms[CONJUGATION_FORMS.MASU_PAST_POSITIVE]}
                            </td>
                            <td style={{whiteSpace: 'noWrap'}}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                            <td>
                                {conjugationForms[CONJUGATION_FORMS.MASU_PRESENT_NEGATIVE]}<br/>
                                {conjugationForms[CONJUGATION_FORMS.MASU_PAST_NEGATIVE]}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} style={{textAlign: 'center'}}>
                                <b>〜て / 〜なくて</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {conjugationForms[CONJUGATION_FORMS.TE]}
                            </td>
                            <td style={{whiteSpace: 'noWrap'}}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                            <td>
                                TBD
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} style={{textAlign: 'center'}}>
                                <b>〜た/〜ない</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {conjugationForms[CONJUGATION_FORMS.PLAIN_PRESENT_POSITIVE]}<br/>
                                {conjugationForms[CONJUGATION_FORMS.PLAIN_PAST_POSITIVE]}
                            </td>
                            <td style={{whiteSpace: 'noWrap'}}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                            <td>
                                {conjugationForms[CONJUGATION_FORMS.PLAIN_PRESENT_NEGATIVE]}<br/>
                                {conjugationForms[CONJUGATION_FORMS.PLAIN_PAST_NEGATIVE]}
                            </td>
                        </tr>
                        {VERBS.includes(data[KEYS.PART_OF_SPEECH]) &&
                            <React.Fragment>
                                <tr>
                                    <td colSpan={3} style={{textAlign: 'center'}}>
                                        <b>-Potential</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td　colSpan={3} style={{textAlign: 'center'}}>
                                        {conjugationForms[CONJUGATION_FORMS.POTENTIAL_STEM]}る
                                    </td>
                                </tr>
                            </React.Fragment>
                        }
                        </tbody>
                    </table>
                    */}
                    </React.Fragment>
                }
            </div>

        </Box>
    );
}

export function getBadge(row) {
    const defaultLabel = '(ᵔᴥᵔ)';

    if (!row) {
        return (<Chip label={defaultLabel} />);
    }

    const partOfSpeech = row[KEYS.PART_OF_SPEECH];
    const isVerbType = VERBS.includes(partOfSpeech);
    const isAdjectiveType = ADJECTIVES.includes(partOfSpeech);

    if (row[KEYS.KANJI]) {
        return (
            <Chip
                avatar={<Avatar>M</Avatar>}
                label={`${row[KEYS.KANJI]} - ${row[KEYS.KANJI_DEFINITION]}`}
                color="primary"
            />
        );
    }

    if (isVerbType || isAdjectiveType) {
        if (row[KEYS.MASU_TYPE]) {
            return (
                <Chip
                    label={row[KEYS.PART_OF_SPEECH]}
                    color={isVerbType ? 'primary' : 'secondary'}
                />
            );
        }
    }

    if (partOfSpeech && LABELS[partOfSpeech]) {
        return (
            <Chip label={LABELS[partOfSpeech]} />
        );
    }

    return (<Chip label={defaultLabel} />);
}