const KANA_TO_ROMAJI = {
    "あ": "a",
    "ば": "ba",
    "べ": "be",
    "び": "bi",
    "ぼ": "bo",
    "ぶ": "bu",
    "びゃ": "bya",
    "びょ": "byo",
    "びゅ": "byu",
    "ちゃ": "cha",
    "ち": "chi",
    "ちょ": "cho",
    "ちゅ": "chu",
    "だ": "da",
    "で": "de",
    "ぢ": "dji",
    "ど": "do",
    "づ": "dzu",
    "ぢゃ": "dja",
    "ぢょ": "djo",
    "ぢゅ": "dju",
    "え": "e",
    "ふ": "fu",
    "が": "ga",
    "げ": "ge",
    "ぎ": "gi",
    "ご": "go",
    "ぐ": "gu",
    "ぎゃ": "gya",
    "ぎょ": "gyo",
    "ぎゅ": "gyu",
    "は": "ha",
    "へ": "he",
    "ひ": "hi",
    "ほ": "ho",
    "ひゃ": "hya",
    "ひょ": "hyo",
    "ひゅ": "hyu",
    "い": "i",
    "じゃ": "ja",
    "じ": "ji",
    "じょ": "jo",
    "じゅ": "ju",
    "か": "ka",
    "け": "ke",
    "き": "ki",
    "こ": "ko",
    "く": "ku",
    "きゃ": "kya",
    "きょ": "kyo",
    "きゅ": "kyu",
    "ま": "ma",
    "め": "me",
    "み": "mi",
    "も": "mo",
    "む": "mu",
    "みゃ": "mya",
    "みょ": "myo",
    "みゅ": "myu",
    "ん": "n",
    "な": "na",
    "ね": "ne",
    "に": "ni",
    "の": "no",
    "ぬ": "nu",
    "にゃ": "nya",
    "にょ": "nyo",
    "にゅ": "nyu",
    "お": "o",
    "ぱ": "pa",
    "ぺ": "pe",
    "ぴ": "pi",
    "ぽ": "po",
    "ぷ": "pu",
    "ぴゃ": "pya",
    "ぴょ": "pyo",
    "ぴゅ": "pyu",
    "ら": "ra",
    "れ": "re",
    "り": "ri",
    "ろ": "ro",
    "る": "ru",
    "りゃ": "rya",
    "りょ": "ryo",
    "りゅ": "ryu",
    "さ": "sa",
    "せ": "se",
    "しゃ": "sha",
    "し": "shi",
    "しょ": "sho",
    "しゅ": "shu",
    "そ": "so",
    "す": "su",
    "た": "ta",
    "て": "te",
    "と": "to",
    "つ": "tsu",
    "う": "u",
    "わ": "wa",
    "を": "wo",
    "や": "ya",
    "よ": "yo",
    "ゆ": "yu",
    "ざ": "za",
    "ぜ": "ze",
    "ぞ": "zo",
    "ず": "zu",

    "ア": "a",
    "バ": "ba",
    "ベ": "be",
    "ビ": "bi",
    "ボ": "bo",
    "ブ": "bu",
    "ビャ": "bya",
    "ビョ": "byo",
    "ビュ": "byu",
    "チャ": "cha",
    "チ": "chi",
    "チョ": "cho",
    "チュ": "chu",
    "ダ": "da",
    "デ": "de",
    "ディ": "di",
    "ヂャ": "dja",
    "ヂ": "dji",
    "ヂョ": "djo",
    "ヂュ": "dju",
    "ド": "do",
    "ヅ": "dzu",
    "エ": "e",
    "フィ": "fi",
    "フ": "fu",
    "ガ": "ga",
    "ゲ": "ge",
    "ギ": "gi",
    "ゴ": "go",
    "グ": "gu",
    "ギャ": "gya",
    "ギョ": "gyo",
    "ギュ": "gyu",
    "ハ": "ha",
    "ヘ": "he",
    "ヒ": "hi",
    "ホ": "ho",
    "ヒャ": "hya",
    "ヒョ": "hyo",
    "ヒュ": "hyu",
    "イ": "i",
    "ジャ": "ja",
    "ジ": "ji",
    "ジョ": "jo",
    "ジュ": "ju",
    "カ": "ka",
    "ケ": "ke",
    "キ": "ki",
    "コ": "ko",
    "ク": "ku",
    "キャ": "kya",
    "キョ": "kyo",
    "キュ": "kyu",
    "マ": "ma",
    "メ": "me",
    "ミ": "mi",
    "モ": "mo",
    "ム": "mu",
    "ミャ": "mya",
    "ミョ": "myo",
    "ミュ": "myu",
    "ナ": "na",
    "ネ": "ne",
    "ニ": "ni",
    "ノ": "no",
    "ヌ": "nu",
    "ニャ": "nya",
    "ニョ": "nyo",
    "ニュ": "nyu",
    "オ": "o",
    "パ": "pa",
    "ペ": "pe",
    "ピ": "pi",
    "ポ": "po",
    "プ": "pu",
    "ピャ": "pya",
    "ピョ": "pyo",
    "ピュ": "pyu",
    "ラ": "ra",
    "レ": "re",
    "リ": "ri",
    "ロ": "ro",
    "ル": "ru",
    "リャ": "rya",
    "リョ": "ryo",
    "リュ": "ryu",
    "サ": "sa",
    "セ": "se",
    "シャ": "sha",
    "シ": "shi",
    "ショ": "sho",
    "シュ": "shu",
    "ソ": "so",
    "ス": "su",
    "タ": "ta",
    "テ": "te",
    "ティ": "ti",
    "ト": "to",
    "ツ": "tsu",
    "ウ": "u",
    "ワ": "wa",
    "ウィ": "wi",
    "ウェ": "we",
    "ヲ": "wo",
    "ヤ": "ya",
    "ヨ": "yo",
    "ユ": "yu",
    "ザ": "za",
    "ゼ": "ze",
    "ゾ": "zo",
    "ズ": "zu",
    "ン": "n"
};

export function kanaToRomaji(kanaListAsString) {
    const retval = kanaListAsString.split('\n').map(val => getRomaji(val));
    return retval.join('\n');
}

/**
 * Parse Genki vocabulary column values
 *
 * @param vocab - Value is expected to be in this format:
 *     よろしくお願いします[よろしくおねがいします]
 *     アジア研究[アジアけんきゅう]
 *     アメリカ
 *     イギリス
 */
export function vocabToKana(vocab) {
    const retval = vocab.split('\n').map(val => {
        const [a, b] = val.split("[");

        let kanji = '';
        let kana = '';
        if (b) {
            // Vocabulary has both kanji and kana.
            kanji = a;
            kana = b.slice(0, b.length - 1);
        } else {
            // Vocabulary string doesn't have kanji.
            kana = a;
        }

        return getSearchStr(kanji, kana);
    });
    return retval.join('\n');
}

function getSearchStr(kanji, kana) {
    const kanjiStr = kanji.replaceAll(' ','');
    const kanaStr = kana.replaceAll(' ','');
    const romaji = getRomaji(kanaStr);

    if (kanjiStr) {
        return `${kanjiStr} ${kanaStr} ${romaji}`;
    }

    if (kanaStr) {
        return `${kanaStr} ${romaji}`;
    }

    return ``;
}

function getRomaji(kana) {
    const kanaArr = kana.split("");
    let result = [];
    let prevVal = '';

    kanaArr.forEach(val => {
        let lookupVal = KANA_TO_ROMAJI[val];

        if (prevVal) {
            // Replace previous っ with current romaji initial consonant
            if (prevVal === 'っ' || prevVal === 'ッ') {
                result.pop();
                result.push(lookupVal.slice(0,1));
            }

            // Replace result with correct compound value
            if ((val === 'ゃ' || val === 'ょ' || val === 'ゅ' ||
                 val === 'ャ' || val === 'ョ' || val === 'ュ' || val === 'ィ' || val === 'ェ')) {
                result.pop();
                lookupVal = KANA_TO_ROMAJI[`${prevVal}${val}`];
            }

            // Replace katakana ー with previous romaji
            if (val === 'ー') {
                const prevMora = result[result.length-1];
                const prevVowel = prevMora.charAt(prevMora.length-1);
                if (prevVowel === 'a' || prevVowel === 'i' || prevVowel === 'u') {
                    lookupVal = prevVowel;
                } else if (prevVowel === 'e') {
                    lookupVal = 'i';
                } else if (prevVowel === 'o') {
                    lookupVal = 'o';
                }
            }
        }

        result.push(lookupVal ? lookupVal : val);
        prevVal = val;
    })

    return result.join('');
}