import React from "react";

import {BREAKPOINTS, KEYS, SORT_DIRECTION} from '../Constants';
import PropTypes from 'prop-types';
import ScrollablePane from "./ScrollablePane";
import FullScreenDialog from "./FullScreenDialog";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import {fade, makeStyles} from '@material-ui/core/styles';
import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "@material-ui/core/Button";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {isBreakpoint} from "../util/Breakpoint";

let searchInput;

function getHiddenTableHeaderCssSelectorClass(modelId) {
    return `${modelId}_hidden_table_header_row`;
}

function SearchAppBar({id, filterContent, flashcardOptions, showSearch, onChange, searchStr, onSearchStrClear,
                       leftGutter, topMargin}) {

    const flashcardLauncherStyle = {float:'left', paddingTop: '9px', marginLeft: leftGutter};

    const searchStyle = {whiteSpace: 'nowrap', float:'right'}

    const filterStyle = {float: 'right'};

    return (
        <div style={{marginTop: topMargin}}>
            <div style={flashcardLauncherStyle}>
                <FlashcardLauncher
                    id={id}
                    label={'Test'}
                    data={flashcardOptions}
                />
            </div>

            {filterContent &&
                <FullScreenDialog
                    modalTitle="Filter"
                    renderLauncher={(onClickHandler) => {
                        return (
                            <div style={filterStyle}>
                                <IconButton onClick={onClickHandler} style={{paddingRight: '.25em'}}>
                                    <FilterListIcon />
                                </IconButton>
                            </div>
                        );
                    }}>
                    {filterContent}
                </FullScreenDialog>
            }

            {showSearch &&
                <div style={searchStyle}>
                    <IconButton>
                        <SearchIcon />
                    </IconButton>
                    <InputBase
                        placeholder={`Search…`}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={onChange}
                        value={searchStr}
                        ref={(inputRef) => { searchInput = inputRef; }}
                    />
                    <span className={searchStr ? '' : 'no-visible'}>
                    <IconButton onClick={(e) => {
                        if (searchInput && searchInput.children && searchInput.children[0]) {
                            searchInput.children[0].focus();
                        }
                        onSearchStrClear(e);
                    }}>
                        <ClearIcon style={{color: '#c0c0c0'}}/>
                    </IconButton>
                        </span>
                </div>
            }


        </div>
    );
}

function FlashcardLauncher({id, label, data}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!data || data.length === 0) {
        return null;
    }

    if (data.length === 1) {
        return (<LaunchButton label={label} onClick={data[0].clickFunc} />);
    }

    return (
        <React.Fragment>
            <LaunchButton label={label} onClick={handleClick} />
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                { data ? data.map((val, idx) => {
                    const {label, clickFunc} = val;
                    return (
                        <MenuItem
                            key={`menuItem_${id}_${idx}`}
                            onClick={() => {
                                clickFunc();
                                handleClose();
                            }}
                        >
                            {label}
                        </MenuItem>
                    );
                }) : null}
            </Menu>
        </React.Fragment>
    );
}

function LaunchButton({label, onClick}) {
    return (
        <Button aria-controls="simple-menu" aria-haspopup="true" variant="contained" color="primary" onClick={onClick} size='small'>
            {label}
        </Button>
    );
}

export default class VocabularyTable extends React.Component {
    static propTypes = {
        instanceId: PropTypes.string,
        columns: PropTypes.array.isRequired,
        rows: PropTypes.array.isRequired,
        onSelection: PropTypes.func,
        lessonRenderer: PropTypes.func,
        height: PropTypes.string,
        breakpoint: PropTypes.string,
        filterContent: PropTypes.any,
        flashcardOptions: PropTypes.array,
        openDetailDialog: PropTypes.func,
        onSearch: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        onSelection: () => {},
        onSortChange: () => {},
        lessonRenderer: () => {},
        flashcardOptions: []
    };

    constructor(props) {
        super(props);
        this.state = {
            currentRows: [],
            selectedRow: null,
            searchStr: '',
            colWidths: [],
            calculatedColWidths: [],
            sortKey: null,
            sortDirection: null
        };
        this.onSearchChangeTimeout = null;
        this.onColWidthsTimeout = null;
    }

    componentDidMount() {
        const { rows } = this.props;
        if (rows && rows.length > 0) {
            this.setState({
                selectedRow: rows[0]
            }, this.rowSelectionHandler);
        }
    }

    componentWillUnmount() {
        window.clearTimeout(this.onSearchChangeTimeout);
        window.clearTimeout(this.onColWidthsTimeout);
    }

    componentDidUpdate() {
        const { rows } = this.props;
        const { currentRows } = this.state;
        if (rows !== currentRows) {
            this.setState({
                currentRows: rows,
                selectedRow: (rows && rows.length > 0) ? rows[0] : null
            }, this.rowSelectionHandler);
        }

        // Re-calculate <th> widths
        this.calculateColumnWidths();
    }

    calculateColumnWidths = () => {
        window.clearTimeout(this.onColWidthsTimeout);
        this.onColWidthsTimeout = window.setTimeout(() => {
            const className = getHiddenTableHeaderCssSelectorClass(this.props.instanceId);
            // const elements = document.getElementsByClassName(`hiddenHeader ${this.props.instanceId}_hidden`);
            const elements = document.getElementsByClassName(className);
            let tdWidths = [];
            let trWidth = 0;
            if (elements && elements.length === 1) {
                const tr = elements.item(0);

                // Get tr width.
                trWidth = tr.offsetWidth;

                // Get td list.
                const tdList = tr.cells;

                // Get array of td widths.
                let i = 0;
                while(true) {
                    const td = tdList.item(i++);
                    if (!td) { break; }
                    tdWidths.push(td.offsetWidth);
                }
            }

            const { colWidths } = this.state;
            if (JSON.stringify(colWidths) === JSON.stringify(tdWidths)) {
                return;
            }

            let calculatedColWidths = tdWidths;

            // Get revised TD widths to account for table cell borders.
            if (trWidth > 0) {
                // let runningTotal = 0;
                calculatedColWidths = tdWidths.map((val, idx) => {
                    return val;
                });
            }

            this.setState({
                colWidths: tdWidths,
                calculatedColWidths
            });

        }, 200);
    };

    onTableBodyClick = (e) => {
        const {rows} = this.props;
        if (e.target && e.target.parentNode) {
            const rowId = e.target.parentNode.getAttribute('data-row-id');
            if (!rowId) {
                return;
            }
            const selected = rows.find(val => val[KEYS.ID] === rowId);
            if (selected) {
                this.setState({
                    selectedRow: selected
                }, () => {
                    this.rowSelectionHandler();
                })
            }
        }
    }

    rowSelectionHandler = () => {
        const {onSelection} = this.props;
        const {selectedRow} = this.state;
        onSelection(selectedRow);
    }

    onSearchStrChange = (e) => {
        let val = e.target.value;
        let lowercaseVal = val.toLowerCase();
        this.updateSearchStr(lowercaseVal);
        this.setState({
            searchStr: lowercaseVal
        });
    }

    onSearchStrClear = () => {
        const val = '';
        this.updateSearchStr(val);
        this.setState({
            searchStr: val
        });
    }

    updateSearchStr = (val) => {
        const { onSearch } = this.props;
        window.clearTimeout(this.onSearchChangeTimeout);
        this.onSearchChangeTimeout = window.setTimeout(() => {
            onSearch(val);
        }, 1000);
    }

    onSort = (sortKey, sortDirection) => {
        const { onSortChange } = this.props;
        this.setState({
            sortKey,
            sortDirection
        }, () => {
            onSortChange(sortKey, sortDirection);
        });
    }

    render() {
        const {instanceId, columns, rows, breakpoint, filterContent, openDetailDialog, flashcardOptions, onSearch} = this.props;
        const {selectedRow, searchStr, calculatedColWidths, sortKey} = this.state;
        
        let margin;
        let topMargin;
        let firstCellLeftPadding;
        if (isBreakpoint(BREAKPOINTS.SM_DOWN, breakpoint)) {
            margin = '.75em';
            topMargin = '.5em';
            firstCellLeftPadding = '.75em';
        } else {
            margin = 'inherit';
            topMargin = '.75em';
            firstCellLeftPadding = '6px';
        }

        return (
            <ScrollablePane
                fixedHeader={
                    <React.Fragment>
                        {(filterContent || flashcardOptions.length > 0 || onSearch) ?
                            <SearchAppBar
                                showSearch={Boolean(onSearch)}
                                onChange={this.onSearchStrChange}
                                searchStr={searchStr}
                                onSearchStrClear={this.onSearchStrClear}
                                filterContent={filterContent}
                                id={instanceId}
                                flashcardOptions={flashcardOptions}
                                leftGutter={margin}
                                topMargin={topMargin}
                            /> : <div style={{height: '6px'}}>&nbsp;</div>
                        }

                        {/* Fixed table header */}
                        <TableHeader
                            modelId={instanceId}
                            columns={columns}
                            breakpoint={breakpoint}
                            colWidths={calculatedColWidths}
                            isHidden={false}
                            onTableHeaderClick={this.onSort}
                            sortKey={sortKey}
                            margin={firstCellLeftPadding}
                        />
                    </React.Fragment>
                }
                fixedFooter={
                    <div style={{paddingTop: margin, paddingLeft: margin, fontWeight: 'bold', paddingBottom: margin}}>
                        {rows && rows.length > 0 ? `${rows.length} entries` : ''}
                    </div>
                }
            >
                <Table
                    modelId={instanceId}
                    columns={columns}
                    rows={rows}
                    onTableBodyClick={this.onTableBodyClick}
                    onArrowClick={openDetailDialog}
                    selectedRow={selectedRow}
                    breakpoint={breakpoint}
                    colWidths={calculatedColWidths}
                    margin={firstCellLeftPadding}
                />
            </ScrollablePane>
        );
    }
}

function Table({modelId, columns, rows, onTableBodyClick, onArrowClick, selectedRow, breakpoint, colWidths, margin}) {
    const selectedRowId = selectedRow ? selectedRow[KEYS.ID] : null;
    return (
        <table className={'myTable'} width='100%'>
            <TableHeader
                modelId={modelId}
                columns={columns}
                breakpoint={breakpoint}
                colWidths={colWidths}
                isHidden={true}
                margin={margin}
            />
            <tbody onClick={onTableBodyClick}>
                <TableBodyRows
                    modelId={modelId}
                    columns={columns}
                    rows={rows}
                    selectedRowId={selectedRowId}
                    breakpoint={breakpoint}
                    onArrowClick={onArrowClick}
                    margin={margin}
                />
            </tbody>
        </table>
    )
}


function TableHeader({modelId, columns, breakpoint, colWidths, isHidden, onTableHeaderClick, sortKey, margin}) {

    // Debug flag
    const isDebug = false;

    const clickFunc = onTableHeaderClick ? onTableHeaderClick : () => {};
    let modelIdToUse = modelId;

    if (isHidden) {
        modelIdToUse = getHiddenTableHeaderCssSelectorClass(modelId);
    }

    let colIndex = 0;
    let isFirstColumn = true;
    let rowWidth = 0;
    const thList = columns.reduce((acc, col, idx) => {
        if (isBreakpoint(col.breakpoint, breakpoint)) {
            return acc;
        }

        const thProps = {
            key: `${modelIdToUse}_th_${idx}`,
        };

        let label = <TableHeaderLabel col={col} onTableHeaderClick={clickFunc} sortKey={sortKey} isFirstColumn={isFirstColumn} />
        const width = colWidths[colIndex];

        if (isHidden) {
            if (colWidths && !width) {
                // Show the "hidden" <th> row if the widths are not yet calculated.
                thProps.style = {
                    textAlign: 'left',
                }

                if (isFirstColumn) {
                    thProps.style.paddingLeft = margin;
                    isFirstColumn = false;
                }

                // Debug only
                if (isDebug) {
                    thProps.style.backgroundColor = idx % 2 === 0 ? 'green' : 'gray';
                }
            } else {
                // Debug only
                if (isDebug) {
                    thProps.style = {
                        textAlign: 'left',
                        backgroundColor: idx % 2 === 0 ? 'green' : 'gray'
                    }

                    if (isFirstColumn) {
                        thProps.style.paddingLeft = margin;
                        isFirstColumn = false;
                    }

                } else {
                    // Hide the true <th> row.
                    label = <div style={{height:'0px', visibility:'hidden'}}>{label}</div>;
                }
            }

            acc.push(
                <th {...thProps}>{label}</th>
            );
            return acc;
        }

        thProps.style = {
            display: 'none'
        };

        if (width) {
            rowWidth += width;

            thProps.style = {
                fontWeight: 700,
                textAlign: 'left',
                float: 'left',
                width: `${width}px`,
                paddingLeft: '1px',
                cursor: 'pointer',
            };

            if (isFirstColumn) {
                thProps.style.paddingLeft = margin;
                isFirstColumn = false;
            }

            if (isDebug) {
                thProps.style.backgroundColor = idx % 2 === 0 ? 'red' : 'yellow';
            }
        }

        colIndex++;

        // Display fake table header row with same dimensions.
        acc.push(<div {...thProps}>{label}</div>);
        return acc;
    }, []);

    // This className value is a CSS selector for calculating the column widths of the rendered table header.
    if (isHidden) {
        return (
            <thead>
                <tr className={`${modelIdToUse}`}>
                    {thList}
                </tr>
            </thead>
        );
    }

    return <div style={{width: `${rowWidth}px`}}>{thList}</div>;
}

function TableHeaderLabel({col, onTableHeaderClick, sortKey, isFirstColumn}) {
    const {dataKey, label} = col;
    const [sortDirection, setDirection] = React.useState(SORT_DIRECTION.NONE);

    let sortIcon;
    if (sortKey !== dataKey && sortDirection !== SORT_DIRECTION.NONE) {
        // Another sort key was selected. Reset this sort direction.
        setDirection(SORT_DIRECTION.NONE);
        sortIcon = <KeyboardArrowDownIcon style={{visibility: 'hidden'}}/>
    } else if (sortDirection === SORT_DIRECTION.NONE) {
        sortIcon = <KeyboardArrowDownIcon style={{visibility: 'hidden'}}/>
    } else if (sortDirection === SORT_DIRECTION.ASC) {
        sortIcon = <KeyboardArrowUpIcon style={{color: '#333'}}/>
    } else if (sortDirection === SORT_DIRECTION.DESC) {
        sortIcon = <KeyboardArrowDownIcon style={{color: '#333'}}/>
    }

    // TODO:andrewReview
    return (
        <div
            style={{marginLeft: isFirstColumn ? '1px' : '5px', whiteSpace: 'nowrap'}}
            onClick={(() => {
                let newSortDirection;
                if (sortDirection === SORT_DIRECTION.NONE) {
                    newSortDirection = SORT_DIRECTION.ASC;
                } else if (sortDirection === SORT_DIRECTION.ASC) {
                    newSortDirection = SORT_DIRECTION.DESC;
                } else if (sortDirection === SORT_DIRECTION.DESC) {
                    newSortDirection = SORT_DIRECTION.NONE;
                }
                setDirection(newSortDirection);
                onTableHeaderClick(dataKey, newSortDirection);
            })}
        >
            {label ? label : dataKey}
            <IconButton edge="start" color="inherit" aria-label="menu" size='small' disabled={true}>
                {sortIcon}
            </IconButton>
        </div>
    );
}

function TableBodyRows({modelId, columns, rows, selectedRowId, breakpoint, onArrowClick, margin}) {

    const columnInfo = columns.map(val => {
        const { dataKey, minWidth } = val;
        return {
            dataKey,
            minWidth,
            isHidden: isBreakpoint(val.breakpoint, breakpoint),
        }
    });

    return rows.map((row, idx) => {
        const rowId = row[KEYS.ID];
        const className = rowId === selectedRowId ? `selectedRow ${modelId}` : '';
        let isFirstColumn = true;
        return (
            <tr
                key={`${modelId}_tr_${rowId}`}
                data-row-id={rowId}
                className={className}
            >
                {columnInfo.reduce((acc, col, idx) => {

                    const {dataKey, minWidth, isHidden} = col;

                    if (isHidden) {
                        return acc;
                    }

                    const tdProps = {
                        key: `${modelId}_td_${row[KEYS.ID]}_${idx}`,
                    };

                    if (isFirstColumn) {
                        tdProps.style = {paddingLeft: margin};
                        isFirstColumn = false;
                    }

                    if (minWidth) {
                        tdProps.style.minWidth = `${minWidth}px`;
                    }

                    acc.push(
                        <td {...tdProps}>{row[dataKey]}</td>
                    );
                    return acc;
                }, [])}
                { onArrowClick ? (
                    <td key={`${modelId}_td_${row[KEYS.ID]}_arrow`}
                        className='mobileArrow'
                        onClick={() => {onArrowClick(row, idx)}}
                    >
                        <KeyboardArrowRightIcon />
                    </td>) : null
                }
            </tr>
        )
    })
}
