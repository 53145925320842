import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import React from "react";
import PropTypes from "prop-types";

export default class AppLayoutBase extends React.Component {

    static propTypes = {
        header: PropTypes.object,
        contentLeft: PropTypes.object,
        contentRight: PropTypes.object,
        footer: PropTypes.object,
        onDimensionsChange: PropTypes.func,
        bodyMargin: PropTypes.string
    };

    static defaultProps = {
        onDimensionsChange: () => {}
    };

    constructor(props) {
        super(props);
        this.state = {
            innerHeight: undefined
        }

        this.contentLeftRef = React.createRef();
        this.setTimeoutRef = null;
    }

    componentDidMount() {
        // Handle window resize for layout purposes.
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('hashchange', this.updateDimensions);
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.clearTimeout(this.setTimeoutRef);
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('hashchange', this.updateDimensions);
    }

    updateDimensions = () => {
        const { onDimensionsChange } = this.props;

        window.clearTimeout(this.setTimeoutRef);

        this.setTimeoutRef = setTimeout(() => {
            // Override height: 100vh due to Safari
            // https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
            this.setState({
                innerHeight: window.innerHeight
            }, () => {
                const contentLeftHeight = this.contentLeftRef.current ? this.contentLeftRef.current.offsetHeight : 0;
                const contentLeftWidth = this.contentLeftRef.current ? this.contentLeftRef.current.offsetWidth : 0;
                onDimensionsChange({
                    contentLeftHeight,
                    contentLeftWidth
                });
            });
        }, 100);
    }

    render() {
        const { contentLeft, contentRight, footer, bodyMargin, header } = this.props;
        const { innerHeight } = this.state;

        return (
            <Container maxWidth={'xl'} disableGutters={true}>
                <Box className='flexRoot' style={{height: `${innerHeight}px`}} boxShadow={0}>

                    {/* Header */}
                    {header}

                    {/* Body content */}
                    <Container maxWidth={false} className='flexFill' disableGutters={true}>
                        <div className='flexContainer'>
                            {/* Content */}
                            <Box className='flexFill' ml={bodyMargin} mb={bodyMargin}>
                                <Grid container item xs={12} spacing={0} style={{height: '100%'}}>

                                    {/* Left content */}
                                    <Grid item xs={12} md={contentRight ? 6 : 12} ref={this.contentLeftRef}>
                                        {contentLeft}
                                    </Grid>

                                    {/* Optional right content */}
                                    {contentRight &&
                                        <Hidden smDown>
                                            <Grid item xs={12} md={6}>
                                                {contentRight}
                                            </Grid>
                                        </Hidden>
                                    }

                                </Grid>
                            </Box>
                        </div>
                    </Container>

                    {/* Footer */}
                    {footer ? footer : null}

                </Box>
            </Container>
        );
    }
}