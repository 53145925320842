import React from 'react';
import ScrollablePane from "../components/ScrollablePane";

export default function DevAppTab1Content() {
    return (
        <React.Fragment>
            <div>ありがとう</div>
            <div>ありがとうございます</div>
            <div>いいえ</div>
            <div>いただきます</div>
            <div>いってきます</div>
            <div>いってらっしゃい</div>
            <div>おかえり（なさい）</div>
            <div>おはよう</div>
            <div>おはようございます</div>
            <div>おやすみ（なさい）</div>
            <div>ごちそうさま（でした）</div>
            <div>こんにちは</div>
            <div>こんばんは</div>
            <div>さようなら</div>
            <div>すみません</div>
            <div>ただいま</div>
            <div>はじめまして</div>
            <div>よろしくお願いします[よろしくおねがいします]</div>
            <div>アジア研究[アジアけんきゅう]</div>
            <div>アメリカ</div>
            <div>イギリス</div>
            <div>医者[いしゃ]</div>
            <div>一時[いちじ]</div>
            <div>一年生[いちねんせい]</div>
            <div>一歳[いっさい]</div>
            <div>一分[いっぷん]</div>
            <div>妹（さん）[いもうと（さん）]</div>
            <div>英語[えいご]</div>
            <div>オーストラリア</div>
            <div>お母さん[おかあさん]</div>
            <div>お父さん[おとうさん]</div>
            <div>弟（さん）[おとうと（さん）]</div>
            <div>お兄さん[おにいさん]</div>
            <div>お姉さん[おねえさん]</div>
            <div>会社員[かいしゃいん]</div>
            <div>科学[かがく]</div>
            <div>学生[がくせい]</div>
            <div>韓国[かんこく]</div>
            <div>九歳[きゅうさい]</div>
            <div>九分[きゅうふん]</div>
            <div>九時[くじ]</div>
            <div>経済[けいざい]</div>
            <div>高校[こうこう]</div>
            <div>高校生[こうこうせい]</div>
            <div>国際関係[こくさいかんけい]</div>
            <div>午後[ごご]</div>
            <div>五歳[ごさい]</div>
            <div>五時[ごじ]</div>
            <div>午前[ごぜん]</div>
            <div>五分[ごふん]</div>
            <div>コンピューター</div>
            <div>三歳[さんさい]</div>
            <div>三時[さんじ]</div>
            <div>三十分[さんじっぷん/さんじゅっぷん]</div>
            <div>三分[さんぷん]</div>
            <div>仕事[しごと]</div>
            <div>七時[しちじ]</div>
            <div>十歳[じっさい]</div>
            <div>十分[じっぷん]</div>
            <div>十一時[じゅういちじ]</div>
            <div>十一歳[じゅういっさい]</div>
            <div>十一分[じゅういっぷん]</div>
            <div>十九分[じゅうきゅうふん]</div>
            <div>十五分[じゅうごふん]</div>
            <div>十三分[じゅうさんぷん]</div>
            <div>十時[じゅうじ]</div>
            <div>十七分[じゅうななふん]</div>
            <div>十二時[じゅうにじ]</div>
            <div>十二分[じゅうにふん]</div>
            <div>十八分[じゅうはちふん/じゅうはっぷん]</div>
            <div>十四分[じゅうよんぷん]</div>
            <div>十六分[じゅうろっぷん]</div>
            <div>十歳[じゅっさい]</div>
            <div>十分[じゅっぷん]</div>
            <div>主婦[しゅふ]</div>
            <div>人類学[じんるいがく]</div>
            <div>スウェーデン</div>
            <div>政治[せいじ]</div>
            <div>専攻[せんこう]</div>
            <div>先生[せんせい]</div>
            <div>大学[だいがく]</div>
            <div>大学院生[だいがくいんせい]</div>
            <div>大学生[だいがくせい]</div>
            <div>中国[ちゅうごく]</div>
            <div>電話[でんわ]</div>
            <div>友だち[ともだち]</div>
            <div>七歳[ななさい]</div>
            <div>七分[ななふん]</div>
            <div>名前[なまえ]</div>
            <div>何[なん/なに]</div>
            <div>二歳[にさい]</div>
            <div>二時[にじ]</div>
            <div>二十分[にじっぷん]</div>
            <div>二時半[にじはん]</div>
            <div>二十分[にじゅっぷん]</div>
            <div>二分[にふん]</div>
            <div>日本[にほん]</div>
            <div>日本語[にほんご]</div>
            <div>日本人[にほんじん]</div>
            <div>二十歳[はたち]</div>
            <div>八時[はちじ]</div>
            <div>八分[はちふん]</div>
            <div>八歳[はっさい]</div>
            <div>八分[はっぷん]</div>
            <div>番号[ばんごう]</div>
            <div>ビジネス</div>
            <div>文学[ぶんがく]</div>
            <div>弁護士[べんごし]</div>
            <div>四時[よじ]</div>
            <div>四歳[よんさい]</div>
            <div>四分[よんぷん]</div>
            <div>留学生[りゅうがくせい]</div>
            <div>歴史[れきし]</div>
            <div>六歳[ろくさい]</div>
            <div>六時[ろくじ]</div>
            <div>六分[ろっぷん]</div>
            <div>私[わたし]</div>
            <div>今[いま]</div>
            <div>あの</div>
            <div>ええ</div>
            <div>そうです</div>
            <div>そうですか</div>
            <div>はい</div>
            <div>～語[～ご]</div>
            <div>～歳[～さい]</div>
            <div>～さん</div>
            <div>～時[～じ]</div>
            <div>～人[～じん]</div>
            <div>～年生[～ねんせい]</div>
            <div>半[はん]</div>
            <div>あそこ</div>
            <div>あれ</div>
            <div>いくら</div>
            <div>いす</div>
            <div>鉛筆[えんぴつ]</div>
            <div>傘[かさ]</div>
            <div>かばん</div>
            <div>喫茶店[きっさてん]</div>
            <div>銀行[ぎんこう]</div>
            <div>靴[くつ]</div>
            <div>消しゴム[けしゴム]</div>
            <div>黒板[こくばん]</div>
            <div>ここ</div>
            <div>これ</div>
            <div>財布[さいふ]</div>
            <div>魚[さかな]</div>
            <div>ジーンズ</div>
            <div>辞書[じしょ]</div>
            <div>自転車[じてんしゃ]</div>
            <div>新聞[しんぶん]</div>
            <div>そこ</div>
            <div>それ</div>
            <div>だれ</div>
            <div>机[つくえ]</div>
            <div>T シャツ</div>
            <div>電気[でんき]</div>
            <div>ドア</div>
            <div>トイレ</div>
            <div>時計[とけい]</div>
            <div>どこ</div>
            <div>図書館[としょかん]</div>
            <div>どれ</div>
            <div>とんかつ</div>
            <div>肉[にく]</div>
            <div>ノート</div>
            <div>ペン</div>
            <div>帽子[ぼうし]</div>
            <div>本[ほん]</div>
            <div>窓[まど]</div>
            <div>メニュー</div>
            <div>野菜[やさい]</div>
            <div>郵便局[ゆうびんきょく]</div>
            <div>おいしい</div>
            <div>高い[たかい]</div>
            <div>いらっしゃいませ</div>
            <div>おねがいします（～を）</div>
            <div>ください（～を）</div>
            <div>じゃあ</div>
            <div>どうぞ</div>
            <div>どうも</div>
            <div>あの</div>
            <div>この</div>
            <div>その</div>
            <div>どの</div>
            <div>～円[～えん]</div>
            <div>アイスクリーム</div>
            <div>朝[あさ]</div>
            <div>朝ご飯[あさごはん]</div>
            <div>明日[あした]</div>
            <div>家[いえ]</div>
            <div>いつ</div>
            <div>うち</div>
            <div>映画[えいが]</div>
            <div>お酒[おさけ]</div>
            <div>お茶[おちゃ]</div>
            <div>音楽[おんがく]</div>
            <div>学校[がっこう]</div>
            <div>今日[きょう]</div>
            <div>コーヒー</div>
        </React.Fragment>
    );
}