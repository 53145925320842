import React from "react";
import VocabularyTable from '../components/VocabularyTable';
import {BREAKPOINTS, KEYS, LAYOUT_IDS, PARTS_OF_SPEECH_KEYS} from "../Constants";
import {sortRows} from '../japanese/util/DeserializerUtil';
import AppLayout from "../components/AppLayout_old";
import ScrollablePane from "../components/ScrollablePane";
import VocabularyDetail from '../japanese/components/VocabularyDetail';
import {parseMarugotoTsv} from "../japanese/deserializer/MarugotoA22KDeserializer";
import {Navigation} from "../components/Navigation";

const LESSON_CATEGORIES = [
    {group: 'Lesson 1', tags: ['1','1ス']},
    {group: 'Lesson 3', tags: ['3','3ス']},
    {group: 'Lesson 5', tags: ['5','5ス']},
    {group: 'Lesson 7', tags: ['7','7ス']},
    {group: 'Lesson 9', tags: ['9','9ス']},
    {group: 'Lesson 11', tags: ['11','11ス']},
    {group: 'Lesson 13', tags: ['13','13ス']},
    {group: 'Lesson 15', tags: ['15','15ス']},
    {group: 'Lesson 17', tags: ['17','17ス']},
]

// Generate default lesson tags.
const DEFAULT_LESSON_TAGS = LESSON_CATEGORIES.reduce((acc, val) => {
    if (val.group === "Lesson 15") {
        acc = [...acc, ...val.tags];
    }
    return acc;
}, []);

const POS_CATEGORIES = [
    {group: 'Verbs', tags:[PARTS_OF_SPEECH_KEYS.VERB_RU, PARTS_OF_SPEECH_KEYS.VERB_U, PARTS_OF_SPEECH_KEYS.VERB_IRR]},
    {group: 'Other', tags:[PARTS_OF_SPEECH_KEYS.UNCATEGORIZED]},
];

// Generate default POS tags.
const DEFAULT_POS_TAGS = POS_CATEGORIES.reduce((acc, val) => {
    acc = [...acc, ...val.tags];
    return acc;
}, []);

const lessonRenderer = val => `Lesson ${val}`;

export default class MarugotoA22K extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            originalData: [],
            data: [],
            lessons: [],
            selectedLessonTags: [],
            selectedPosTags: [],
            srcType: null,
            destType: null,
            kanaType: null,
            sortKey: null,
            sortDirection: null,
            showFilterModal: false,

            vocabularyTableHeight: '0',
            vocabularyTableWidth: '0',
            selectedRow: null
        };
        this.cancel = false;
    }

    componentDidMount() {
        // Fetch and parse verb CSV data.
        fetch('/data-marugoto-a2-2-k.txt')
            .then(response => response.text())
            .then(data => this.parseTsv(data));
        this.cancel = false;
    }

    componentWillUnmount() {
        this.cancel = true;
    }

    parseTsv = (data) => {
        const {data : parsedData, lessons} = parseMarugotoTsv(data);
        if (this.cancel) {
            console.log("andrewReview - Marugoto cancel setState!");
            return;
        }

        this.setState({
            originalData: parsedData,
            data: parsedData,
            lessons,
            selectedLessonTags: DEFAULT_LESSON_TAGS,
            selectedPosTags: DEFAULT_POS_TAGS,
        }, this.filterData);
    }

    onTypeChange = (srcType, destType) => {
        this.setState({
            srcType,
            destType
        }, this.filterData);
    }

    onKanaSelect = (kanaType) => {
        this.setState({
            kanaType
        }, this.filterData)
    }

    onSortChange = (sortKey, sortDirection) => {
        this.setState({
            sortKey,
            sortDirection
        }, this.filterData);
    }

    onLessonTagChange = (val) => {
        this.setState({
            selectedLessonTags: val
        }, this.filterData);
    }

    onPosTagChange = (val) => {
        this.setState({
            selectedPosTags: val
        }, this.filterData);
    }

    filterData = () => {
        const {originalData, selectedLessonTags, selectedPosTags, sortKey, sortDirection} = this.state;
        if (!originalData) {
            return;
        }

        // Filter by lesson
        let revisedData = originalData.filter(val => selectedLessonTags.includes(val[KEYS.LESSON]));

        // Filter by POS
        revisedData = revisedData.filter(val => selectedPosTags.includes(val[KEYS.PART_OF_SPEECH]));

        // Sort rows.
        revisedData = sortRows(revisedData, sortKey, sortDirection);

        this.setState({
            data: revisedData
        });
    }

    onRowSelection = (selectedRow) => {
        this.setState({
            selectedRow
        })
    }

    render() {
        const { breakpoint, keyPress } = this.props;
        const { data, vocabularyTableHeight, vocabularyTableWidth, selectedRow } = this.state;
        const columns = [
            { dataKey: KEYS.PART_OF_SPEECH, label: 'POS', breakpoint: BREAKPOINTS.XS_DOWN},
            { dataKey: KEYS.VOCABULARY_KANJI, label: 'Kanji'},
            { dataKey: KEYS.VOCABULARY_KANA, label: 'Kana', breakpoint: BREAKPOINTS.XS_DOWN},
            { dataKey: KEYS.DEFINITION, label: 'Definition'}
        ];

        const contentLeft =
            <VocabularyTable
                instanceId={LAYOUT_IDS.MARUGOTO_A2_2_KATSUDOU}
                columns={columns}
                rows={data}
                height={vocabularyTableHeight}
                width={vocabularyTableWidth}
                onSelection={this.onRowSelection}
                onSortChange={this.onSortChange}
                lessonRenderer={lessonRenderer}
                breakpoint={breakpoint}
                keyPress={keyPress}
            />

        return (
            <AppLayout
                breakpoint={breakpoint}
                header={<Navigation />}
                contentLeft={contentLeft}
                contentRight={
                    <ScrollablePane>
                        <VocabularyDetail data={selectedRow} lessonRenderer={lessonRenderer}/>
                    </ScrollablePane>
                }
                onDimensionsChange={val => {
                    const {
                        contentLeftHeight,
                        contentLeftWidth
                    } = val;
                    this.setState({
                        vocabularyTableHeight: `${contentLeftHeight}`,
                        vocabularyTableWidth: `${contentLeftWidth}`
                    });
                }}
            />
        );
    }
}