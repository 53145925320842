// fetch data promise and result
// let fetchDataResult;

const fetchDataPromiseMap = {};
const fetchDataResultMap = {}

export function fetchData(url, parserFunc) {
    let fetchDataPromise = fetchDataPromiseMap[url];
    let fetchDataResult = fetchDataResultMap[url];
    const parse = parserFunc ? parserFunc : data => data;

    // Promise doesn't exist yet. Fetch data.
    if (!fetchDataPromise) {
        fetchDataPromise = new Promise((resolve, reject) => {
            fetch(url)
                .then(response => response.text())
                .then(data => {
                    fetchDataResult = parse(data);
                    resolve(fetchDataResult);
                });
        });
        return fetchDataPromise;
    }

    // Promise exists, but hasn't resolved yet. Return existing promise.
    if (!fetchDataResult) {
        console.log("Fetch.js - return unresolved promise");
        return fetchDataPromise;
    }

    // Data is already fetched. Return promise and resolve.
    return new Promise((resolve, reject) => {
        console.log("Fetch.js - return fetched data.");
        resolve(fetchDataResult);
    });
}

export function parseFile(data, getRow) {
    const fileLines = data.split('\n');
    const parsedData = fileLines.reduce((acc, val, idx) => {
        // Ignore label row.
        if (idx === 0) {
            return acc;
        }

        const fields = val.split('\t');

        if (fields && fields.length > 0) {
            const lastFieldIndex = fields.length -1;
            const lastField = fields[lastFieldIndex];
            if (lastField) {
                fields[lastFieldIndex] = lastField.trim();
            }
        }


        const row = getRow(fields, idx);

        acc.push(row);
        return acc;
    }, []);
    return parsedData;
}