import {KANA_TYPES, KEYS, PARTS_OF_SPEECH_KEYS, SORT_DIRECTION} from "../../Constants";
import {Furigana} from "../components/Ruby";
import React from "react";
import {ADJECTIVE_TYPES} from "../conjugator/AdjectiveConjugator";
import {stripWhitespace} from "./Util";
import {hiraganaToKatakana} from "./RomajiUtil";
import {VERB_TYPES} from "../conjugator/VerbConjugator";

export const　QUESTION_TYPE = {
    BEFORE_REVEAL: 'questionBeforeReveal',
    AFTER_REVEAL: 'questionAfterReveal',
    NOT_APPLICABLE: 'notApplicable'
};

/**
 * Get data from parsed CSV data.
 *
 * @param row
 * @param dataType
 * @param kanaType
 * @param questionType [optional]
 * @returns {null|*}
 */
export function getData(row, dataType, kanaType, questionType = QUESTION_TYPE.NOT_APPLICABLE) {

    // TODO:andrewReview - make this optional
    const isKatakana = false;

    if (dataType === KEYS.DEFINITION) {
        return row[KEYS.GRAMMAR_NOTES] ? `${row[KEYS.DEFINITION]} (${row[KEYS.GRAMMAR_NOTES]})` : row[KEYS.DEFINITION];
    }

    if (dataType === KEYS.SECONDARY_DEFINITION ||
        dataType === KEYS.SENTENCE_DEFINITION ||
        dataType === KEYS.DEFINITION_KANA ||
        dataType === KEYS.PART_OF_SPEECH ||
        dataType === KEYS.LESSON) {
        return row[dataType];
    }

    if (dataType === KEYS.VOCABULARY || dataType === KEYS.SENTENCE) {
        let kanjiStr;
        let kanaStr;

        if (dataType === KEYS.VOCABULARY) {
            kanjiStr = row[KEYS.VOCABULARY_KANJI] ? row[KEYS.VOCABULARY_KANJI] : '';
            kanaStr = row[KEYS.VOCABULARY_KANA] ? row[KEYS.VOCABULARY_KANA] : '';

            // TODO:andrewReview - handle na adjectives better.
            // Strip off (な) from any na adjectives if this is a question label.
            if (questionType === QUESTION_TYPE.BEFORE_REVEAL && row[KEYS.MASU_TYPE] === ADJECTIVE_TYPES.NA_VERB) {
                kanjiStr = kanjiStr.substring(0, kanjiStr.length - 3);
                kanaStr = kanaStr.substring(0, kanaStr.length - 3);
            }
        } else if (dataType === KEYS.SENTENCE) {
            kanjiStr = row[KEYS.SENTENCE_KANJI] ? row[KEYS.SENTENCE_KANJI] : '';
            kanaStr = row[KEYS.SENTENCE_KANA] ? row[KEYS.SENTENCE_KANA] : '';
        }

        if (isKatakana) {
            kanjiStr = hiraganaToKatakana(kanjiStr);
            kanaStr = hiraganaToKatakana(kanaStr);
        }

        // Strip out any helper whitespaces from the vocabulary or sentence values.
        const strippedKanjiStr = stripWhitespace(kanjiStr);
        const strippedKanaStr = stripWhitespace(kanaStr);
        if (questionType === QUESTION_TYPE.BEFORE_REVEAL) {
            return (kanaType === KANA_TYPES.KANJI) ?
                <Furigana kanaStr='　' kanjiStr={strippedKanjiStr} /> : strippedKanaStr;
        } else if (questionType === QUESTION_TYPE.AFTER_REVEAL) {
            return (kanaType === KANA_TYPES.KANJI) ?
                <Furigana kanaStr={kanaStr} kanjiStr={kanjiStr} ignoreWhitespace={true}/> : strippedKanaStr;
        } else {
            return (kanaType === KANA_TYPES.KANJI) ?
                strippedKanjiStr  : strippedKanaStr ;
        }
    }

    if (!row[KEYS.CONJUGATION_MAP]) {
        return null;
    }

    if (questionType === QUESTION_TYPE.BEFORE_REVEAL) {
        let kanjiStr = row[KEYS.CONJUGATION_MAP][KANA_TYPES.KANJI][dataType];
        let kanaStr = row[KEYS.CONJUGATION_MAP][KANA_TYPES.KANA][dataType];

        if (isKatakana) {
            kanjiStr = hiraganaToKatakana(kanjiStr);
            kanaStr = hiraganaToKatakana(kanaStr);
        }

        return (kanaType === KANA_TYPES.KANJI) ? <Furigana kanaStr='　' kanjiStr={kanjiStr} /> : kanaStr;
    }

    let retvalKanaType = kanaType;
    if (questionType === QUESTION_TYPE.AFTER_REVEAL) {
        retvalKanaType = kanaType === KANA_TYPES.KANJI ? KANA_TYPES.RUBY : kanaType;
    }

    if (retvalKanaType === KANA_TYPES.RUBY) {
        let conjugatedKanjiStr = row[KEYS.CONJUGATION_MAP][KANA_TYPES.KANJI][dataType];
        let conjugatedKanaStr = row[KEYS.CONJUGATION_MAP][KANA_TYPES.KANA][dataType];

        if (isKatakana) {
            conjugatedKanjiStr = hiraganaToKatakana(conjugatedKanjiStr);
            conjugatedKanaStr = hiraganaToKatakana(conjugatedKanaStr);
        }

        return <Furigana kanjiStr={conjugatedKanjiStr} kanaStr={conjugatedKanaStr} ignoreWhitespace={true}/>;
    }

    let conjugationStr = row[KEYS.CONJUGATION_MAP][retvalKanaType][dataType];
    if (isKatakana) {
        conjugationStr = hiraganaToKatakana(conjugationStr);
    }
    return conjugationStr;
}

export function sortRows(data, sortKey, sortDirection) {
    const shallowClonedData = [...data];

    if (sortKey && sortDirection && (sortDirection === SORT_DIRECTION.ASC || sortDirection === SORT_DIRECTION.DESC)) {
        shallowClonedData.sort((a, b) => {
            let aData = a[sortKey];
            let bData = b[sortKey];
            if (!aData || !bData) {
                console.log("sortKey is missing value:", sortKey);
                if (!aData) {
                    aData = '';
                }
                if (!bData) {
                    bData = '';
                }
            }
            return sortDirection === SORT_DIRECTION.ASC ? aData.localeCompare(bData) : bData.localeCompare(aData);
        });
    }

    return shallowClonedData;
}

export function getConjugationMap(row, initConjugations) {
    // Generated row[KEYS.CONJUGATION_MAP][KANA_TYPES.KANA] values.
    // Generated row[KEYS.CONJUGATION_MAP][KANA_TYPES.KANJI] values.
    const retval = [KANA_TYPES.KANA, KANA_TYPES.KANJI].reduce((acc, kanaType) => {
        // Get conjugations
        const dictionaryForm = getDictionaryForm(kanaType, row);
        const conjugations = initConjugations ? initConjugations(dictionaryForm, row) : {};

        acc[kanaType] = conjugations;
        return acc;
    }, {});

    // Generated row[KEYS.CONJUGATION_MAP][KANA_TYPES.RUBY] values.
    const conjugationTypes = Object.keys(retval[KANA_TYPES.KANA]);
    retval[KANA_TYPES.RUBY] = conjugationTypes.reduce((acc, dataType) => {
        const kanjiStr = retval[KANA_TYPES.KANJI][dataType];
        const kanaStr = retval[KANA_TYPES.KANA][dataType];
        acc[dataType] = <Furigana kanjiStr={kanjiStr} kanaStr={kanaStr} ignoreWhitespace={true}/>;
        return acc;
    }, {});

    return retval;
}

function getDictionaryForm(kanaDisplayType, verbData) {
    let retval;
    switch (kanaDisplayType) {
        case KANA_TYPES.KANA: {
            retval = verbData[KEYS.VOCABULARY_KANA];
            break;
        }
        case KANA_TYPES.RUBY:
        case KANA_TYPES.KANJI:
        default: {
            retval = verbData[KEYS.VOCABULARY_KANJI];
            break;
        }
    }
    return retval;
}

export function getAppConjugationTypes(partOfSpeech, kanjiVocabulary) {
    let masuType;
    switch(partOfSpeech) {
        case PARTS_OF_SPEECH_KEYS.ADJ_I: {
            masuType = ADJECTIVE_TYPES.I_VERB;
            break;
        }
        case PARTS_OF_SPEECH_KEYS.ADJ_NA: {
            masuType = ADJECTIVE_TYPES.NA_VERB;
            break;
        }
        case PARTS_OF_SPEECH_KEYS.VERB_IRR: {
            masuType = VERB_TYPES.IRREGULAR;
            break;
        }
        case PARTS_OF_SPEECH_KEYS.VERB_RU: {
            masuType = VERB_TYPES.RU_VERB;
            break;
        }
        case PARTS_OF_SPEECH_KEYS.VERB_U: {
            masuType = VERB_TYPES.U_VERB;
            break;
        }
        default:
            console.log("Error - unhandled partOfSpeech:", partOfSpeech);
    }

    let teType = masuType;

    // 行く is an う-verb, but has an irregular て form.
    if (partOfSpeech === PARTS_OF_SPEECH_KEYS.VERB_U &&
        (kanjiVocabulary.endsWith('行く') || kanjiVocabulary.endsWith("持っていく"))) {
        teType = VERB_TYPES.IRREGULAR;
        console.log("GenkiDeserializer.js - marking U-verb with irregular Te-form:", kanjiVocabulary);
    }

    // いい is an い-adjective, but has irregular conjugation
    if (partOfSpeech === PARTS_OF_SPEECH_KEYS.ADJ_I &&
        !kanjiVocabulary.endsWith('かわいい') &&
        kanjiVocabulary.endsWith('いい')) {
        masuType = ADJECTIVE_TYPES.IRREGULAR;
        teType = ADJECTIVE_TYPES.IRREGULAR;
        console.log("GenkiDeserializer.js - marking I-adj as irregular:", kanjiVocabulary);
    }

    return {
        masuType,
        teType
    }
}