/**
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
 *
 * @param arrayLength
 * @param excludeIndex [optional] - if specified, exclude this index
 * @returns {number}
 */
import {KEYCODE} from "../../Constants";

export function getRandomIndex(arrayLength, excludeIndex) {
    let retval = Math.floor(Math.random() * Math.floor(arrayLength));
    if (arrayLength > 1 && retval === excludeIndex) {
        retval = (retval < (arrayLength - 1)) ? retval + 1 : 0;
    }
    return retval;
}

/**
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
 *
 * export function union(setA, setB) {
 *    let _union = new Set(setA)
 *    for (let elem of setB) {
 *        _union.add(elem)
 *    }
 *    return _union
 * }
 *
 * @param arrA
 * @param arrB
 * @returns {any[]}
 */
export function union(arrA, arrB) {
    let _union = new Set(arrA)
    for (let elem of arrB) {
        _union.add(elem)
    }
    return [..._union];
}

/**
 * Parse string containing mixed kanji and kana notes.
 * Example: "行く[いく]" => {kanjiStr: "行く", kanaStr: "いく"}
 * @param txt - string in the format "行く[いく]"
 * @returns {{kanaStr: string, kanjiStr: string}}
 */
export function parseFlatRuby(txt) {
    let kanjiStr = "";
    let kanaStr = "";

    if (txt) {
        const start = txt.indexOf("[");
        const end = txt.indexOf("]");
        if (start > -1 && end > -1 && start < end) {
            kanjiStr = txt.substring(0, start);
            kanaStr = txt.substring(start + 1, end);
        } else {
            kanjiStr = txt;
            kanaStr = txt;
        }
    }

    return {
        kanjiStr,
        kanaStr
    };
}

export function stripWhitespace(val) {
    if (!val) {
        return null;
    }
    const strippedVal = val.replace(/ /g,'');
    return strippedVal;
}

export function handleKeyPress(keyPress, data, currIndex) {
    let newIndex;
    let newRow;

    const dataLength = data ? data.length : 0;
    if (keyPress && dataLength > 0) {
        const key = keyPress.split("_")[0];
        switch (key) {
            case KEYCODE.DOWN:
            case KEYCODE.RIGHT: {
                // Increment
                newIndex = (currIndex < (dataLength - 1)) ? currIndex + 1 : 0;
                newRow = data[newIndex];
                break;
            }
            case KEYCODE.UP:
            case KEYCODE.LEFT: {
                // Decrement
                newIndex = (currIndex > 0) ? currIndex - 1 : (dataLength - 1);
                newRow = data[newIndex];
                break;
            }
            default:
                // NOOP
        }
    }

    return {
        newIndex,
        newRow
    };
}