import React from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';

/**
 * Example usage:
 *
 * const COURSES = [
 *     {group: '1 & 2', tags: ["'会G'","'会L1'","'会L2'","'会L3'","'会L4'","'会L5'","'読L4'","'読L5'"]},
 *     {group: '3 & 4', tags: ["'会L6'","'会L7'","'会L8'","'会L9'","'読L6'","'読L7'","'読L8'"]},
 *     {group: '5 & 6', tags: ["'会L10'","'会L11'","'会L12'","'会L13'","'会L14'","'読L9'","'読L10'","'読L11'","'読L12'","'読L13'","'読L14'"]},
 *     {group: '7 & 8', tags: ["'会L15'","'会L16'","'会L17'","'会L18'","'会L19'","'会L20'","'読L15'","'読L16'","'読L17'","'読L18'","'読L19'","'読L20'"]},
 *     {group: '9', tags: ["'会L21'","'会L22'","'会L23'","'読L21'","'読L22'","'読L23'"]}
 * ];
 * const filters = ["'会G'","'会L1'"];
 * const filterComponent = (
 *     <LessonFilter modelId='lessons' categories={COURSES} tags={filters} onTagChange={this.onFilterChange} />
 *  );
 */
export default class CategoryFilter extends React.Component {
    static propTypes = {
        modelId: PropTypes.string.isRequired,
        categories: PropTypes.array,
        tags: PropTypes.array,
        onTagChange: PropTypes.func,
        checkboxLabelFunc: PropTypes.func
    }

    static defaultProps = {
        categories: [],
        checkboxLabelFunc: val => val
    }

    constructor(props) {
        super(props);
        this._tags = props.tags ? [...props.tags] : null;
        this.debounceRef = null;
    }

    componentDidMount() {
        const { tags } = this.props;
        this._tags = tags ? [...tags] : null;
    }

    componentWillUnmount() {
        window.clearTimeout(this.debounceRef);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { tags } = this.props;
        if (prevProps.tags !== tags) {
            this._tags = tags ? [...tags] : null;
        }
    }

    removeTag = (value) => () => {
        this._tags = this._tags.filter(val => val !== value);
        this.updateLessonFilter();
    }

    addTag = (value) => () => {
        this._tags.push(value);
        this.updateLessonFilter();
    }

    onButtonClick = (idx) => (event) => {
        const { categories} = this.props;
        const filterSet = new Set(this._tags);
        const lessonSet = new Set(categories[idx].tags);
        const isAddOp = !this.isSuperset(filterSet, lessonSet);

        if (isAddOp) {
            const unionSet = this.union(filterSet, lessonSet);
            this._tags = Array.from(unionSet);
        } else {
            const differenceSet = this.difference(filterSet, lessonSet);
            this._tags = Array.from(differenceSet);
        }

        // Update filter
        this.updateLessonFilter();
    }

    // From MDN - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
    isSuperset = (set, subset) => {
        for (let elem of subset) {
            if (!set.has(elem)) {
                return false
            }
        }
        return true
    }

    // From MDN - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
    union = (setA, setB) => {
        let _union = new Set(setA)
        for (let elem of setB) {
            _union.add(elem)
        }
        return _union
    }

    // From MDN - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
    difference = (setA, setB) => {
        let _difference = new Set(setA)
        for (let elem of setB) {
            _difference.delete(elem)
        }
        return _difference
    }

    // Call lifting function with revised filter list.
    updateLessonFilter = () => {
        // Hacky way to get this component to render immediately when this._tags is changed.
        this.setState({lastUpdate: Date.now()});

        // Debounce - don't actually change the filter until 1000ms have passed.
        window.clearTimeout(this.debounceRef);
        this.debounceRef = window.setTimeout(() => {
            this.props.onTagChange(this._tags);
        }, 500);
    }

    render() {
        const {modelId, categories, checkboxLabelFunc} = this.props;

        // Don't initialize until tags is set.
        let lessonFilterRows;
        if (this._tags) {

            // Generate checkbox cells.
            lessonFilterRows = categories.map((row, idx) => {
                const { group, tags } = row;
                let isAllChecked;
                let isSomeChecked;

                // Generate all checkboxes
                const lessonCheckboxes = tags.map((val, idx) => {
                    const isChecked = this._tags.includes(val);

                    // Determine button state.
                    isAllChecked = (isAllChecked === undefined) ? isChecked : isAllChecked && isChecked;
                    if (isChecked) {
                        isSomeChecked = true;
                    }

                    const label = checkboxLabelFunc(val);
                    if (!label) {
                        return <br key={`${modelId}_br_${idx}`} style={{clear: 'both'}} />
                    }

                    return (
                        <div key={`${modelId}_cb_${val}`} style={{whiteSpace: 'nowrap', float:'left'}}>
                            <Chip
                                style={{margin: '2px'}}
                                label={label}
                                color={isChecked ? 'secondary' : 'default'}
                                onClick={isChecked ? this.removeTag(val) : this.addTag(val)}
                            />
                        </div>
                    );
                });

                let variant = "outlined";
                let color = 'primary';
                if (isAllChecked) {
                    variant = "contained";
                    color = 'secondary';
                } else if (isSomeChecked) {
                    variant = "outlined";
                    color = 'secondary';
                }

                const filterRow = (
                    <tr key={`${modelId}_tr_${idx}`}>
                        <td style={{verticalAlign: 'top'}}>
                            <Button color={color} variant={variant} onClick={this.onButtonClick(idx)} style={{width: '100%', justifyContent: 'left'}}>
                                <span style={{whiteSpace: 'nowrap'}}>{group}</span>
                            </Button>
                        </td>
                        <td style={{width: '100%'}}>
                            {lessonCheckboxes}
                        </td>
                    </tr>
                );

                return filterRow;
            });
        }

        return (
            <table style={{borderSpacing: '6px'}}>
                <tbody>
                    {lessonFilterRows}
                </tbody>
            </table>
        );
    }
}