import React from 'react';
// import BootstrapApp from './bootstrap-app/App';
import MaterialUiApp from './material-ui-app/App';
import Router from './components/Router';
import { PATHS } from './material-ui-app/components/Navigation';
import DevApp from "./material-ui-app/DevApp";

export default function Root() {
    return <Router render={(Show, currPath1, currHashPath1, currHashPath2) => {
        return (
            <React.Fragment>
                <Show currPath={currPath1} showPath={PATHS.ROOT} isRemove={true}>
                    <MaterialUiApp/>
                </Show>
                <Show currPath={currPath1} showPath={PATHS.DEV} isRemove={true}>
                    <DevApp />
                </Show>

                {/* To fully enable, uncomment the react-bootstrap links in index.html */}
                {/*
                <Show currPath={currPath1} showPath={PATHS.DEV} isRemove={true}>
                    <BootstrapApp />
                </Show>
                */}
            </React.Fragment>
        );
    }}/>
}
