import React from 'react';
import { getRubyTextInfo } from '../util/RomajiUtil'

export function Ruby(props) {
    const { value, children } = props;
    return (
        <ruby>
            {children}<rp>(</rp><rt>{value}</rt><rp>)</rp>
        </ruby>
    );
}

export function Furigana(props) {
    const { kanjiStr, kanaStr, ignoreWhitespace } = props;
    const rubyTextInfo = getRubyTextInfo(kanjiStr, kanaStr, ignoreWhitespace);
    const retval = (
        <React.Fragment>
            {rubyTextInfo.reduce((acc, keyValue, idx) => {
                const kanji = Object.keys(keyValue)[0];
                let kana = Object.values(keyValue)[0];

                // Don't show kana ruby on top of kana.
                if (kanji === kana || kana.trim() === "") {
                    kana = '　';
                    kanji.split("").forEach((char, sub_idx) => {
                        acc.push(<Ruby key={`${idx}_${sub_idx}`} value={kana}>{char}</Ruby>)
                    });
                } else {
                    acc.push(<Ruby key={idx} value={kana}>{kanji}</Ruby>);
                }
                return acc;
            },[])}
        </React.Fragment>
    );
    return retval;
}

export function SpacedKana(props) {
    const { kanjiStr, kanaStr, ignoreWhitespace } = props;
    const rubyTextInfo = getRubyTextInfo(kanjiStr, kanaStr, ignoreWhitespace);
    if (!rubyTextInfo || rubyTextInfo.length === 0) {
        return null;
    }
    const kanaTokens = rubyTextInfo.map((keyValue, idx) => Object.values(keyValue)[0]);
    const retval = kanaTokens.join("・");
    return retval;
}