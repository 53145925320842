import {KEYS, PARTS_OF_SPEECH_KEYS} from '../../Constants';
import {parseFlatRuby} from "../util/Util";
import {getAppConjugationTypes, getConjugationMap} from "../util/DeserializerUtil";
import {conjugateVerb} from "../conjugator/VerbConjugator";

// TODO:andrewReview - move somewhere else
export function compareAsInt(a, b) {
    if (a === b) {
        return 0;
    }
    const numA = parseInt(a);
    const numB = parseInt(b);

    // Handle lessons ending in ス.
    // Ex: '1ス' vs '1'
    if ((numA === numB) &&
        (a !== String(numA) || b !== String(numB))) {
        return (a < b) ? -1 : 1;
    }

    return (numA < numB) ? -1 : 1;
}

function lessonSort(rowA, rowB) {
    return compareAsInt(rowA[KEYS.LESSON], rowB[KEYS.LESSON]);
}

/**
 * Parse noun TSV data.
 *
 * @param data
 * @returns {{data: *[], labels: {}}}
 */
export function parseMarugotoTsv(data) {
    let labels = {};
    const lessons = new Set();
    const fileLines = data.split('\n');

    const rawData = fileLines.reduce((acc, val, idx) => {
        const fields = val.split('\t');
        const row = {};

        // Fields directly from data.
        row[KEYS.ID] = String(idx + 1);

        // Get field values.
        /* eslint-disable no-unused-vars */
        const [_hiraganaIndex, _wordOriginal, _kanji, _word, _furigana, _dictionary, _partOfSpeech, _accent, _english, _lesson] = fields;

        // Get lesson values
        row[KEYS.LESSON] = _lesson ? _lesson.trim() : '';

        // Parse from flat ruby value
        row[KEYS.VOCABULARY] = _furigana ? _furigana : _word;
        const {kanaStr, kanjiStr} = parseFlatRuby(row[KEYS.VOCABULARY]);
        row[KEYS.VOCABULARY_KANA] = kanaStr;
        row[KEYS.VOCABULARY_KANJI] = kanjiStr;

        // Part of speech
        row[KEYS.PART_OF_SPEECH] = _partOfSpeech ? _partOfSpeech : PARTS_OF_SPEECH_KEYS.UNCATEGORIZED;


        row[KEYS.DEFINITION] = _english;
        row[KEYS.SECONDARY_DEFINITION] = _accent;

        if (idx === 0) {
            // Label row
            row[KEYS.LESSON] = 'lessons';
            labels = row;
        } else {
            // Non-label rows
            acc.push(row);

            // Handle verb conjugation
            if (_partOfSpeech) {
                const {kanaStr: dictKanaStr, kanjiStr: dictKanjiStr} = parseFlatRuby(_dictionary);

                const verbObject = {};
                verbObject[KEYS.VOCABULARY_KANA] = dictKanaStr;
                verbObject[KEYS.VOCABULARY_KANJI] = dictKanjiStr;

                const {masuType, teType} = getAppConjugationTypes(_partOfSpeech, dictKanjiStr);

                // TODO:andrewReview - not sure whether these are needed here.
                row[KEYS.MASU_TYPE] = masuType;
                row[KEYS.TE_TYPE] = teType;

                row[KEYS.CONJUGATION_MAP] = getConjugationMap(
                    verbObject,
                    (dictionaryForm) => conjugateVerb(dictionaryForm, masuType, teType)
                );
            }

            // Add lesson values
            if (row[KEYS.LESSON]) {
                lessons.add(row[KEYS.LESSON]);
            }
        }

        return acc;
    }, []);

    // Resort by lesson and revise index.
    const sortedData = rawData.sort(lessonSort);
    const revisedData = sortedData.map((row, idx) => {
        row[KEYS.ID] = String(idx + 1);
        return row;
    });

    return {
        data: revisedData,
        lessons: [...lessons].sort(compareAsInt),
        labels
    }
}