import React from 'react';
import {Navigation} from "../components/Navigation";
import AppLayoutBase from "../components/AppLayoutBase";
import {isBreakpoint} from "../util/Breakpoint";
import {BREAKPOINTS} from "../Constants";
import ScrollablePane from "../components/ScrollablePane";

export function GenkiGrammar({breakpoint}) {
    const [height, setHeight] = React.useState(0);

    // TODO:andrewReview
    let bodyMargin;
    let elementMargin;
    let paddingLeft;
    if (isBreakpoint(BREAKPOINTS.SM_DOWN, breakpoint)) {
        /*
        bodyMargin = '0px';
        elementMargin = '-7px';
        paddingLeft = '7px';
        */

        bodyMargin = '.5em';
        elementMargin = '-7px';
        paddingLeft = '.75em';
    } else {
        bodyMargin = '.75em';
        elementMargin = '-13px';
        paddingLeft = '1em'
    }

    return (
        <React.Fragment>
            <AppLayoutBase
                header={<Navigation bodyMargin={bodyMargin} elementMargin={elementMargin} />}
                contentLeft={
                    <ScrollablePane>
                        <div style={{paddingLeft: paddingLeft}}>
                        <pre>
{`// Ch 1
1. Xは Yです
2. Xは Yですか。[Question sentences]
3. noun1　の noun2

// Ch 2
1. これ それ あれ どれ
2. この/その/あの/どの + noun
3. ここ そこ あそこ どこ
4. 誰の noun
5. noun も
6. noun じゃないです
7. 〜ね/〜よ

// Ch 3
1. Verb conjugation [ます-forms, present]
   - dictionary form
   - present, affirmative  〜ます
   - present, negative     〜ません
   - stem
2. Verb types and the "present tense" [habitual or future actions]
   - habitual actions;  ex: よくテレビを見ます。
   - future actions;    ex: 明日京都に行きます。
3. Particles [を で に へ]
4. Time reference
   - に required for days of weeks / and numerical time expressions:
     日曜日に, 十時四十五分に, 九月に
   - に not required:
     明日, 毎晩, いつ
   - に optional for parts of day / weekend:
     朝(に), 週末(に)
5. 〜ませんか
6. Word order
   - topic, time, place, object, verb    ex:　私は 今日 図書館で 日本語を 勉強します。
   - topic, frequency, time, goal, verb  ex:　私は よく 七時ごろ 家へ 帰ります。
7. Frequency adverbs
   - 毎日
   - よく
   - 時々
   - 全然 〜ません
   - あまり 〜ません
8. The topic particle は

// Ch 4
1. Xが あります/います
2. Describing where things are
   Xは Yの [location word] です。
   Xは Yと Zの 間です。
3. Past tense of です
4. Past tense of verbs
   - past, affirmative  〜ました
   - past, negative     〜ませんでした
5. も [も/にも]
6. 一時間
7. たくさん
8. と
   - connect two nouns;  ex: 日本語と英語を話します。
   - together with;      ex: スーさんと韓国に行きます。

// Ch 5
1. Adjectives
2. 好き(な)/嫌い(な)
3. 〜ましょう/〜ましょうか
4. Counting [counters]

// Ch 6
1. て-form
2. 〜てください
3. 〜てもいいです
4. 〜てはいけません
5. て-forms for joining verbs [Describing two activities]
   [verb stem]て + verb2
6. 〜から
7. 〜ましょうか

// Ch 7
1. 〜ている
2. メアリーさんは髪が長いです
3. て-forms for joining sentences
4. verb stem + にいく
5. Counting people

// Ch 8
1. Short forms
2. Informal speech
3. 〜と思います/〜と言っていました
4. 〜ないでください
5. verbのが好きです
6. が
7. 何か and　何も

// Ch 9
1. Past tense short forms
2. Qualifying nouns with verbs and adjectives
3. まだ〜ていません
4. 〜から

// Ch 10
1. Comparison between two items
   Aのほうが Bより (property)
2. Comparison among three or more items
   (class of items)の中で Aが一番 (property)
3. adjective/noun + の
4. 〜つもりだ
5. adjective + なる
6. どこかに/どこにも
7. で

// Ch 11
1. 〜たい
2. 〜たり〜たりする
3. 〜ことがある
4. noun A や noun B

// Ch 12
1. 〜んです
2. 〜すぎる
3. 〜ほうがいいです
4. 〜ので
5. 〜なければいけません/〜なきゃいけません
6. 〜でしょう

// Ch 13
1. Potential verbs
2. 〜し
3. 〜そうです (It looks like...)
4. 〜てみる
5. なら
6. 一周間に三回

// Ch 14
1. ほしい
2. 〜かもしれません
3. あげる/くれる/もらう
4. 〜たらどうですか
5. number + も/number + しか + negative

// Ch 15
1. Volitional Form
2. Volitional Form + と思っています
3. 〜ておく
4. Using Sentences to Qualify Nouns

// Ch 16
1. てあげる/くれる/もらう
2. 〜ていただけませんか
3. 〜といい
4. 〜時
5. 〜てすみませんでした
`}
                        </pre>
                        </div>
                    </ScrollablePane>
                }
            />
        </React.Fragment>
    );
}