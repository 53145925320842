import React from "react";
import PropTypes from "prop-types";
import {isBreakpoint} from "../util/Breakpoint";
import {BREAKPOINTS} from "../Constants";
import {Navigation} from "./Navigation";
import AppLayoutBase from "./AppLayoutBase";

export default class AppLayout extends React.Component {
    static propTypes = {
        breakpoint: PropTypes.string,
        contentLeft: PropTypes.object,
        contentRight: PropTypes.object,
        footer: PropTypes.object,
        onDimensionsChange: PropTypes.func
    };

    render() {
        const {breakpoint, contentLeft, contentRight, footer, onDimensionsChange} = this.props;

        // TODO:andrewReview
        let bodyMargin;
        let elementMargin;
        if (isBreakpoint(BREAKPOINTS.SM_DOWN, breakpoint)) {
            bodyMargin = '0px';
            elementMargin = '-7px';
        } else {
            bodyMargin = '.75em';
            elementMargin = '-13px';
        }

        return (
            <AppLayoutBase
                bodyMargin={bodyMargin}
                header={<Navigation bodyMargin={bodyMargin} elementMargin={elementMargin}/>}
                contentLeft={contentLeft}
                contentRight={contentRight}
                footer={footer}
                onDimensionsChange={onDimensionsChange}
            />
        );
    }
}