import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import ScrollablePane from "../components/ScrollablePane";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import DevAppTab1Content from "./DevAppTab1Content";
import {kanaToRomaji, vocabToKana} from "./DevUtil";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function DevAppTab2_KanaToRomaji() {
    const [inputVal, setInputVal] = React.useState('xs=6 sm=3');
    const [outputVal, setOutputVal] = React.useState('');

    const classes = useStyles();

    const onTextAreaChange = (e) => {
        const val = e.target.value;
        setInputVal(val);

        // This parses vocab as \n delimited strings with format: 漢字[かんじ]\n
        // const parsed = vocabToKana(val);

        // This parses vocab as \n delimited strings with format: かんじ\n
        const parsed = kanaToRomaji(val);

        setOutputVal(parsed);
    }

    return(
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{height: "100%"}}
        >
            <Hidden xsDown>
            <Grid item xs={6} sm={3} style={{height: '100%', padding: '1em'}}>
                <Paper className={classes.paper} style={{height: '100%'}}>
                    <textarea
                        style={{width: '100%', height: '100%'}}
                        onChange={onTextAreaChange}
                        value={inputVal}
                    />
                </Paper>
            </Grid>
            </Hidden>

            <Grid item xs={12} sm={3} style={{backgroundColor: 'red', height: '80%', padding: '1em'}}>
                <Paper className={classes.paper} style={{height: '100%'}}>

                    <ScrollablePane
                        fixedHeader={'xs=12 sm=3'}
                        fixedFooter={'footer'}
                    >
                        <DevAppTab1Content/>
                    </ScrollablePane>
                </Paper>
            </Grid>

            <Hidden xsDown>
            <Grid item xs={6} sm={3} style={{height: '100%', padding: '1em'}}>
                <Paper className={classes.paper} style={{height: '100%'}}>
                    <textarea
                        readOnly={true}
                        style={{width: '100%', height: '100%'}}
                        value={outputVal}
                        placeholder={'xs=6 sm=3'}
                    />
                </Paper>
            </Grid>
            </Hidden>

        </Grid>
    );
}