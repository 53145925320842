import React from "react";
import {FullScreenControlledDialog} from "./FullScreenDialog";
import {getBadge} from "../japanese/components/VocabularyDetail";
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import {BREAKPOINTS, DUMMY_KEYCODE, KEYS} from "../Constants";
import {handleKeyPress} from "../japanese/util/Util";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import {isBreakpoint} from "../util/Breakpoint";

export default class VocabularyDetailDialog extends React.Component {
    static propTypes = {
        isOpen: PropTypes.bool,
        rows: PropTypes.array,
        selectedRow: PropTypes.object,
        selectedIndex: PropTypes.number,
        onSelection: PropTypes.func,
        onClose: PropTypes.func,
        lessonRenderer: PropTypes.func,
        breakpoint: PropTypes.string
    };

    static defaultProps = {
        isOpen: () => {console.log("isOpen");},
        rows: [],
        onClose: () => {console.log("onClose");},
        lessonRenderer: val => val
    };

    onBack = () => {
        const {rows, selectedIndex, onSelection} = this.props;
        const {newIndex, newRow} = handleKeyPress(DUMMY_KEYCODE.BACK, rows, selectedIndex);
        onSelection(newRow, newIndex);
    }

    onForward = () => {
        const {rows, selectedIndex, onSelection} = this.props;
        const {newIndex, newRow} = handleKeyPress(DUMMY_KEYCODE.FORWARD, rows, selectedIndex);
        onSelection(newRow, newIndex);
    }

    render () {
        const {isOpen, onClose, rows, selectedRow, selectedIndex, detailView, lessonRenderer, breakpoint} = this.props;
        return (
            <FullScreenControlledDialog
                isLeftTransition={true}
                modalTitle={<div style={{textAlign: 'center'}}>{getBadge(selectedRow)}</div>}
                leftPane={
                    <Button variant='contained' onClick={this.onBack}><KeyboardArrowLeftIcon/></Button>
                }
                rightPane={
                    <Button variant='contained' onClick={this.onForward}><KeyboardArrowRightIcon/></Button>
                }
                footer={
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center"
                    >
                        <Hidden smUp={true}>
                            <Grid item xs={2} style={{textAlign: 'left', paddingBottom: '.5em'}}>
                                <Button variant='contained' onClick={this.onBack}><KeyboardArrowLeftIcon/></Button>
                            </Grid>
                        </Hidden>
                        <Grid item xs={8} sm={12}>
                            <div style={{textAlign: 'center', paddingBottom: '.5em', color: '#8f8f8f'}}>
                                {(selectedRow && selectedRow[KEYS.LESSON]) ? <b>{lessonRenderer(selectedRow[KEYS.LESSON])}</b> : ''} &#9679; (<b>{selectedIndex + 1}</b>/<b>{rows.length}</b>)
                            </div>
                        </Grid>
                        <Hidden smUp={true}>
                            <Grid item xs={2} style={{textAlign: 'right', paddingBottom: '.5em'}}>
                                <Button variant='contained' onClick={this.onForward}><KeyboardArrowRightIcon/></Button>
                            </Grid>
                        </Hidden>
                    </Grid>
                }
                isOpen={isOpen}
                onClose={onClose}
                isCenteredContent={true}
                breakpoint={breakpoint}
            >
                {detailView}

            </FullScreenControlledDialog>
        );
    }
}