import React from "react";
import {FullScreenControlledDialog} from "./FullScreenDialog";
import {getBadge} from "../japanese/components/VocabularyDetail";
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import {KEYCODE, KEYS} from "../Constants";
import {getRandomIndex} from "../japanese/util/Util";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

export default class FlashcardDialog extends React.Component {
    static propTypes = {
        isOpen: PropTypes.bool,
        rows: PropTypes.array,
        selectedRow: PropTypes.object,
        selectedIndex: PropTypes.number,
        onSelection: PropTypes.func,
        onClose: PropTypes.func,
        lessonRenderer: PropTypes.func,
        keyPress: PropTypes.string,
        questionType: PropTypes.string,
        questionRenderer: PropTypes.func,
        answerRenderer: PropTypes.func,
        breakpoint: PropTypes.string
    };

    static defaultProps = {
        isOpen: () => {console.log("isOpen");},
        rows: [],
        onClose: () => {console.log("onClose");},
        lessonRenderer: val => val,
        questionRenderer: () => {},
        answerRenderer: () => {}
    };

    constructor(props) {
        super(props);
        this.activeRowIndices = [];
        this.repeatRowIndices = [];
        this.state = {
            showQuestion: true,
            numRemaining: 0,
            numTotal: 0
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedIndex, isOpen, keyPress, rows, onSelection, questionType} = this.props;
        const {numRemaining, showQuestion} = this.state;
        const newState = {};

        // Reset question state when the item changes.
        if (selectedIndex !== prevProps.selectedIndex) {
            newState.showQuestion = true;
        }

        // Handle modal opening. Reset values.
        if (isOpen && isOpen !== prevProps.isOpen) {

            this.activeRowIndices = [];
            for (let i=0; i<rows.length; i++) {
                if (rows[i][questionType]) {
                    this.activeRowIndices.push(i);
                }
            }
            newState.numTotal = this.activeRowIndices.length;
            newState.numRemaining = this.activeRowIndices.length;
            this.repeatRowIndices = [];
            newState.showQuestion = true;

            // Get a random first flashcard item.
            const nextActiveRowIndex = getRandomIndex(newState.numRemaining, selectedIndex);
            const nextRowIndex = this.activeRowIndices[nextActiveRowIndex];
            onSelection(rows[nextRowIndex], nextRowIndex);
        }

        // Handle key presses.
        if (keyPress && keyPress !== prevProps.keyPress) {
            const key = keyPress.split("_")[0];
            if (key === KEYCODE.DOWN && numRemaining > 0) {
                newState.showQuestion = false;
            } else if (!showQuestion && key === KEYCODE.LEFT) {
                this.onIncorrect();
            } else if (!showQuestion && key === KEYCODE.RIGHT) {
                this.onCorrect();
            }
        }

        if (Object.values(newState).length) {
            this.setState(newState);
        }
    }

    onIncorrect = (e) => {
        // Don't propagate this event to the onBodyClick handler.
        if (e) {
            e.stopPropagation();
        }
        this.onNext(false);
    }

    onCorrect = (e) => {
        // Don't propagate this event to the onBodyClick handler.
        if (e) {
            e.stopPropagation();
        }
        this.onNext(true);
    }

    onNext = (isSuccess) => {
        const {rows, selectedIndex, onSelection} = this.props;
        const numActiveRemaining = this.activeRowIndices.length;

        // Nothing left.
        if (numActiveRemaining === 0) {
            return;
        }

        // Only 1 left. If not success, hide answer again.
        if (numActiveRemaining === 1 && !isSuccess && this.repeatRowIndices.length === 0) {
            this.setState({
                showQuestion: true
            });
            return;
        }


        // Remove current active row index.
        let currActiveRowIndex = this.activeRowIndices.findIndex(val => val === selectedIndex);
        this.activeRowIndices.splice(currActiveRowIndex, 1);

        // Add failed item into the repeat row index.
        if (!isSuccess) {
            this.repeatRowIndices.push(selectedIndex);
        }

        // Switch indices when the active index is exhausted but items still remain in the other container.
        if (this.activeRowIndices.length === 0 && this.repeatRowIndices.length > 0) {
            // Switch indices.
            this.activeRowIndices = this.repeatRowIndices;
            this.repeatRowIndices = [];

            // Redo current row index.
            currActiveRowIndex = this.activeRowIndices.findIndex(val => val === selectedIndex);
        }

        // Get next value.
        const nextActiveRowIndex = getRandomIndex(this.activeRowIndices.length, currActiveRowIndex);
        const nextRowIndex = this.activeRowIndices[nextActiveRowIndex];

        this.setState({
            numRemaining: this.activeRowIndices.length + this.repeatRowIndices.length
        }, () => {
            onSelection(rows[nextRowIndex], nextRowIndex);
        });
    }

    showAnswer = () => {
        const { numRemaining } = this.state;
        if (numRemaining === 0) {
            return;
        }
        this.setState({
            showQuestion: false
        });
    }

    render () {
        const {isOpen, onClose, selectedRow, questionType, questionRenderer, answerRenderer, lessonRenderer, breakpoint} = this.props;
        const {showQuestion, numRemaining, numTotal} = this.state;

        let questionClassName = '';
        let answerClassName = '';
        let doneClassName = '';

        if (numRemaining === 0) {
            answerClassName = 'no-display';
            questionClassName = 'no-display';
        } else if (showQuestion) {
            answerClassName = 'no-display';
            doneClassName = 'no-display';
        } else {
            questionClassName = 'no-display';
            doneClassName = 'no-display';
        }

        return (
            <FullScreenControlledDialog
                onBodyClick={this.showAnswer}
                isLeftTransition={true}
                modalTitle={<div style={{textAlign: 'center'}} className={showQuestion ? 'no-visible' : ''}>{getBadge(selectedRow)}</div>}
                leftPane={
                    <div className={showQuestion ? 'no-visible' : ''}>
                        <Button variant='outlined' onClick={this.onIncorrect}><CancelIcon/></Button>
                    </div>
                }
                rightPane={
                    <div className={showQuestion ? 'no-visible' : ''}>
                        <Button variant='outlined' onClick={this.onCorrect}><CheckCircleIcon/></Button>
                    </div>
                }
                footer={
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center"
                    >
                            <Hidden smUp={true}>
                                <Grid item xs={2} style={{textAlign: 'left'}}>
                                    <div className={showQuestion ? 'no-visible' : ''} style={{paddingBottom: '.5em'}}>
                                        <Button variant='outlined' onClick={this.onIncorrect}><CancelIcon/></Button>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Grid item xs={8} sm={12}>
                                <div style={{textAlign: 'center', paddingBottom: '.5em', color: '#8f8f8f'}}>
                                    <span className={showQuestion ? 'no-display' : ''}>{(selectedRow && selectedRow[KEYS.LESSON]) ? lessonRenderer(selectedRow[KEYS.LESSON]) : ''} &#9679; </span>(<b>{numTotal - numRemaining}</b>/<b>{numTotal}</b>)
                                </div>
                            </Grid>
                            <Hidden smUp={true}>
                                <Grid item xs={2} style={{textAlign: 'right'}}>
                                    <div className={showQuestion ? 'no-visible' : ''} style={{paddingBottom: '.5em'}}>
                                        <Button variant='outlined' onClick={this.onCorrect}><CheckCircleIcon/></Button>
                                    </div>
                                </Grid>
                            </Hidden>
                    </Grid>
                }
                isOpen={isOpen}
                onClose={onClose}
                isCenteredContent={true}
                breakpoint={breakpoint}
            >
                <div className={questionClassName} onClick={this.showAnswer} style={{textAlign:'center'}}>
                    {selectedRow ? questionRenderer(selectedRow, questionType) : ''}
                </div>
                <div className={answerClassName} style={{textAlign:'center'}}>
                    {selectedRow ? answerRenderer(selectedRow, questionType) : ''}
                </div>
                <div className={doneClassName}>
                    <h2 style={{marginBottom:0, textAlign:'center'}}>
                        Success!
                    </h2>
                </div>
            </FullScreenControlledDialog>
        );
    }
}