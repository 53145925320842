import React from 'react';
import Container from "@material-ui/core/Container";
import {AppBar, Tab, Tabs} from "@material-ui/core";
import {ThemeProvider} from '@material-ui/core/styles';
import theme from './theme'
import CssBaseline from "@material-ui/core/CssBaseline";
import DevAppTab2_KanaToRomaji from "./dev/DevAppTab2_KanaToRomaji";
import DevAppTab1_KanaToRuby from "./dev/DevAppTab1_KanaToRuby";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index &&
                children
            }
        </div>
    );
}

export default function DevApp() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabStyle = {height: 'calc(100% - 70px)', marginTop: '16px', backgroundColor: 'yellow'};

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            {/* Height needs to work with mobile as well - subtract arbitrary amount for now. */}
            <Container maxWidth={'md'} style={{height: 'calc(100vh - 120px)'}}>

                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Kana To Ruby" {...a11yProps(0)} />
                        <Tab label="Kana to Romaji" {...a11yProps(1)} />
                        <Tab label="Item Three" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>

                {/* Apparently have to subtract AppBar height to get remainder of 100vh height */}
                <TabPanel value={value} index={0} style={tabStyle}>
                    <DevAppTab1_KanaToRuby />
                </TabPanel>

                <TabPanel value={value} index={1} style={tabStyle}>
                    <DevAppTab2_KanaToRomaji />
                </TabPanel>

                <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel>


            </Container>
        </ThemeProvider>
    );
}