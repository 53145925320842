import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {parseFlatRuby} from "../japanese/util/Util";
import {Furigana} from "../japanese/components/Ruby";

let debounceRef;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
}));

function parseInput(val){
    if (!val) {
        return "";
    }
    const lines = val.split('\n');
    return lines.map((val, idx) => {
        const { kanjiStr, kanaStr } = parseFlatRuby(val);
        return (<React.Fragment key={`dev_kana_to_ruby_${idx}`}>
            <div><Furigana kanjiStr={kanjiStr} kanaStr={kanaStr} ignoreWhitespace={true}/></div>
            {/*
            <div>{kanjiStr.replaceAll(" ","")}</div>
            <div>{kanaStr.replaceAll(" ","")}</div>
            <hr/>
            */}
        </React.Fragment>);
    });
}

export default function DevAppTab1_KanaToRuby() {
    const [outputVal, setOutputVal] = React.useState('');

    const classes = useStyles();

    const onTextAreaChange = (e) => {
        const val = e.target.value;

        window.clearTimeout(debounceRef);
        debounceRef = window.setTimeout(() => {
            // This parses vocab as \n delimited strings with format: 漢字[かんじ]\n
            // const parsed = vocabToKana(val);
            const parsed = parseInput(val);
            setOutputVal(parsed);
        }, 500);

    }

    return(
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{height: "100%"}}
        >

            <Grid item xs={12} style={{height: '50%', padding: '1em'}}>
                <Paper className={classes.paper} style={{height: '100%'}}>
                    <textarea
                        spellCheck={'false'}
                        autoComplete={'false'}
                        autoCorrect={'false'}
                        autoCapitalize={'false'}
                        autoFocus={false}
                        autoSave={'false'}
                        style={{width: '100%', height: '100%'}}
                        onChange={onTextAreaChange}
                    />
                </Paper>
            </Grid>

            <Grid item xs={12} style={{height: '50%', padding: '1em'}}>
                <Paper className={classes.paper} style={{height: '100%', fontSize: 'x-large'}}>
                    {outputVal}
                </Paper>
            </Grid>

        </Grid>
    );
}