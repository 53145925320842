import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#8f8f8f'
            // main: '#7e7e7e'
            // main: '#eaeaea'
            // main: '#007bff'
            // main: 'rgba(0,0,0,.03)'
            // main: '#1976d2'
            // main: '#ffffff'
        },
        secondary: {
            // main: '#1976d2'
            main: '#007bff'
            // main: '#dc004e'
            // main: 'rgb(204, 229, 255)'
            // main: 'rgba(0,0,0,.03)'
        },
        /*
        primary: {
            main: '#556cd6',
        },
        secondary: {
            main: '#19857b',
        },
        */
        error: {
            main: red.A400,
        },
        background: {
            default: '#fff',
        },
    },
    /*
    shadows: [
        "none", "none", "none", "none", "none",
        "none", "none", "none", "none", "none",
        "none", "none", "none", "none", "none",
        "none", "none", "none", "none", "none",
        "none", "none", "none", "none", "none"
    ]
    */
});

export default theme;


/*
        palette: {
            primary: {
                main: '#7e7e7e'
                // main: '#eaeaea'
                // main: '#007bff'
                // main: 'rgba(0,0,0,.03)'
                // main: '#1976d2'
                // main: '#ffffff'
            },
            secondary: {
                // main: '#1976d2'
                main: '#007bff'
                // main: '#dc004e'
                // main: 'rgb(204, 229, 255)'
                // main: 'rgba(0,0,0,.03)'
            }
        },
        shadows: [
            "none", "none", "none", "none", "none",
            "none", "none", "none", "none", "none",
            "none", "none", "none", "none", "none",
            "none", "none", "none", "none", "none",
            "none", "none", "none", "none", "none"
        ]
 */