export const LAYOUT_IDS = {
    KANJI: 'kanji',
    KANJI2: 'kanji2',
    NUMBERS: 'numbers',
    GENKI: 'genkiLayout',
    MARUGOTO_A2_1: 'marugotoA21',
    MARUGOTO_A2_2_KATSUDOU: 'marugotoA22Katsudou',
    SPEKTRUM_A1: 'spektrumA1'
};

export const KEYS = {
    ID: 'id',
    LESSON: '_lesson',
    AUDIO: '_audio',
    TAGS_LIST: '_tagsList',
    KANJI: '_kanji',
    KANJI_DEFINITION: '_kanjiDefinition',
    PART_OF_SPEECH: '_partOfSpeech',
    CATEGORY: '_category',
    SUB_CATEGORY: '_subCategory',
    MASU_TYPE: '_masuType',
    TE_TYPE: '_teType',
    PLAIN_TYPE: '_plainType',
    DEFINITION: '_definition',
    DEFINITION_KANA: '_definitionKana',
    SECONDARY_DEFINITION: '_secondaryDefinition',
    GRAMMAR_NOTES: '_grammar',

    // Vocabulary field and parsed data
    VOCABULARY: '_vocabulary',
    VOCABULARY_KANA: '_vocabularyKanaOnly',
    VOCABULARY_KANJI: '_vocabularyKanjiOnly',

    // Sentence field and parsed data
    SENTENCE: '_sentence',
    SENTENCE_KANA: 'sentenceKanaOnly',
    SENTENCE_KANJI: 'sentenceKanjiOnly',
    SENTENCE_DEFINITION: 'sentenceDefinition',

    // Search string
    SEARCH_STRING: '_searchString',

    // VERB_CONJUGATIONS object keys
    CONJUGATION_MAP: 'conjugationMap',
};

// Genki parts of speech
export const PARTS_OF_SPEECH_KEYS = {
    NOUN: 'n.',
    ADJ_I: 'い-adj.',
    ADJ_NA: 'な-adj.',
    VERB_U: 'u-v.',
    VERB_RU: 'ru-v.',
    VERB_IRR: 'irr-v.',
    ADVERB: 'adv.',
    EXPRESSION: 'exp.',
    PARTICLE: 'part.',
    PRE_NOMINAL: 'pre.',
    SUFFIX: 'suf.',
    UNCATEGORIZED: '-',
};

// Genki verbs
export const VERBS = [
    PARTS_OF_SPEECH_KEYS.VERB_U,
    PARTS_OF_SPEECH_KEYS.VERB_RU,
    PARTS_OF_SPEECH_KEYS.VERB_IRR
];

// Genki adjectives
export const ADJECTIVES = [
    PARTS_OF_SPEECH_KEYS.ADJ_I,
    PARTS_OF_SPEECH_KEYS.ADJ_NA
];

// Combined Genki verbs and adjectives
export const VERBS_AND_ADJECTIVES = [...VERBS, ...ADJECTIVES];

export const CONJUGATION_FORMS = {
    DICTIONARY: 'conjugationFormDictionary',
    STEM: 'conjugationFormStem',
    POTENTIAL_STEM: 'conjugationFormPotentialStem',
    MASU_PRESENT_POSITIVE: 'conjugationFormMasuPresentPositive',
    MASU_PRESENT_NEGATIVE: 'conjugationFormMasuPresentNegative',
    MASU_PAST_POSITIVE: 'conjugationFormMasuPastPositive',
    MASU_PAST_NEGATIVE: 'conjugationFormMasuPastNegative',
    TE: 'conjugationFormTe',
    TE_NEGATIVE: 'conjugationFormTeNegative',
    PLAIN_PRESENT_POSITIVE: 'conjugationFormNaiPresentPositive',
    PLAIN_PRESENT_NEGATIVE: 'conjugationFormNaiPresentNegative',
    PLAIN_PAST_POSITIVE: 'conjugationFormNaiPastPositive',
    PLAIN_PAST_NEGATIVE: 'conjugationFormNaiPastNegative',
    VOLITIONAL: 'conjugationFormVolitional'
};

export const KANA_TYPES = {
    KANJI: 'kanaTypesKanji',
    KANA: 'kanaTypesKana',
    RUBY: 'kanaTypesRuby',
};

// Display labels - may override spreadsheet labels
export const LABELS = {};
LABELS[KEYS.VOCABULARY] = "Vocabulary";
LABELS[KEYS.VOCABULARY_KANJI] = "漢字";
LABELS[KEYS.VOCABULARY_KANA] = "かな";
LABELS[KEYS.PART_OF_SPEECH] = "POS";
LABELS[KEYS.LESSON] = "Lesson";
LABELS[KEYS.TE_TYPE] = "て形";
LABELS[KEYS.MASU_TYPE] = "ます形";
LABELS[KEYS.PLAIN_TYPE] = "普通形"
LABELS[KANA_TYPES.KANA] = "仮名";
LABELS[KANA_TYPES.KANJI] = "漢字";
LABELS[KANA_TYPES.RUBY] = "振り仮名";
LABELS[KEYS.DEFINITION] = "Definition";
LABELS[KEYS.SENTENCE] = "Sentence (JP)";
LABELS[KEYS.SENTENCE_DEFINITION] = "Sentence (EN)";
LABELS[CONJUGATION_FORMS.MASU_PRESENT_POSITIVE] = "ます";
LABELS[CONJUGATION_FORMS.MASU_PRESENT_NEGATIVE] = "ます negative";
LABELS[CONJUGATION_FORMS.MASU_PAST_POSITIVE] = "ます past";
LABELS[CONJUGATION_FORMS.MASU_PAST_NEGATIVE] = "ます past negative";
LABELS[CONJUGATION_FORMS.TE] = "～て";
LABELS[CONJUGATION_FORMS.PLAIN_PRESENT_POSITIVE] = "plain";
LABELS[CONJUGATION_FORMS.PLAIN_PRESENT_NEGATIVE] = "plain negative";
LABELS[CONJUGATION_FORMS.PLAIN_PAST_POSITIVE] = "plain past";
LABELS[CONJUGATION_FORMS.PLAIN_PAST_NEGATIVE] = "plain past negative";
LABELS[PARTS_OF_SPEECH_KEYS.NOUN] = "Noun";
LABELS[PARTS_OF_SPEECH_KEYS.ADVERB] = "Adverbial expression";
LABELS[PARTS_OF_SPEECH_KEYS.EXPRESSION] = "Expression";
LABELS[PARTS_OF_SPEECH_KEYS.PARTICLE] = "Particle";
LABELS[PARTS_OF_SPEECH_KEYS.PRE_NOMINAL] = "Pre-nominal expression";
LABELS[PARTS_OF_SPEECH_KEYS.SUFFIX] = "Noun-forming suffix";

export const SPEKTRUM_CATEGORIES = {
    WORDS: '_words',
    LIST: '_list',
    NOUNS: '_nouns',
    VERBS: '_verbs',
    VERBS_PERFEKT: '_verbsPerfekt',
    OTHER: '_other',
    NUMBERS_BASIC: '_numbersBasic',
    NUMBERS_TEENS: '_numbersTeens',
    NUMBERS_TENS_BASIC: '_numbersTensBasic',
    NUMBERS_TENS_COMPOUND: '_numbersTensCompound',
    NUMBERS_100_PLUS_BASIC: '_numbers100PlusBasic',
    NUMBERS_100_PLUS_COMPOUND: '_numbers100PlusCompound',

};

export const SPEKTRUM_TAGS = {
    QUESTION_WORDS: '_questionWords'
}

export const SPEKTRUM_KEYS = {
    ARTICLE: '_article',
    SINGULAR: '_singular',
    PLURAL: '_plural',
    VERBEN_PRAEFIX: '_verben_praefix',
    VERBEN_PERFEKT: '_verben_perfekt',
    VERBEN_PERFEKT_HABEN_SEIN: '_verben_perfekt_haben_sein',
    ICH: '_v_ich',
    DU: '_v_du',
    ER_SIE: '_v_er_sie',
    WIR: 'v_wir',
    IHR: 'v_ihr',
    SIE_PLURAL: 'v_sie_plural',
    SIE_FORMELL: 'v_sie_formell'
}

export const KEYCODE = {
    UP: 'ArrowUp',
    DOWN: 'ArrowDown',
    LEFT: 'ArrowLeft',
    RIGHT: 'ArrowRight',
};

export const DUMMY_KEYCODE = {
    FORWARD: `${KEYCODE.DOWN}_12345`,
    BACK: `${KEYCODE.UP}_12345`
}

export const SORT_DIRECTION = {
    NONE: 'none',
    ASC: 'asc',
    DESC: 'desc'
};

export const BREAKPOINTS = {
    XS_DOWN: 'xsDown',
    SM_DOWN: 'smDown',
    MD_DOWN: 'mdDown',
    LG_DOWN: 'lgDown'
};