import {CONJUGATION_FORMS} from "../../Constants";
import {stripWhitespace} from "../util/Util";

export const ADJECTIVE_TYPES = {
    I_VERB: 'い',
    NA_VERB: 'な',
    IRREGULAR: 'Irregular'
};

export function conjugateAdjective(adjValue, masuType) {
    let dictionary;
    let stem;
    let adjPresentPositive;
    let adjPresentNegative;
    let adjPastPositive;
    let adjPastNegative;
    let adjTeForm;
    let adjTeFormNegative;
    let adjPlainPresentPositive;
    let adjPlainPresentNegative;
    let adjPlainPastPositive;
    let adjPlainPastNegative;

    switch(masuType) {
        case ADJECTIVE_TYPES.I_VERB: {
            dictionary = adjValue;
            stem = adjValue.substring(0, adjValue.length-1);

            // plain-form
            adjPlainPresentPositive = adjValue;
            adjPlainPresentNegative = `${stem}くない`;
            adjPlainPastPositive = `${stem}かった`;
            adjPlainPastNegative = `${stem}くなかった`;

            // masu-form
            adjPresentPositive = `${adjPlainPresentPositive}です`;
            adjPresentNegative = `${adjPlainPresentNegative}です`;
            adjPastPositive = `${adjPlainPastPositive}です`;
            adjPastNegative = `${adjPlainPastNegative}です`;

            // te-form
            adjTeForm = `${stem}くて`;
            adjTeFormNegative = `${stem}くなくて`;
            break;
        }
        case ADJECTIVE_TYPES.NA_VERB: {
            stem = adjValue.substring(0, adjValue.length-3);
            dictionary = stem;

            // plain-form
            adjPlainPresentPositive = `${stem}(だ)`;
            adjPlainPresentNegative = `${stem}じゃない`;
            adjPlainPastPositive = `${stem}だった`;
            adjPlainPastNegative = `${stem}じゃながった`;

            // masu-form
            adjPresentPositive = `${stem}です`;
            adjPresentNegative = `${adjPlainPresentNegative}です`;
            adjPastPositive = `${stem}でした`;
            adjPastNegative = `${adjPlainPastNegative}です`;

            // te-form
            adjTeForm = `${stem}で`;
            adjTeFormNegative = `${stem}じゃなくて`;
            break;
        }
        case ADJECTIVE_TYPES.IRREGULAR: {
            dictionary = adjValue;
            stem = 'よ';
            const irregularStem = adjValue.substring(0, adjValue.length-2);

            // plain-form
            adjPlainPresentPositive = adjValue;
            adjPlainPresentNegative = `${irregularStem}よくない`;
            adjPlainPastPositive = `${irregularStem}よかった`;
            adjPlainPastNegative = `${irregularStem}よくなかった`;

            // masu-form
            adjPresentPositive = `${adjPlainPresentPositive}です`;
            adjPresentNegative = `${adjPlainPresentNegative}です`;
            adjPastPositive = `${adjPlainPastPositive}です`;
            adjPastNegative = `${adjPlainPastNegative}です`;

            // te-form
            adjTeForm = `${irregularStem}よくて`;
            adjTeFormNegative = `${irregularStem}よくなくて`;
            break;
        }
        default:
            // NOOP
    }

    const retval = {};
    retval[CONJUGATION_FORMS.DICTIONARY] = dictionary;
    retval[CONJUGATION_FORMS.STEM] = stem;
    retval[CONJUGATION_FORMS.MASU_PRESENT_POSITIVE] = stripWhitespace(adjPresentPositive);
    retval[CONJUGATION_FORMS.MASU_PRESENT_NEGATIVE] = stripWhitespace(adjPresentNegative);
    retval[CONJUGATION_FORMS.MASU_PAST_POSITIVE] = stripWhitespace(adjPastPositive);
    retval[CONJUGATION_FORMS.MASU_PAST_NEGATIVE] = stripWhitespace(adjPastNegative);
    retval[CONJUGATION_FORMS.TE] = stripWhitespace(adjTeForm);
    retval[CONJUGATION_FORMS.TE_NEGATIVE] = stripWhitespace(adjTeFormNegative);
    retval[CONJUGATION_FORMS.PLAIN_PRESENT_POSITIVE] = stripWhitespace(adjPlainPresentPositive);
    retval[CONJUGATION_FORMS.PLAIN_PRESENT_NEGATIVE] = stripWhitespace(adjPlainPresentNegative);
    retval[CONJUGATION_FORMS.PLAIN_PAST_POSITIVE] = stripWhitespace(adjPlainPastPositive);
    retval[CONJUGATION_FORMS.PLAIN_PAST_NEGATIVE] = stripWhitespace(adjPlainPastNegative);
    return retval;
}