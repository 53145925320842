import {KEYS, SPEKTRUM_CATEGORIES, SPEKTRUM_KEYS, SPEKTRUM_TAGS} from '../Constants';
import {parseFile} from '../util/Fetch';

export function parseSpektrumA1Words(data) {
    return parseFile(data, (fields, idx) => {
        const row = {};
        row[KEYS.ID] = `${SPEKTRUM_CATEGORIES.WORDS}_${idx}`;
        row[KEYS.LESSON] = fields[0];
        row[KEYS.AUDIO] = fields[1]
        row[KEYS.VOCABULARY] = fields[2];
        row[KEYS.DEFINITION] = fields[3];
        row[KEYS.SUB_CATEGORY] = `${fields[4]} / ${fields[5]}`;
        row[KEYS.SEARCH_STRING] = fields[8];
        row[KEYS.CATEGORY] = SPEKTRUM_CATEGORIES.WORDS;

        const tags = [SPEKTRUM_CATEGORIES.WORDS];
        if (fields[9]) {
            tags.push(SPEKTRUM_TAGS.QUESTION_WORDS);
        }
        row[KEYS.TAGS_LIST] = tags;

        return row;
    });
}

export function parseSpektrumA1Nouns(data) {
    return parseFile(data, (fields, idx) => {
        const row = {};
        row[KEYS.ID] = `${SPEKTRUM_CATEGORIES.NOUNS}_${idx}`;
        row[KEYS.LESSON] = fields[0];
        row[SPEKTRUM_KEYS.ARTICLE] = fields[1];
        row[SPEKTRUM_KEYS.SINGULAR] = fields[2];
        row[SPEKTRUM_KEYS.PLURAL] = fields[3];
        row[KEYS.VOCABULARY] = `${fields[1]} ${fields[2]}`;
        row[KEYS.DEFINITION] = fields[4];
        row[KEYS.SEARCH_STRING] = fields[5];
        row[KEYS.CATEGORY] = SPEKTRUM_CATEGORIES.NOUNS;

        row[KEYS.TAGS_LIST] = [SPEKTRUM_CATEGORIES.NOUNS];
        return row;
    });
}

export function parseSpektrumA1Verbs(data) {
    let lesson;
    return parseFile(data, (fields, idx) => {
        if (fields[0]) {
            lesson = fields[0];
        }
        const verb = fields[1];
        const row = {};
        row[KEYS.ID] = `${SPEKTRUM_CATEGORIES.VERBS}_${idx}`;
        row[KEYS.LESSON] = lesson;
        row[KEYS.VOCABULARY] = verb;
        row[KEYS.DEFINITION] = fields[2];
        row[KEYS.SENTENCE] = fields[11];
        row[KEYS.SENTENCE_DEFINITION] = fields[12];
        row[KEYS.CATEGORY] = SPEKTRUM_CATEGORIES.VERBS;
        row[KEYS.TAGS_LIST] = [SPEKTRUM_CATEGORIES.VERBS];

        let verbStem;
        if (verb.endsWith("en")) {
            verbStem = verb.slice(0, verb.length-2);
        } else {
            verbStem = verb.slice(0, verb.length-1);
        }

        let verbPrefix = fields[3] ? fields[3] : '';
        if (verbPrefix) {
            verbStem = verbStem.slice(verbPrefix.length);
        }

        row[SPEKTRUM_KEYS.VERBEN_PRAEFIX] = verbPrefix;
        row[SPEKTRUM_KEYS.ICH] = fields[4] ? `${fields[4]}*` : `${verbStem}e`;
        row[SPEKTRUM_KEYS.DU] = fields[5] ? `${fields[5]}*` : `${verbStem}st`;
        row[SPEKTRUM_KEYS.ER_SIE] = fields[6] ? `${fields[6]}*` : `${verbStem}t`;
        row[SPEKTRUM_KEYS.WIR] = fields[7] ? `${fields[7]}*` : `${verbStem}en`;
        row[SPEKTRUM_KEYS.IHR] = fields[8] ? `${fields[8]}*` : `${verbStem}t`;
        row[SPEKTRUM_KEYS.SIE_PLURAL] = fields[9] ? `${fields[9]}*` : `${verbStem}en`;
        row[SPEKTRUM_KEYS.SIE_FORMELL] = fields[10] ? `${fields[10]}*` : `${verbStem}en`;

        // Search string in spreadsheet plus all conjugations
        row[KEYS.SEARCH_STRING] = `${fields[13]} ${row[SPEKTRUM_KEYS.ICH]} ${row[SPEKTRUM_KEYS.DU]} ` +
            `${row[SPEKTRUM_KEYS.ER_SIE]} ${row[SPEKTRUM_KEYS.WIR]} ${row[SPEKTRUM_KEYS.IHR]} ` +
            `${row[SPEKTRUM_KEYS.SIE_PLURAL]} ${row[SPEKTRUM_KEYS.SIE_FORMELL]}`;

        return row;
    });
}

export function parseSpektrumA1VerbsPerfekt(data) {
    let lesson;
    return parseFile(data, (fields, idx) => {
        if (fields[0]) {
            lesson = fields[0];
        }

        const verbPresent = fields[1];
        const verbPerfektSeinHaben = fields[2];
        const verbPerfekt = fields[3];
        const definition = fields[4];
        const sentenceDe = fields[5];
        const sentenceEn = fields[6];

        const row = {};
        row[KEYS.ID] = `${SPEKTRUM_CATEGORIES.VERBS_PERFEKT}_${idx}`;
        row[KEYS.LESSON] = lesson;
        row[KEYS.VOCABULARY] = verbPresent;
        row[SPEKTRUM_KEYS.VERBEN_PERFEKT] = verbPerfekt;
        row[SPEKTRUM_KEYS.VERBEN_PERFEKT_HABEN_SEIN] = verbPerfektSeinHaben;
        row[KEYS.DEFINITION] = definition;
        row[KEYS.SENTENCE] = sentenceDe;
        row[KEYS.SENTENCE_DEFINITION] = sentenceEn;
        row[KEYS.CATEGORY] = SPEKTRUM_CATEGORIES.VERBS_PERFEKT;
        row[KEYS.TAGS_LIST] = [SPEKTRUM_CATEGORIES.VERBS_PERFEKT];

        // Search string
        row[KEYS.SEARCH_STRING] = `${verbPresent} ${verbPerfekt} ${definition}`

        return row;
    });
}

export function parseSpektrumA1Other(data) {
    return parseFile(data, (fields, idx) => {
        const row = {};
        row[KEYS.ID] = `${SPEKTRUM_CATEGORIES.OTHER}_${idx}`;
        row[KEYS.LESSON] = fields[0];
        row[KEYS.VOCABULARY] = fields[1];
        row[KEYS.DEFINITION] = fields[2];
        row[KEYS.PART_OF_SPEECH] = fields[3]
        row[KEYS.SENTENCE] = fields[4];
        row[KEYS.SENTENCE_DEFINITION] = fields[5];
        row[KEYS.SEARCH_STRING] = fields[6];
        row[KEYS.CATEGORY] = SPEKTRUM_CATEGORIES.OTHER;
        row[KEYS.TAGS_LIST] = [SPEKTRUM_CATEGORIES.OTHER];

        return row;
    });
}

export function parseGermanNumbers(data) {
    const generatedData = [];
    const parsedData = parseFile(data, (fields, idx) => {
        const row = {};

        const id = `${SPEKTRUM_CATEGORIES.NUMBERS_BASIC}_${idx}`;
        const lesson = '2';
        const numeral = fields[0];
        const numberInWords = fields[1];

        row[KEYS.ID] = id;
        row[KEYS.LESSON] = lesson
        row[KEYS.DEFINITION] = numeral;
        row[KEYS.VOCABULARY] = numberInWords;
        row[KEYS.SEARCH_STRING] = numberInWords;

        /* TODO
    NUMBERS_100_PLUS_COMPOUND: '_numbers100PlusCompound',
         */

        let numberCategory = fields[2];
        switch(numberCategory) {
            case 'Basic': {
                numberCategory = SPEKTRUM_CATEGORIES.NUMBERS_BASIC;
                break;
            }
            case 'Teens': {
                numberCategory = SPEKTRUM_CATEGORIES.NUMBERS_TEENS;
                break;
            }
            case 'Tens': {
                numberCategory = SPEKTRUM_CATEGORIES.NUMBERS_TENS_BASIC;

                // Generate X1 - X9 double-digit numbers.
                let number = parseInt(numeral, 10);
                ['einund','zweiund','dreiund','vierund','fünfund','sechsund','siebenund','achtund','neunund']
                    .forEach((val,idx) => {
                        const generatedRow = {};
                        generatedRow[KEYS.ID] = `${id}_${idx + 1}`;
                        generatedRow[KEYS.LESSON] = lesson;
                        generatedRow[KEYS.DEFINITION] = String(++number);
                        const vocabulary = `${val}${numberInWords}`;
                        generatedRow[KEYS.VOCABULARY] = vocabulary;
                        generatedRow[KEYS.SEARCH_STRING] = vocabulary;
                        generatedRow[KEYS.TAGS_LIST] = [SPEKTRUM_CATEGORIES.NUMBERS_TENS_COMPOUND];
                        generatedData.push(generatedRow);
                });
                break;
            }
            case '100+': {
                numberCategory = SPEKTRUM_CATEGORIES.NUMBERS_100_PLUS_BASIC;
                break;
            }
            default: {
                console.error("Unknown numberCategory:", numberCategory);
            }
        }

        row[KEYS.TAGS_LIST] = [numberCategory];
        return row;
    });

    return [...parsedData, ...generatedData];
}

export function parseSpektrumList(data) {
    let lesson;
    return parseFile(data, (fields, idx) => {
        const row = {};
        row[KEYS.ID] = `${SPEKTRUM_CATEGORIES.LIST}_${idx}`;
        if (fields[0]) {
            lesson = fields[0];
        }
        row[KEYS.LESSON] = lesson;
        row[KEYS.VOCABULARY] = fields[1];
        row[KEYS.DEFINITION] = fields[2];
        row[KEYS.SEARCH_STRING] = `${fields[1]} ${fields[2]}`;
        row[KEYS.CATEGORY] = SPEKTRUM_CATEGORIES.LIST;
        row[KEYS.TAGS_LIST] = [SPEKTRUM_CATEGORIES.LIST];

        return row;
    });
}