import React from "react";

export default function ScrollablePane({fixedHeader, fixedFooter, children, onBodyClick}) {
    const headerComponent = fixedHeader ? fixedHeader : null;
    const footerComponent = fixedFooter ? fixedFooter : null;
    const onClick = onBodyClick ? onBodyClick : () => {};
    return (
        <div className='flexContainer'>
            {headerComponent}
            <div className='flexFill' onClick={onClick}>
                <div className='scrollableContainer'>
                    <div className='scrollableContent'>
                        {children}
                    </div>
                </div>
            </div>
            {footerComponent}
        </div>
    );
}