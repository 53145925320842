import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Container from "@material-ui/core/Container";
import ScrollablePane from "./ScrollablePane";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import {isBreakpoint} from "../util/Breakpoint";
import {BREAKPOINTS} from "../Constants";


const TransitionUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const TransitionLeft = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export default function FullScreenDialog({launcherTitle, renderLauncher, modalTitle, children}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {
                renderLauncher ? renderLauncher(handleClickOpen) : (
                    <Button onClick={handleClickOpen}>
                        {launcherTitle ? launcherTitle : 'launcherTitle'}
                    </Button>
                )
            }
            <FullScreenControlledDialog
                modalTitle={modalTitle}
                launcherTitle={launcherTitle}
                children={children}
                isOpen={open}
                onClose={handleClose}
            />
        </div>
    );
}

function ScrollablePaneBody({onBodyClick, handleClose, modalTitle, launcherTitle, footer, children}) {
    return (
        <ScrollablePane
            onBodyClick={onBodyClick}
            fixedHeader={
                <Container maxWidth={'xl'} disableGutters={true}>
                    <AppBar position="static" color="secondary">
                        <Toolbar variant="dense">
                            <Typography variant="h6" style={{flex: 1}}>
                                {modalTitle ? modalTitle : launcherTitle}
                            </Typography>
                            <IconButton
                                autoFocus
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Container>
            }
            fixedFooter={
                footer ?
                    <Container maxWidth={'xl'} disableGutters={true}>
                        {footer}
                    </Container> : null
            }
        >
            <Container maxWidth={'xl'} disableGutters={true}>
                {children}
            </Container>
        </ScrollablePane>
    );
}

function CenteredScrollablePane({onBodyClick, handleClose, modalTitle, launcherTitle, leftPane, rightPane, footer,
                                    children, bodyHeight, breakpoint}) {
    // Toggle debug.
    const isDebug = false;

    // TODO: andrewReview - centered or not?
    // Override centered content if the the window is extra small.
    // const contentAlignItems = isBreakpoint(BREAKPOINTS.XS_DOWN, breakpoint) ? "" : "center";
    const contentAlignItems = "center";

    return (
        <div style={{height: '100%', display:'flex', flexDirection: 'column', backgroundColor: isDebug ? 'purple': 'inherit'}}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{height: "100%", backgroundColor: isDebug ? 'gray' : 'inherit'}}
                onClick={onBodyClick}
            >

                {/* Left pane */}
                <Hidden xsDown={true}>
                    <Grid item sm={2} lg={3}>
                        <div style={{textAlign: 'right', paddingRight: '2em'}}>
                            {leftPane}
                        </div>
                    </Grid>
                </Hidden>

                {/* Center pane */}
                <Grid item xs={12} sm={8} lg={6}
                      style={{height: '100%', maxHeight: '750px', maxWidth: '525px', padding: '1em', backgroundColor: isDebug ? 'yellow' : 'inherit'}}
                      id='centeredFullScreenDialogContainer'
                >
                    <Paper style={{height: '100%'}}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="flex-start"
                            style={{height: "100%"}}
                        >

                            <div style={{display:'flex', flexDirection: 'column', height: '100%', width: '100%', backgroundColor: isDebug ? 'green': 'inherit'}}>

                                <Grid container
                                      direction="row"
                                      justify="center"
                                      alignItems="center"
                                      id='centeredFullScreenDialogHeader'
                                >
                                    <Grid item xs={2}>
                                        &nbsp;
                                    </Grid>
                                    <Grid item xs={8} style={{textAlign: 'center'}}>
                                        <Typography variant="h6">
                                            {modalTitle ? modalTitle : launcherTitle}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} style={{textAlign: 'right', paddingRight: '1em'}}>
                                        <IconButton
                                            color="inherit"
                                            onClick={handleClose}
                                            aria-label="close"
                                            style={{paddingRight: 0, color: '#8f8f8f'}}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                <div id='centeredFullScreenDialogBody' style={{flex: 1, backgroundColor: isDebug ? 'red' : 'inherit'}} onClick={onBodyClick}>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems={contentAlignItems}
                                        style={{height: "100%"}}
                                    >
                                        {/* Calculate height*/}
                                        <Grid item xs={12} style={{backgroundColor: isDebug ? 'orange' : 'inherit', padding: '1em', overflowY: 'auto', maxHeight: bodyHeight}}>
                                            {children}
                                        </Grid>

                                    </Grid>
                                </div>
                                <div id='centeredFullScreenDialogFooter'>
                                    {footer}
                                </div>
                            </div>
                        </Grid>
                    </Paper>
                </Grid>

                {/* Right pane*/}
                <Hidden xsDown={true}>
                    <Grid item sm={2} lg={3}>
                        <div style={{textAlign: 'left', paddingLeft: '2em'}}>
                            {rightPane}
                        </div>
                    </Grid>
                </Hidden>

            </Grid>
        </div>
    );
}

export class FullScreenControlledDialog extends React.Component {
    static propTypes = {
        modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        launcherTitle: PropTypes.string,
        leftPane: PropTypes.any,
        rightPane: PropTypes.any,
        footer: PropTypes.any,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        onBodyClick: PropTypes.func,
        isLeftTransition: PropTypes.bool,
        isCenteredContent: PropTypes.bool,
        breakpoint: PropTypes.string
    };

    static defaultProps = {
        onBodyClick: () => {},
        isCenteredContent: false
    }

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            maxCenteredBodyHeight: '0px'
        }
        this.setTimeoutRef = null;
    }

    componentDidMount() {
        const {isOpen} = this.props;
        if (isOpen === true) {
            this.setState({open: true});
        }
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isOpen} = this.props;
        const {open} = this.state;

        // Update the state if all three conditions are met:
        // 1) Prop value is true or false.
        // 2) Prop value is different than previous prop value.
        // 3) Prop value is different than state value.
        if ((isOpen === false || isOpen === true) &&
            (isOpen !== prevProps.isOpen) &&
            (isOpen !== open)) {
            this.setState({open: isOpen}, this.updateDimensions);
        }
    }

    updateDimensions = () => {
        window.clearTimeout(this.setTimeoutRef);
        this.setTimeoutRef = setTimeout(() => {

            const centeredContainer = document.getElementById('centeredFullScreenDialogContainer');
            const centeredContainerHeight = centeredContainer ? centeredContainer.offsetHeight : 0;

            const centeredHeader = document.getElementById('centeredFullScreenDialogHeader');
            const centeredHeaderHeight = centeredHeader ? centeredHeader.offsetHeight : 0;

            const centeredFooter = document.getElementById('centeredFullScreenDialogFooter');
            const centeredFooterHeight = centeredFooter ? centeredFooter.offsetHeight : 0;

            // Approximate container padding for 2 x 1em.
            const containerPadding = 32;
            const maxCenteredBodyHeight = centeredContainerHeight - containerPadding - centeredHeaderHeight - centeredFooterHeight;

            this.setState({
                maxCenteredBodyHeight: maxCenteredBodyHeight && maxCenteredBodyHeight > 0 ? `${maxCenteredBodyHeight}px` : '0px'
            });
        }, 100);
    }

    /**
     * Save in state and call lifting function.
     */
    handleClose = () => {
        const {onClose} = this.props;
        this.setState({open: false}, onClose);
    };

    render() {
        const {modalTitle, launcherTitle, children, leftPane, rightPane, footer, isLeftTransition, onBodyClick,
            isCenteredContent, breakpoint} = this.props;
        const {open, maxCenteredBodyHeight} = this.state;

        let transitionComponent = TransitionUp;
        if (isLeftTransition) {
            transitionComponent = TransitionLeft;
        }

        return (
            <Dialog fullScreen={true} open={open} onClose={this.handleClose} TransitionComponent={transitionComponent}>
                {isCenteredContent ?
                    (
                        <CenteredScrollablePane
                            onBodyClick={onBodyClick }
                            handleClose={this.handleClose}
                            modalTitle={modalTitle}
                            launcherTitle={launcherTitle}
                            leftPane={leftPane}
                            rightPane={rightPane}
                            footer={footer}
                            bodyHeight={maxCenteredBodyHeight}
                            breakpoint={breakpoint}
                        >
                            {children}
                        </CenteredScrollablePane>
                    ) :
                    (
                        <ScrollablePaneBody
                            onBodyClick={onBodyClick }
                            handleClose={this.handleClose}
                            modalTitle={modalTitle}
                            launcherTitle={launcherTitle}
                            footer={footer}
                        >
                            {children}
                        </ScrollablePaneBody>
                    )
                }
            </Dialog>
        );
    }
}