import {ADJECTIVES, KEYS, PARTS_OF_SPEECH_KEYS, VERBS, VERBS_AND_ADJECTIVES} from '../../Constants';
import {conjugateNoun} from "../conjugator/NounConjugator";
import {conjugateVerb} from "../conjugator/VerbConjugator";
import {getAppConjugationTypes, getConjugationMap} from "../util/DeserializerUtil";
import {ADJECTIVE_TYPES, conjugateAdjective} from "../conjugator/AdjectiveConjugator";
import {parseFlatRuby} from "../util/Util";

const PART_OF_SPEECH_LOOKUP_ORDER = Object.values(PARTS_OF_SPEECH_KEYS).reduce((acc, key, idx) => {
    acc[key] = idx;
    return acc;
}, {});

export const RAW_ORDER = '_rawOrder';
export const RAW_KANA_KEY = '_rawKanaKey';
export const RAW_KANJI_KEY = '_rawKanjiKey';
export const RAW_LESSON_KEY = '_rawLessonKey';

// fetch data promise and result
let fetchDataPromise;
let fetchDataResult;

export function fetchData() {
    // Promise doesn't exist yet. Fetch data.
    if (!fetchDataPromise) {
        fetchDataPromise = new Promise((resolve, reject) => {
            fetch('/data-genki.txt')
                .then(response => response.text())
                .then(data => {
                    fetchDataResult = parseGenkiTsv(data);
                    resolve(fetchDataResult);
                });
        });
        return fetchDataPromise;
    }

    // Promise exists, but hasn't resolved yet. Return existing promise.
    if (!fetchDataResult) {
        console.log("GenkiDeserializer.js - return unresolved promise");
        return fetchDataPromise;
    }

    // Data is already fetched. Return promise and resolve.
    return new Promise((resolve, reject) => {
        console.log("GenkiDeserializer.js - return fetched data.");
        resolve(fetchDataResult);
    });
}

/**
 * Parse verb TSV data.
 *
 * @param data
 * @returns {{data: *[], lessons: {}}}
 */
function parseGenkiTsv(data) {
    console.log("GenkiDeserializer.js - parseGenkiTsv");
    const lessons = new Set();
    const fileLines = data.split('\n');
    const rawData = fileLines.reduce((acc, val, idx) => {
        // Ignore label row.
        if (idx === 0) {
            return acc;
        }

        const fields = val.split('\t');
        const row = {};
        let kanaField = fields[4];
        let kanjiField = fields[5];
        const partOfSpeech = fields[9];

        // TODO:andrewReview -not needed anymore
        // Revise na-adjectives
        /*
        if (partOfSpeech === PARTS_OF_SPEECH_KEYS.ADJ_NA) {
            if (kanaField.endsWith('（な）') || kanjiField.endsWith('（な）')) {
                const kanaEnding = kanaField.indexOf('（な）');
                if (kanaEnding > 0) {
                    console.log("GenkiDeserializer.js - removing（な）kana ending -", kanaField);
                    kanaField = kanaField.slice(0, kanaEnding);
                }

                const kanjiEnding = kanjiField.indexOf('（な）');
                if (kanjiEnding > 0) {
                    console.log("GenkiDeserializer.js - removing（な）kanji ending -", kanjiField);
                    kanjiField = kanjiField.slice(0, kanjiEnding);
                }
            } else if (kanaField.endsWith('な') && kanjiField.endsWith('な')) {
                    console.log("GenkiDeserializer.js - removing な kana ending -", kanaField);
                    kanaField = kanaField.slice(0, kanaField.length-1);

                    console.log("GenkiDeserializer.js - removing な kanji ending -", kanjiField);
                    kanjiField = kanjiField.slice(0, kanjiField.length-1);
            }
        }
        */

        const rawLesson = fields[3];

        // Fields directly from data.
        row[KEYS.PART_OF_SPEECH] = partOfSpeech;
        row[KEYS.DEFINITION] = fields[10];

        // Raw fields for TSV output
        row[RAW_ORDER] = String(idx);
        row[RAW_KANA_KEY] = kanaField;
        row[RAW_KANJI_KEY] = kanjiField;
        row[RAW_LESSON_KEY] = rawLesson;

        /*
        // Auto-generate vocabulary as "flat ruby" format
        row[KEYS.VOCABULARY] = kanjiField ? `${kanjiField}[${kanaField}]` : kanaField;
        row[KEYS.VOCABULARY_KANA] = kanaField;
        row[KEYS.VOCABULARY_KANJI] = kanjiField ? kanjiField : kanaField;
         */
        row[KEYS.VOCABULARY] = fields[6];
        const {kanaStr: vocabKana, kanjiStr: vocabKanji} = parseFlatRuby(row[KEYS.VOCABULARY]);
        row[KEYS.VOCABULARY_KANA] = vocabKana;
        row[KEYS.VOCABULARY_KANJI] = vocabKanji;

        // Extract tags from the raw lesson string.
        const tags = Array.from(
            // rawLesson.matchAll(/[会|読][L|G][0-9]*/g),
            rawLesson.matchAll(/[会|読][L|G][0-9]*[-I*|(e)]*/g),
            res => `'${res[0]}'`
        ).sort((a, b) => a.localeCompare(b));
        tags.forEach(val => {lessons.add(val);})

        row[KEYS.TAGS_LIST] = tags;
        row[KEYS.LESSON] = tags.join(",");

        // Add ます and て forms for verb and adjective conjugations.
        if (VERBS_AND_ADJECTIVES.includes(partOfSpeech)) {
            const {masuType, teType} = getAppConjugationTypes(partOfSpeech, row[KEYS.VOCABULARY_KANJI]);
            row[KEYS.MASU_TYPE] = masuType;
            row[KEYS.TE_TYPE] = teType;

            // Handle adjective conjugation
            if (ADJECTIVES.includes(partOfSpeech)) {
                if (masuType === ADJECTIVE_TYPES.NA_VERB) {
                    row[KEYS.VOCABULARY_KANA] += '(な)';
                    row[KEYS.VOCABULARY_KANJI] += '(な)';
                }
                row[KEYS.CONJUGATION_MAP] = getConjugationMap(
                    row,
                    (dictionaryForm, row) =>
                        conjugateAdjective(dictionaryForm, row[KEYS.MASU_TYPE], row[KEYS.TE_TYPE])
                );
            }

            // Handle verb conjugation
            if (VERBS.includes(partOfSpeech)) {
                row[KEYS.CONJUGATION_MAP] = getConjugationMap(
                    row,
                    (dictionaryForm, row) =>
                        conjugateVerb(dictionaryForm, row[KEYS.MASU_TYPE], row[KEYS.TE_TYPE])
                );
            }
        } else if (PARTS_OF_SPEECH_KEYS.NOUN === partOfSpeech) {
            row[KEYS.CONJUGATION_MAP] = getConjugationMap(
                row,
                noun => conjugateNoun(noun)
            )
        }

        row[KEYS.GRAMMAR_NOTES] = fields[11];

        // Parse from flat ruby value
        row[KEYS.SENTENCE] = fields[12];
        const {kanaStr: sentenceKanaStr, kanjiStr: sentenceKanjiStr} = parseFlatRuby(row[KEYS.SENTENCE]);
        row[KEYS.SENTENCE_KANA] = sentenceKanaStr;
        row[KEYS.SENTENCE_KANJI] = sentenceKanjiStr;

        row[KEYS.SENTENCE_DEFINITION] = fields[13];

        row[KEYS.SEARCH_STRING] = fields[14];

        acc.push(row);

        return acc;
    }, []);

    // Sort data by lesson, then by parts of speech
    const sortedData = [...rawData].sort((rowA, rowB) => {
        // Get earliest lesson tag.
        const lessonA = rowA[KEYS.TAGS_LIST][0];
        const lessonB = rowB[KEYS.TAGS_LIST][0];

        // Order by section and lesson value.
        if (lessonA !== lessonB) {
            const compareValue = compareLessonStrings(lessonA, lessonB);
            if (compareValue !== 0) {
                return compareValue;
            }
        }

        // Order by parts of speech within a lesson.
        const partOfSpeechA = rowA[KEYS.PART_OF_SPEECH];
        const partOfSpeechB = rowB[KEYS.PART_OF_SPEECH];
        if (partOfSpeechA !== partOfSpeechB) {
            const intA = PART_OF_SPEECH_LOOKUP_ORDER[partOfSpeechA];
            const intB = PART_OF_SPEECH_LOOKUP_ORDER[partOfSpeechB];
            return (intA < intB) ? -1 : 1;
        }

        return 0;
    });

    // Generate IDs
    sortedData.forEach((row, idx) => {
        row[KEYS.ID] = String(idx + 1);
    });

    return {
        data: sortedData,
        lessons: [...lessons].sort(compareLessonStrings)
    }
}

function compareLessonStrings(lessonA, lessonB) {
    // Split components.
    // Ex: "'会G'" => "会G" => ["会", ""]
    // Ex: "'会L10'" => "会L10" => ["会", "10"]
    const [sectionA, lessonStrA] = lessonA.slice(1, lessonA.length-1).split(/[L|G]/);
    const [sectionB, lessonStrB] = lessonB.slice(1, lessonB.length-1).split(/[L|G]/);

    // Order by 会, then 読.
    if (sectionA !== sectionB) {
        return (sectionA === '会') ? -1 : 1;
    }

    // Order by lesson value.
    if (lessonStrA !== lessonStrB) {
        // If tag is '会G', then the lesson str will be "". Set the parsed int as 0 to prioritize order.
        const parseIntA = lessonStrA ? parseInt(lessonStrA, 10) : 0;
        const parseIntB = lessonStrB ? parseInt(lessonStrB, 10) : 0;
        return (parseIntA < parseIntB) ? -1 : 1;
    }

    return 0;
}