import {CONJUGATION_FORMS} from "../../Constants";
import {stripWhitespace} from "../util/Util";

export function conjugateNoun(nounValue) {
    const dictionary = stripWhitespace(nounValue);
    let nounLongPositive;
    let nounLongNegative;
    let nounLongPastPositive;
    let nounLongPastNegative;
    let nounTeForm;
    let nounTeFormNegative;
    let nounPlainPositive;
    let nounPlainNegative;
    let nounPlainPastPositive;
    let nounPlainPastNegative;

    // long-form
    nounLongPositive = `${dictionary}です`;
    nounLongNegative = `${dictionary}じゃないです`;
    nounLongPastPositive = `${dictionary}でした`;
    nounLongPastNegative = `${dictionary}じゃないでした`;

    // te-form
    nounTeForm = `${dictionary}で`;
    nounTeFormNegative = `${dictionary}じゃなくて`;

    // plain-form
    nounPlainPositive = `${dictionary}だ`;
    nounPlainNegative = `${dictionary}じゃない`;
    nounPlainPastPositive = `${dictionary}だった`;
    nounPlainPastNegative = `${dictionary}じゃながった`;

    const retval = {};
    retval[CONJUGATION_FORMS.DICTIONARY] = dictionary;
    retval[CONJUGATION_FORMS.STEM] = dictionary;
    retval[CONJUGATION_FORMS.MASU_PRESENT_POSITIVE] = nounLongPositive;
    retval[CONJUGATION_FORMS.MASU_PRESENT_NEGATIVE] = nounLongNegative;
    retval[CONJUGATION_FORMS.MASU_PAST_POSITIVE] = nounLongPastPositive;
    retval[CONJUGATION_FORMS.MASU_PAST_NEGATIVE] = nounLongPastNegative;
    retval[CONJUGATION_FORMS.TE] = nounTeForm;
    retval[CONJUGATION_FORMS.TE_NEGATIVE] = nounTeFormNegative;
    retval[CONJUGATION_FORMS.PLAIN_PRESENT_POSITIVE] = nounPlainPositive;
    retval[CONJUGATION_FORMS.PLAIN_PRESENT_NEGATIVE] = nounPlainNegative;
    retval[CONJUGATION_FORMS.PLAIN_PAST_POSITIVE] = nounPlainPastPositive;
    retval[CONJUGATION_FORMS.PLAIN_PAST_NEGATIVE] = nounPlainPastNegative;
    return retval;
}