import React from "react";
import AppLayout from "../components/AppLayout";
import {fetchData} from "../util/Fetch";
import {
    parseGermanNumbers,
    parseSpektrumA1Nouns,
    parseSpektrumA1Other,
    parseSpektrumA1Verbs,
    parseSpektrumA1VerbsPerfekt,
    parseSpektrumA1Words, parseSpektrumList
} from "../german/SpektrumDeserializer";
import {
    BREAKPOINTS,
    KEYS,
    LABELS,
    LAYOUT_IDS,
    SORT_DIRECTION,
    SPEKTRUM_CATEGORIES,
    SPEKTRUM_KEYS,
    SPEKTRUM_TAGS
} from "../Constants";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles({
    header: {
        fontWeight: 500,
        lineHeight: '1.5rem',
    },
    tableContainer: {
        margin: '1em'
    },
    table: {
        margin: 'auto',
        maxWidth: '500px',
        border: '1px solid #999',
        textAlign: 'left'
    },
    tableCell: {
        padding: '.5em 1em'
    }
});

const LESSONS = {
    group: 'Lessons',
    tags: ['1', '2', '3', '4', '5', '6', '7', '8', '9']
};

const CATEGORY_TAGS = {
    group: 'Category Tags',
    tags: [
        // SPEKTRUM_CATEGORIES.WORDS,
        // SPEKTRUM_CATEGORIES.LIST,
        SPEKTRUM_CATEGORIES.NOUNS,
        SPEKTRUM_CATEGORIES.VERBS,
        SPEKTRUM_CATEGORIES.VERBS_PERFEKT,
        SPEKTRUM_CATEGORIES.OTHER
    ]
};

const NUMBER_TAGS = {
    group: 'Number Tags',
    tags: [
        SPEKTRUM_CATEGORIES.NUMBERS_BASIC,
        SPEKTRUM_CATEGORIES.NUMBERS_TEENS,
        SPEKTRUM_CATEGORIES.NUMBERS_TENS_BASIC,
        SPEKTRUM_CATEGORIES.NUMBERS_TENS_COMPOUND,
        SPEKTRUM_CATEGORIES.NUMBERS_100_PLUS_BASIC
    ]
}

const QUESTION_TAGS = {
    group: 'Question Tags',
    tags: [SPEKTRUM_TAGS.QUESTION_WORDS]
};

const TAG_LABELS = {};
TAG_LABELS[SPEKTRUM_CATEGORIES.WORDS] = "Wörter und Wendungen";
TAG_LABELS[SPEKTRUM_CATEGORIES.LIST] = "Liste";
TAG_LABELS[SPEKTRUM_CATEGORIES.NOUNS] = "Nouns";
TAG_LABELS[SPEKTRUM_CATEGORIES.VERBS] = "Verbs";
TAG_LABELS[SPEKTRUM_CATEGORIES.VERBS_PERFEKT] = "Verbs Perfekt"
TAG_LABELS[SPEKTRUM_CATEGORIES.OTHER] = "Other";
TAG_LABELS[SPEKTRUM_CATEGORIES.NUMBERS_BASIC] = "0-10";
TAG_LABELS[SPEKTRUM_CATEGORIES.NUMBERS_TEENS] = "11-19";
TAG_LABELS[SPEKTRUM_CATEGORIES.NUMBERS_TENS_BASIC] = "20-90";
TAG_LABELS[SPEKTRUM_CATEGORIES.NUMBERS_TENS_COMPOUND] = "21-99";
TAG_LABELS[SPEKTRUM_CATEGORIES.NUMBERS_100_PLUS_BASIC] = "100+";
TAG_LABELS[SPEKTRUM_CATEGORIES.NUMBERS_100_PLUS_COMPOUND] = "101+";
TAG_LABELS[SPEKTRUM_TAGS.QUESTION_WORDS] = "Questions";

function GermanQuestion({row, isDefinition}) {
    if (!row) {
        return null;
    }

    const h2Style = {marginTop: '4px', marginBottom: '0'};
    const category = row[KEYS.CATEGORY];

    if (isDefinition) {
        return (
            <h2 style={h2Style}>
                <div>{row[KEYS.DEFINITION]}</div>
            </h2>
        );
    }

    if (category === SPEKTRUM_CATEGORIES.NOUNS) {
        return (
            <h2 style={h2Style}>
                <div>{row[SPEKTRUM_KEYS.SINGULAR]}</div>
            </h2>
        );
    }

    /*
    category === SPEKTRUM_CATEGORIES.WORDS ||
    category === SPEKTRUM_CATEGORIES.LIST ||
    category === SPEKTRUM_CATEGORIES.VERBS ||
    category === SPEKTRUM_CATEGORIES.OTHER
    */
    return (
        <h2 style={h2Style}>
            <div>{row[KEYS.VOCABULARY]}</div>
        </h2>
    );
}

function NounDeclensionTableChild({modelId, col, valSuffix, dividers}) {
    if (!col) {
        return null;
    }

    return col.map((val, idx) => {
        let divider;
        if (dividers && dividers.includes(idx)) {
            divider = <Divider />;
        }
        return (
            <React.Fragment key={`${modelId}_${idx}`}>
                <div>{val}{valSuffix}</div>
                {divider}
            </React.Fragment>
        );
    });
}

function NounDeclensionTable({leftHeader, leftCol1, leftCol2, leftCol3, rightHeader, rightCol1, rightCol2, dividers}) {
    const classes = useStyles();
    return (
        <div className={classes.tableContainer}>
            <Paper className={classes.table}>
                <Grid container>
                    <Grid item xs={12} sm={6} className={classes.tableCell}>
                        <div className={classes.header}>{leftHeader}</div>
                        <Divider />
                        <table style={{width: '100%'}}>
                            <tbody>
                            <tr>
                                <td>
                                    <NounDeclensionTableChild
                                        modelId={'leftCol1'}
                                        col={leftCol1}
                                        valSuffix={<span>&nbsp;&nbsp;&nbsp;</span>}
                                        dividers={dividers}
                                    />
                                </td>
                                <td>
                                    <NounDeclensionTableChild
                                        modelId={'leftCol2'}
                                        col={leftCol2}
                                        valSuffix={<span>&nbsp;</span>}
                                        dividers={dividers}
                                    />
                                </td>
                                {leftCol3 &&
                                    <td>
                                        <NounDeclensionTableChild
                                            modelId={'leftCol3'}
                                            col={leftCol3}
                                            valSuffix={<span>&nbsp;</span>}
                                            dividers={dividers}
                                        />
                                    </td>
                                }
                            </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.tableCell}>
                        <div className={classes.header}>{rightHeader}</div>
                        <Divider />
                        <table style={{width: '100%'}}>
                            <tbody>
                            <tr>
                                <td>
                                    <NounDeclensionTableChild
                                        modelId={'rightCol1'}
                                        col={rightCol1}
                                        valSuffix={<span>&nbsp;&nbsp;&nbsp;</span>}
                                        dividers={dividers}
                                    />
                                </td>

                                <td>
                                    <NounDeclensionTableChild
                                        modelId={'rightCol2'}
                                        col={rightCol2}
                                        valSuffix={<span>&nbsp;</span>}
                                        dividers={dividers}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

function GermanVocabularyDetail({row}) {
    const classes = useStyles();

    if (!row) {
        return null;
    }

    const h2Style = {marginTop: '4px', marginBottom: '0'};
    const category = row[KEYS.CATEGORY];

    if (category === SPEKTRUM_CATEGORIES.WORDS) {
        return (
            <div>
                <h3>{row[KEYS.SUB_CATEGORY]}</h3>
                <h2 style={h2Style}>
                    <div>{row[KEYS.VOCABULARY]}</div>
                    <div>{row[KEYS.DEFINITION]}</div>
                </h2>

                <h4>(Audio track {row[KEYS.AUDIO]})</h4>

            </div>
        );
    }

    if (category === SPEKTRUM_CATEGORIES.NOUNS) {

        const NO_PLURAL_VALUE = "--";

        const article = row[SPEKTRUM_KEYS.ARTICLE];
        const singularNoun = row[SPEKTRUM_KEYS.SINGULAR];
        const pluralNoun = row[SPEKTRUM_KEYS.PLURAL];

        const pronouns = ['','','','ich', 'du', 'er/es', 'sie', 'wir', 'ihr', 'sie','Sie'];
        const stem = ['kein', 'mein', 'dein', 'sein', 'ihr', 'unser', 'euer', 'ihr', 'Ihr'];
        const stem_e = ['keine', 'meine', 'deine', 'seine', 'ihre', 'unsere', 'eure', 'ihre', 'Ihre'];
        const stem_en = ['keinen', 'meinen', 'deinen', 'seinen', 'ihren', 'unseren', 'euren', 'ihren', 'Ihren'];
        const stem_em = ['keinem', 'meinem', 'deinem', 'seinem', 'ihrem', 'unserem', 'eurem', 'ihrem', 'Ihrem'];
        const stem_er = ['keiner', 'meiner', 'deiner', 'seiner', 'ihrer', 'unserer', 'eurer', 'ihrer', 'Ihrer'];
        const noPlurals = [NO_PLURAL_VALUE, NO_PLURAL_VALUE, NO_PLURAL_VALUE, NO_PLURAL_VALUE, NO_PLURAL_VALUE,
            NO_PLURAL_VALUE, NO_PLURAL_VALUE, NO_PLURAL_VALUE, NO_PLURAL_VALUE, NO_PLURAL_VALUE];

        let nominativeSingularArr = [];
        let accusativeSingularArr = [];
        let dativeSingularArr = [];
        if (article === 'der') {
            nominativeSingularArr = ['der', 'ein', ...stem];
            accusativeSingularArr = ['den', 'einen', ...stem_en];
            dativeSingularArr = ['dem', 'einem', ...stem_em];
        } else if (article === 'die') {
            nominativeSingularArr = ['die', 'eine', ...stem_e];
            accusativeSingularArr = ['die', 'eine', ...stem_e];
            dativeSingularArr = ['der', 'einer', ...stem_er];
        } else {
            nominativeSingularArr = ['das', 'ein', ...stem];
            accusativeSingularArr = ['das', 'ein', ...stem];
            dativeSingularArr = ['dem', 'einem', ...stem_em];
        }

        const nominativePluralArr = pluralNoun === NO_PLURAL_VALUE ? noPlurals : ['die', NO_PLURAL_VALUE, ...stem_e];
        const dativePluralArr = pluralNoun === NO_PLURAL_VALUE ? noPlurals : ['den', NO_PLURAL_VALUE, ...stem_en];

        let nominativePluralNounArr;
        if (pluralNoun !== NO_PLURAL_VALUE) {
            nominativePluralNounArr = nominativePluralArr.map(val => val === NO_PLURAL_VALUE ? '' : pluralNoun)
        }

        let dativePluralNoun = (pluralNoun === NO_PLURAL_VALUE) ? undefined : pluralNoun;
        let dativePluralNounArr;
        if (dativePluralNoun) {
            if (!dativePluralNoun.endsWith('s') && !dativePluralNoun.endsWith('n')) {
                dativePluralNoun = `${dativePluralNoun}n`;
            }
            dativePluralNounArr = dativePluralArr.map(val => val === NO_PLURAL_VALUE ? '' : dativePluralNoun)
        }

        return (
            <div>
                <h2 style={h2Style}>
                    <div>{article} {singularNoun}</div>
                    <div>{pluralNoun === NO_PLURAL_VALUE ? '' : `die ${pluralNoun}`}</div>
                </h2>
                <h3>
                    <div>{row[KEYS.DEFINITION]}</div>
                </h3>

                <NounDeclensionTable
                    leftHeader='Nominativ/Akkusativ'
                    leftCol1={pronouns}
                    leftCol2={nominativeSingularArr}
                    leftCol3={article === 'der' ? accusativeSingularArr : undefined}
                    rightHeader={'Nominativ/Akkusativ (Pl.)'}
                    rightCol1={nominativePluralArr}
                    rightCol2={nominativePluralNounArr}
                    dividers={[0,2,6,9]}
                />

                <NounDeclensionTable
                    leftHeader='Dativ'
                    leftCol1={pronouns}
                    leftCol2={dativeSingularArr}
                    rightHeader={'Dativ (Pl.)'}
                    rightCol1={dativePluralArr}
                    rightCol2={dativePluralNounArr}
                    dividers={[0,2,6,9]}
                />

            </div>
        );
    }

    if (category === SPEKTRUM_CATEGORIES.VERBS) {
        return (
            <div>
                <h2 style={h2Style}>
                    <div>{row[KEYS.VOCABULARY]}</div>
                    <div>{row[KEYS.DEFINITION]}</div>
                </h2>
                <h3>
                    <div>{row[KEYS.SENTENCE]}</div>
                    <div>{row[KEYS.SENTENCE_DEFINITION]}</div>
                </h3>

                {(row[KEYS.VOCABULARY] === "sein") &&
                    <div style={{margin: '1em'}}>
                        <TableContainer component={Paper}
                                        style={{margin: 'auto', maxWidth: '500px', border: '1px solid #999'}}>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>Präsens</TableCell>
                                        <TableCell>Präteritum</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <div>ich</div>
                                            <div>du</div>
                                            <div>er/sie</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>{row[SPEKTRUM_KEYS.ICH]}</div>
                                            <div>{row[SPEKTRUM_KEYS.DU]}</div>
                                            <div>{row[SPEKTRUM_KEYS.ER_SIE]}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>war</div>
                                            <div>warst</div>
                                            <div>war</div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <div>wir</div>
                                            <div>ihr</div>
                                            <div>sie</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>{row[SPEKTRUM_KEYS.WIR]}</div>
                                            <div>{row[SPEKTRUM_KEYS.IHR]}</div>
                                            <div>{row[SPEKTRUM_KEYS.SIE_PLURAL]}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>waren</div>
                                            <div>wart</div>
                                            <div>waren</div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Sie
                                        </TableCell>
                                        <TableCell>
                                            {row[SPEKTRUM_KEYS.SIE_FORMELL]}
                                        </TableCell>
                                        <TableCell>waren</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }

                {(row[KEYS.VOCABULARY] === "haben") &&
                <div style={{margin: '1em'}}>
                    <TableContainer component={Paper}
                                    style={{margin: 'auto', maxWidth: '500px', border: '1px solid #999'}}>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>&nbsp;</TableCell>
                                    <TableCell>Präsens</TableCell>
                                    <TableCell>Präteritum</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <div>ich</div>
                                        <div>du</div>
                                        <div>er/sie</div>
                                    </TableCell>
                                    <TableCell>
                                        <div>{row[SPEKTRUM_KEYS.ICH]}</div>
                                        <div>{row[SPEKTRUM_KEYS.DU]}</div>
                                        <div>{row[SPEKTRUM_KEYS.ER_SIE]}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div>hatte</div>
                                        <div>hattest</div>
                                        <div>hatte</div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <div>wir</div>
                                        <div>ihr</div>
                                        <div>sie</div>
                                    </TableCell>
                                    <TableCell>
                                        <div>{row[SPEKTRUM_KEYS.WIR]}</div>
                                        <div>{row[SPEKTRUM_KEYS.IHR]}</div>
                                        <div>{row[SPEKTRUM_KEYS.SIE_PLURAL]}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div>hatten</div>
                                        <div>hattet</div>
                                        <div>hatten</div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Sie
                                    </TableCell>
                                    <TableCell>
                                        {row[SPEKTRUM_KEYS.SIE_FORMELL]}
                                    </TableCell>
                                    <TableCell>hatten</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                }

                {(row[KEYS.VOCABULARY] !== "sein") && (row[KEYS.VOCABULARY] !== "haben") &&
                    <div style={{margin: '1em'}}>
                        <TableContainer component={Paper}
                                        style={{margin: 'auto', maxWidth: '500px', border: '1px solid #999'}}>
                            <Table size='small'>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            Singular
                                        </TableCell>
                                        <TableCell>
                                            <div>ich</div>
                                            <div>du</div>
                                            <div>er/sie</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                { row[SPEKTRUM_KEYS.ICH] === '--*' ? '--' :
                                                    `${row[SPEKTRUM_KEYS.ICH]} ${row[SPEKTRUM_KEYS.VERBEN_PRAEFIX]}`
                                                }
                                            </div>
                                            <div>
                                                { row[SPEKTRUM_KEYS.DU] === '--*' ? '--' :
                                                    `${row[SPEKTRUM_KEYS.DU]} ${row[SPEKTRUM_KEYS.VERBEN_PRAEFIX]}`
                                                }
                                            </div>
                                            <div>
                                                { row[SPEKTRUM_KEYS.ER_SIE] === '--*' ? '--' :
                                                    `${row[SPEKTRUM_KEYS.ER_SIE]} ${row[SPEKTRUM_KEYS.VERBEN_PRAEFIX]}`
                                                }
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Plural
                                        </TableCell>
                                        <TableCell>
                                            <div>wir</div>
                                            <div>ihr</div>
                                            <div>sie</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                { row[SPEKTRUM_KEYS.WIR] === '--*' ? '--' :
                                                    `${row[SPEKTRUM_KEYS.WIR]} ${row[SPEKTRUM_KEYS.VERBEN_PRAEFIX]}`
                                                }
                                            </div>
                                            <div>
                                                { row[SPEKTRUM_KEYS.IHR] === '--*' ? '--' :
                                                    `${row[SPEKTRUM_KEYS.IHR]} ${row[SPEKTRUM_KEYS.VERBEN_PRAEFIX]}`
                                                }
                                            </div>
                                            <div>
                                                { row[SPEKTRUM_KEYS.SIE_PLURAL] === '--*' ? '--' :
                                                    `${row[SPEKTRUM_KEYS.SIE_PLURAL]} ${row[SPEKTRUM_KEYS.VERBEN_PRAEFIX]}`
                                                }
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            formell
                                        </TableCell>
                                        <TableCell>
                                            Sie
                                        </TableCell>
                                        <TableCell>
                                            {row[SPEKTRUM_KEYS.SIE_FORMELL]} {row[SPEKTRUM_KEYS.VERBEN_PRAEFIX]}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }

            </div>
        );
    }

    if (category === SPEKTRUM_CATEGORIES.VERBS_PERFEKT) {
        return (
            <div>
                <h2 style={h2Style}>
                    <div>{row[KEYS.VOCABULARY]}</div>
                </h2>
                <h2 style={h2Style}>
                    <div>{row[SPEKTRUM_KEYS.VERBEN_PERFEKT_HABEN_SEIN]} {row[SPEKTRUM_KEYS.VERBEN_PERFEKT]}</div>
                </h2>
                <h2 style={{marginTop: 0, marginBottom:0}}>
                    <div>{row[KEYS.DEFINITION]}</div>
                </h2>
                <h3>
                    <div>{row[KEYS.SENTENCE]}</div>
                    <div>{row[KEYS.SENTENCE_DEFINITION]}</div>
                </h3>
            </div>
        );
    }

    if (category === SPEKTRUM_CATEGORIES.OTHER) {
        return (
            <div>
                <h2 style={h2Style}>
                    <div>{row[KEYS.VOCABULARY]}</div>
                </h2>
                <h3 style={{marginTop: 0, marginBottom:0}}>
                    <div>{row[KEYS.DEFINITION]} [{row[KEYS.PART_OF_SPEECH]}]</div>
                </h3>

                <h3>
                    <div>{row[KEYS.SENTENCE]}</div>
                    <div>{row[KEYS.SENTENCE_DEFINITION]}</div>
                </h3>
            </div>
        );
    }

    return (
        <div>
            <h2 style={h2Style}>
                <div>{row[KEYS.VOCABULARY]}</div>
            </h2>
            <h3 style={{marginTop: 0, marginBottom:0}}>
                <div>{row[KEYS.DEFINITION]}</div>
            </h3>
        </div>
    );

    // return <div>Unknown category</div>
}

export default class SpektrumA1 extends React.Component {
    static propTypes = {
        breakpoint: PropTypes.string,
        keyPress: PropTypes.string
    }

    constructor(props) {
        super(props);
        this.state = {
            wordData: null,
            nounData: null,
            verbData: null,
            verbDataPerfekt: null,
            otherData: null,
            numberData: null,
            listData: null,
            originalData: [],
        }
        this.initialData = [];
    }

    componentDidMount() {
        [
            // {url: '/data-spektrum-words.txt', stateKey: 'wordData', parseFunc: parseSpektrumA1Words},
            {url: '/data-spektrum-nouns.txt', stateKey: 'nounData', parseFunc: parseSpektrumA1Nouns},
            {url: '/data-spektrum-verbs.txt', stateKey: 'verbData', parseFunc: parseSpektrumA1Verbs},
            {url: '/data-spektrum-verbs-perfekt.txt', stateKey: 'verbDataPerfekt', parseFunc: parseSpektrumA1VerbsPerfekt},
            {url: '/data-spektrum-other.txt', stateKey: 'otherData', parseFunc: parseSpektrumA1Other},
            {url: '/data-german-numbers.txt', stateKey: 'numberData', parseFunc: parseGermanNumbers},
            // {url: '/data-spektrum-liste.txt', stateKey: 'listData', parseFunc: parseSpektrumList},
        ].forEach(({url, stateKey, parseFunc}) => {
            fetchData(url, parseFunc).then(data => {
                const newState = {};
                newState[stateKey] = data;
                this.setState(newState);
            });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const newState = {};
        let isDataUpdated = false;
        const allData = ['nounData', 'verbData', 'verbDataPerfekt', 'otherData', 'numberData']; // 'wordData', 'listData',

        const isAllDataFetched = allData.reduce((acc, key) => {
            const currValue = this.state[key];
            const prevValue = prevState[key];
            if (currValue && currValue !== prevValue) {
                this.initialData = [...this.initialData, ...currValue];
                isDataUpdated = true;
            }
            return (acc === null) ? Boolean(currValue) : acc && Boolean(currValue);
        }, null);

        if (isDataUpdated && isAllDataFetched) {
            newState.originalData = this.initialData;
        }

        if (Object.keys(newState).length) {
            this.setState(newState);
        }
    }

    render() {
        const {breakpoint, keyPress} = this.props;
        const {originalData} = this.state;

        return (
            <AppLayout
                instanceId={LAYOUT_IDS.SPEKTRUM_A1}
                data={originalData}
                columns={[
                    { dataKey: KEYS.LESSON, label: 'Kapitel', breakpoint: BREAKPOINTS.XS_DOWN},
                    { dataKey: KEYS.VOCABULARY, label: LABELS[KEYS.VOCABULARY]},
                    { dataKey: KEYS.DEFINITION, label: LABELS[KEYS.DEFINITION]},
                ]}
                defaultSort={{
                    field: KEYS.LESSON,
                    direction: SORT_DIRECTION.ASC
                }}
                breakpoint={breakpoint}
                keyPress={keyPress}
                lessonRenderer={val => `Kapitel ${val}`}
                questionRenderer={(row, questionType) => <GermanQuestion row={row} isDefinition={questionType === KEYS.DEFINITION}/>}
                answerRenderer={row => <GermanVocabularyDetail row={row}/>}
                detailRenderer={row => (
                    <Box style={{textAlign: 'center'}}>
                        <GermanVocabularyDetail row={row} />
                    </Box>
                )}
                detailDialogRenderer={(row) => (
                    <div style={{textAlign: 'center'}}>
                        <GermanVocabularyDetail row={row} />
                    </div>
                )}
                searchField={KEYS.SEARCH_STRING}
                flashcardOptions={[
                    {
                        field: KEYS.VOCABULARY,
                        label: LABELS[KEYS.VOCABULARY]
                    },
                    {
                        field: KEYS.DEFINITION,
                        label: LABELS[KEYS.DEFINITION]
                    }
                ]}
                filterOptions={[
                    {
                        modelId: 'spektrumLessons',
                        tagCategories: [ LESSONS ],
                        defaultTags: ['7', '8', '9'],
                        filterFunc: selectedTags => row => {
                            // If no tags are selected, show all results.
                            if (!selectedTags || selectedTags.length === 0) {
                                return true;
                            }

                            return selectedTags.includes(row[KEYS.LESSON]);
                        },
                    },
                    {
                        modelId: 'spektrumTags',
                        tagCategories: [
                            CATEGORY_TAGS,
                            NUMBER_TAGS,
                            // QUESTION_TAGS
                        ],
                        defaultTags: [SPEKTRUM_CATEGORIES.NOUNS, SPEKTRUM_CATEGORIES.VERBS, SPEKTRUM_CATEGORIES.VERBS_PERFEKT, SPEKTRUM_CATEGORIES.OTHER],
                        filterFunc: selectedTags => row => {
                            // If no tags are selected, show all results.
                            if (!selectedTags || selectedTags.length === 0) {
                                return true;
                            }

                            // If the row doesn't have tags, filter it out.
                            const tagList = row[KEYS.TAGS_LIST];
                            if (!tagList) {
                                return false;
                            }

                            // Look for an intersecting tag.
                            let isFound = false;
                            for (let i = 0; i < selectedTags.length; i++) {
                                const selectedTag = selectedTags[i];
                                if (tagList.includes(selectedTag)) {
                                    isFound = true;
                                    break;
                                }
                            }

                            return isFound;
                        },
                        checkboxLabelFunc: val => {
                            let retval = TAG_LABELS[val];
                            return retval ? retval : val;
                        }
                    }
                ]}
            />
        );
    }
}
